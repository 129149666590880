import React, { useEffect, useState } from "react";
import PropertyDetailsSection from "./components/property-details";
import { useParams } from "react-router-dom";
import AppLayout from "../../../components/layouts/app-layout";
import { useTranslation } from "react-i18next";

const isAdmin = window.location.href.includes("admin");

const PropertyDetails = () => {
  let { id } = useParams();
  const [propertyId, setPropertyId] = useState(id);
  const { t } = useTranslation();

  useEffect(() => {
    setPropertyId(id);
  }, [id]);
  return (
    <AppLayout pageTitle={t("ADDetails")} withoutNav={isAdmin} unsetOverflow>
      <PropertyDetailsSection id={propertyId} />
    </AppLayout>
  );
}

export default PropertyDetails;
