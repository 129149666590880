import { Delete } from "@mui/icons-material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import { Tooltip } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useDrag, useDrop } from "react-dnd";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

const style = {
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  cursor: 'move',
}

const ElementWrapper = ({ childern, element, key, setOpenAddNewElementModal, openAddNewElementModal, index, moveCard }) => {
  const ref = useRef(null);
  const { t } = useTranslation();

  const [{ handlerId }, drop] = useDrop({
    accept: 'CARD',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'CARD',
    item: () => {
      return { id: key, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));


  return (
    <div className={`desktop-wrapper col-12 mb-4 col-shadow `}
      ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}
    >
      <div className="desktop-tab">
        <span style={{ color: 'var(--main-color-one)' }}>{element.type === 'text' ? t("Text") : element.type === 'image' ? t("Image1") : element.type === 'line' ? t("BreakLine") : t("Gallery")}</span>
                  
        <div className="dots-wrapper">
          <Tooltip title={t("Move")}>
            <button className={`custom-btn custom-btn2 sort-btn`}>
              <ControlCameraIcon />
            </button>
          </Tooltip>
          <Tooltip title={t("Duplicate")}>
            <button
              className={`custom-btn custom-btn2 green-btn`} onClick={() => {
                setOpenAddNewElementModal({
                  visible: false, elements: [...openAddNewElementModal.elements,
                  { id: openAddNewElementModal.elements.length + 1, type: element.type, value: element.value }]
                });
            
              }}>
              <ContentCopyIcon />
            </button>
          </Tooltip>
          <Tooltip title={t("Delete")}>
            <button
              className={`custom-btn custom-btn2 delete-btn`}
              onClick={() => {
                setOpenAddNewElementModal({ visible: false, elements: openAddNewElementModal.elements.filter(i => i.id !== element.id) });
              }}>
              <Delete />
            </button>
          </Tooltip>
          <Tooltip title={element.expand ? t("Collapse") : t("Expand")}>
            <button className='custom-btn custom-btn2'
              onClick={() => {
                let temp = [];
                openAddNewElementModal.elements.map(item => {
                  if (item.id === element.id) {
                    temp.push({ ...item, expand: !item.expand })
                  } else {
                    temp.push(item);
                  }
                  return null;
                })
                setOpenAddNewElementModal({ visible: false, elements: temp });
              }}>
              {element.expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </button>
          </Tooltip>
        </div>
      </div>
      <div style={{
        position: 'relative', overflow: 'hidden', padding: '10px', display: (element.expand ? 'block' : 'none'),
        background: 'inherit'
      }}>
        {childern}
      </div>
    </div>
  );
}

export default ElementWrapper;