import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { getLocation } from "../../api/generalAPI";
import useLocation from "../../components/user-components/useLocation";
import { useTranslation } from "react-i18next";
import { Search } from "../../constants/icons";
import SwiperCore, { Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Tab, Tabs } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import Features from "./counters";

SwiperCore.use([Autoplay]);

const Banner = (props) => {
  const { location, setLocation } = useLocation(
    sessionStorage.getItem("location")
  );
  const { t } = useTranslation();
  
  let loc = [];
  if (location) loc = location.split("//");

  const [search, setSearch] = useState({
    propertyType: "all",
    listedFor: "3",
    lat: location ? loc[1] : "24.716199523004914",
    long: location ? loc[2] : "46.671776478222675",
    placeholder: location ? loc[0] : "ادخل اسم الموقع هنا..",
  });
  // const [propertyTypeValue, setPropertyTypeValue] = useState("0");

  // function handleChange(target) {
  //   let propertype = target.propertyType === "0" ? "all" : target.propertyType;
  //   setSearch({ ...search, propertyType: propertype });
  //   setPropertyTypeValue(target.propertyType);
  // }

  const tabStyle = {
    fontSize: "15px",
    minWidth: '65px',
    minHeight: "40px",
    "&.Mui-selected": {
      color: "#fff",
      background: "var(--main-color-one)",
      borderRadius: "4px"
    },
  };

  useEffect(() => {
    if (!location)
      getLocation().then((_DefLocation) => {
        let latLng = new window.google.maps.LatLng(_DefLocation.latitude, _DefLocation.longitude);
        let gecod = new window.google.maps.Geocoder();
        gecod.geocode(
          {
            location: latLng,
            region: "SA",
          },
          (results, status) => {
            if (status === "OK") {
              results[0].address_components.map((ad) => {
                if (ad.types.includes("country")) {
                  if (ad.short_name === "SA") {
                    setSearch({
                      ...search,
                      lat: _DefLocation.latitude,
                      long: _DefLocation.longitude,
                      placeholder: _DefLocation.city === "Riyadh" ? t("Riyadh") : _DefLocation.city,
                    });
                    setLocation((_DefLocation.city === "Riyadh" ? t("Riyadh") : _DefLocation.city) + "//" + _DefLocation.latitude + "//" + _DefLocation.longitude);
                  } else {
                    setSearch({
                      ...search,
                      lat: 24.716199523004914,
                      long: 46.671776478222675,
                      placeholder: 'الرياض',
                    });
                    setLocation(t("Riyadh") + "//" + 24.716199523004914 + "//" + 46.671776478222675);
                  }
                }
                return ad.short_name;
              });
            } else {
              let city = _DefLocation.city === "Riyadh" ? t("Riyadh") : _DefLocation.city;
              setSearch({
                ...search,
                lat: _DefLocation.latitude || 24.716199523004914,
                long: _DefLocation.longitude || 46.671776478222675,
                placeholder: city || 'الرياض',
              });
    
              setLocation(
                city || t("Riyadh") + "//" + _DefLocation.latitude || 24.716199523004914 + "//" + _DefLocation.longitude || 46.671776478222675
              );
            }
          }
        );
      
      });
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // let data = props.data;

  return (
    <>
      <div className='banner-area slider right-raduis position-relative' dir='rtl'>
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          modules={[EffectFade]}
          effect='fade'
          autoplay={{
            delay: 4000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false,
          }}
          loop={true}
          speed={3000}>
          <SwiperSlide>
            {/* <picture>
              <source
                srcSet='/assets/img/banner/banner-01.webp'
                type='image/webp'
              />
              <img src='/assets/img/banner/banner-01.jpg' alt='banner img 1' />
            </picture> */}
            <img src='/assets/img/banner/banner-01.jpg' alt='banner img 1' />
          </SwiperSlide>
          <SwiperSlide>
            {/* <picture>
              <source
                srcSet='/assets/img/banner/banner-02.webp'
                type='image/webp'
              />
              <img src='/assets/img/banner/banner-02.jpg' alt='banner img 2' />
            </picture> */}
            <LazyLoadImage src='/assets/img/banner/banner-02.jpg' alt='banner img 2' />
          </SwiperSlide>
          <SwiperSlide>
            {/* <picture>
              <source
                srcSet='/assets/img/banner/banner-03.webp'
                type='image/webp'
              />
              <img src='/assets/img/banner/banner-03.jpg' alt='banner img 3' />
            </picture> */}
            <LazyLoadImage src='/assets/img/banner/banner-03.jpg' alt='banner img 3' />
          </SwiperSlide>
        </Swiper>
      
        <div className='banner-inner-wrap'>
          <div className='row'>
            <div className='col-12'>
              <div className='banner-inner'>
                <h2
                  className='title'
                  data-aos='fade-up'
                  data-aos-easing='ease'
                  data-aos-delay='600'
                  style={{ textAlign: "-webkit-center" }}>
                  {props.data.title2}
                </h2>
              </div>
            </div>
            <div className='col-12 p-0'>
              <div
                className='banner-search-wrap'
                data-aos='fade-up'
                data-aos-easing='ease'
                data-aos-delay='1000'>
                <Tabs
                  onChange={(_, val) => setSearch({ ...search, listedFor: val })}
                  centered
                  value={search.listedFor}
                  sx={{
                    background: "#EAEAEA",
                    padding: "5px",
                    borderRadius: "6px",
                    margin: '20px auto 10px',
                    width: 'fit-content'
                  }}
                  TabIndicatorProps={{ style: { display: "none" } }}>
                  <Tab value={"3"} label={t("All")} sx={tabStyle} />
                  <Tab value={"2"} label={t("ForSale")} sx={tabStyle} />
                  <Tab value={"1"} label={t("ForRent")} sx={tabStyle} />
                </Tabs>
                <div className='tab-content'>
                  <div className='tab-pane fade show active'>
                    <div className='rld-main-search'>
                      <div className='row'>
                        {/* <div
                        className='col-select'
>                          <PropertyTypeSelect
                        onChange={handleChange}
                        noBorder={true}
                          selectedValue={propertyTypeValue}
                        />
                      </div> */}
                        <div className='col-input'>
                          <div className='rld-single-input '>
                            <Autocomplete
                              style={{ width: "100%" }}
                              id="search-bar"
                              placeholder={search.placeholder}
                              onPlaceSelected={(place) => {
                                setSearch({
                                  ...search,
                                  lat: place.geometry.location.lat(),
                                  long: place.geometry.location.lng(),
                                });
                              }}
                              options={{
                                types: ["(regions)"],
                                componentRestrictions: { country: "SA" },
                              }}
                            />
                          </div>
                        </div>
                        <div className='readeal-top col-search'>
                          <Link
                            className='btn btn-yellow circular-btn'
                            aria-label="search for properties"
                            to={`/search-map/${search.listedFor}/${search.propertyType}/${search.lat || 24.716199523004914}/${search.long || 46.671776478222675}/createdAt/11/1`}>
                            <Search />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Features /> */}
    </>
  );
};

export default Banner;
