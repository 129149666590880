import React from "react";
import { useTranslation } from "react-i18next";

const ADConstraints = ({ onOK, project }) => {
  const { t } = useTranslation();

  return (
    <div className='custom-card ad-constraints'>
      <div className='card-header'>
        <h4>ضوابط الإعلان العقاري</h4>
        <h6>بمجرد قيامك بالضغط على التالي, فأنت توافق على جميع هذه الضوابط</h6>
      </div>
      <div className='card-body'>
        <div>
          <ul>
            <li>معلومات العرض صحيحة.</li>
            <li>يجب إزالة الإعلان العقاري فور انتهاء الغرض منه.</li>

            {!project && (
              <>
                <li>سيتم حذف الإعلان تلقائياً حسب تاريخ انتهاء رخصة الإعلان.</li>
                <li>سيتم حجب الإعلان في حال انتهاء صلاحية رخصة "فال للوساطة والتسويق"</li>
              </>
            )}

            <li>الالتزام بجميع ما ورد في{" "}
              <a
                style={{
                  color: "var(--main-color-one)",
                  textDecoration: "underline",
                }}
                target="_blank"
                rel="noreferrer"
                href='https://amakknbackendupload.s3.me-south-1.amazonaws.com/brokerage.pdf'>
                نظام الوساطة العقاريّة
              </a>{" "}
              و{" "}
              <a
                style={{
                  color: "var(--main-color-one)",
                  textDecoration: "underline",
                }}
                target="_blank"
                rel="noreferrer"
                href='https://amakknbackendupload.s3.me-south-1.amazonaws.com/regulations.pdf'>
                لوائحه التنفيذية وجدول المخالفات.
              </a>{" "}
            </li>
            <li> الالتزام بجميع ما ورد{" "}
              <a
                style={{
                  color: "var(--main-color-one)",
                  textDecoration: "underline",
                }}
                target="_blank"
                rel="noreferrer"
                href='https://amakknbackendupload.s3.me-south-1.amazonaws.com/rega.pdf'>
                بضوابط الإعلانات العقاريّة
              </a>{" "}
              الصادرة من الهيئة العامة للعقار.</li>
            <li>
              اوافق على{" "}
              <a
                style={{
                  color: "var(--main-color-one)",
                  textDecoration: "underline",
                }}
                target="_blank"
                rel="noreferrer"
                href='https://www.amakkn.com/terms'>
                شروط وأحكام اتفاقية استخدام منصة أماكن
              </a>.
            </li>
          </ul>
        </div>
      </div>
      <div className='card-footer'>
        <button
          className='primary-btn px-5'
          onClick={(e) => {
            onOK();
          }}>
          {t("Next")}
        </button>
      </div>
    </div>
  );
}

export default ADConstraints;
