import { Box } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import AppLayout from "../../components/layouts/app-layout";
import AboutUs from "../../components/section-components/about-us";
import { ListArrow } from "../../constants/icons";
import { useAuth } from "../../helpers/context";

// getAboutUsAndTerms("1").then((_DefLocation) => {
//   if (_DefLocation.resCode === 0) {
//     setContent(_DefLocation.response.extra.content);
//   }
// });

const AboutPage = () => {

  const { vendor } = useAuth();
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  
  return (
    <AppLayout pageTitle="من نحن" withFooter>
      {vendor ? vendor === "amakkn" ? (
        <div className="page-width favorite-area pd-top-90 mg-bottom-100 text-center">
          <Box
            sx={{
              marginBottom: "40px",
              textAlign: "center",
              fontSize: "28px",
              fontWeight: 600,
              color: "#000",
            }}>
            حول أماكن
          </Box>
          <div className='row align-items-center justify-content-end pd-top-30 m-0' dir="rtl">
            <div className="col-12 p-0 text-right mb-2">
              <div className='custom-card' style={{ minHeight: 'auto' }}>
                <div className="card-header">
                  <h4 style={{ color: 'var(--main-color-one)' }}> حول أماكن</h4>
                </div>
                <div className="card-body">
                  <p>
                    موقع أماكن التابع لشركة الأساليب المبتكرة للتسويق الالكتروني يهدف لتسهيل عمليات العرض لجميع أنواع العقارات والشؤون المتعلقة بها وكذلك تسهيل عمليات البحث عن جميع أنواع العقارات والشئون المتعلقة بها مثل الملاك والوسطاء والمقاولين والمقيّمين وغيرهم والوصول إليها وتوفير الكثير من الجهد والوقت والمال على الباحثين ، وذلك بإتاحة الفرصة للملاك والوسطاء وغيرهم من المتخصصين اضافة عروضهم واعلاناتهم بطريقة سهلة ومرنة وبوسائل مختلفة وكذلك تمكين الباحثين من الوصول إلى هذه المعلومات والإعلانات بطريقة سهلة وعملية وبوسائل وتطبيقات مختلفة لتمكينهم بإذن الله من اتخاذ قراراتهم بدقة وجودة أفضل ، وسنستمر في محاولة تحسين خدماتنا والبيئة العامة للبوابة و التطبيقات التابعة لها وذلك بالاستفادة من اخطاءنا والاستثمار في البحث والتطوير وكذلك الاستفادة من ملاحظات ونصائح عملائنا الاعزاء ، ونسأل الله سبحانه وتعالى السداد والتوفيق                </p>
                </div>
              </div>
            </div>
            <div className="col-12 p-0 text-right mb-2">
              <div className='custom-card' style={{ minHeight: 'auto' }}>
                <div className="card-header">
                  <h4 style={{ color: 'var(--main-color-one)' }}>الرؤية</h4>
                </div>
                <div className="card-body">
                  <p>
                    نطمح لان نكون بإذن الله تعالى وتوفيقه الوجهة الاولى والمفضلة لجميع الباحثين عن جميع العقارات والشئون المتعلقة بها في جميع أنحاء الوطن العربي وقد نتوسع في خدمات أخرى لاحقا                </p>
                </div>
              </div>
            </div>
            <div className="col-12 p-0 text-right mb-2">
              <div className='custom-card' style={{ minHeight: 'auto' }}>
                <div className="card-header">
                  <h4 style={{ color: 'var(--main-color-one)' }}>الاستراتيجية</h4>
                </div>
                <div className="card-body">
                  <ul className="custom-list">
                    <li><ListArrow />
                      تطبيق متميز ذو جودة ممتازة ومرونة وآمن للمعلومات.                  </li>
                    <li><ListArrow />
                      واجهة استخدام سهلة وعملية على جميع أنواع الاجهزة.                  </li>
                    <li><ListArrow />
                      الاهتمام بجميع التفاصيل المتعلقة بالبوابة و التطبيقات وخدمة المستخدمين.                  </li>
                  </ul>
                </div>
              </div>
            </div>
       
          </div>
        </div>
      ) : (<AboutUs />) : <></>}
    </AppLayout>
  );
};

export default AboutPage;
