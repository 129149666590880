import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Chip,
  Avatar,
  DialogActions,
  FormControl,
  TextField
} from "@mui/material";
import { useAuth } from "../../../helpers/context";
import { CITIES, DISTRICTS, REGIONS } from "../../../constants";
import { Close, Send } from "@mui/icons-material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import PropertyCardForAdmin from "../../shared/properties/components/property-card-for-admin";
import { getBargain, saveBargainComment } from "../../../api/userApi";
import LoadingButton from "../../../components/global-components/loading-btn";
import { toast } from "react-toastify";

const regionsOptions = REGIONS.map((region) => ({
  id: +region.REGION_ID,
  label: region.REGIONNAME_AR,
}));

const citiesOptions = CITIES.map((city) => ({
  id: +city.CITY_ID,
  label: city.CITYNAME_AR,
  regionId: +city.REGION_ID,
}));

const districtsOptions = DISTRICTS.map((district) => ({
  id: +district.DISTRICT_ID,
  label: district.DISTRICTNAME_AR,
  regionId: +district.REGION_ID,
  cityId: +district.CITY_ID,
}));

const publicUrl = process.env.REACT_APP_URL + "/";

const DealDetails = ({ getData, openDealDialog, setOpenDealDialog, setDeal, deal, companyName }) => {
    const { t } = useTranslation();
    const { token } = useAuth();
    const [content, setContent] = useState('');
    const [isSubmittingData, setIsSubmittingData] = useState(false);

    const resolveStatusName = (status) => {
        const badge = (statusName) => {
            return <Chip label={statusName} size="medium" style={{
                color: status === '0' ? 'gold' : status === '1' ? "blue" : status === '2' ? 'green' : status === '3' ? 'black' : '#d50000',
                borderColor: status === '0' ? 'gold' : status === '1' ? "blue" : status === '2' ? 'green' : status === '3' ? 'black' : '#d50000',
                background: '#fff',
                borderWidth: '1px',
                borderStyle: 'solid'
            }} />;
        };
        
        switch (status) {
            case '0': return badge('صفقة جديدة');
            case '1': return badge("مفاوضات");
            case '2': return badge("مفاوضات متقدّمة");
            case '3': return badge('إتمام الصفقة');
            case '4': return badge('خسارة الصفقة');
            default: return '';
        }
    }
    
    return deal?.id && (
        <Dialog
            fullWidth={true}
            maxWidth={deal?.propertyObject ? "xl" : "lg"}
            open={openDealDialog}
            className="custom-dialog model"
            style={{ direction: "rtl", textAlignLast: "right" }}
            onClose={() => { setOpenDealDialog(false); setDeal({}); }}>
            <DialogTitle>
                <div className="dialog-head">
                    <div>
                        <h2>المشتري: {deal.buyerObject?.name} {resolveStatusName(deal.status)} <br />
                            <span style={{ fontSize: '13px' }}>
                                {deal.createdAt ? moment(new Date(deal.createdAt)).add(3, 'hours').fromNow() : <></>}
                            </span>
                        </h2>
                    </div>
                    <div className="dialog-actions">
                        <span onClick={() => { setOpenDealDialog(false); setDeal({}); }} style={{ cursor: 'pointer' }}>
                            <Close />
                        </span>
                    </div>
                </div>
  
            </DialogTitle>
            <DialogContent>
                <div className='card-body px-0'>
                    <div className="row">
                        <div className={`col-12 ${deal?.propertyObject ? "col-lg-8" : ""}`}>
                            {/* <Divider textAlign="left" className="col-11 mt-2 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
                                {t("BasicInformation")}
                            </Divider> */}
                            <div className='row mx-3 mb-2'>
                                {[
                                    {
                                        label: 'البائع',
                                        value: deal.sellerObject?.name || t("NotAvailable")
                                    },
                                    {
                                        label: 'قيمة الصفقة',
                                        value: Intl.NumberFormat("en").format(deal.value.endsWith('.000') ? parseInt(deal.value) : deal.value) + ' ريال'
                                    },
                                    {
                                        label: 'العمولة',
                                        value: Intl.NumberFormat("en").format(deal.commission.endsWith('.000') ? parseInt(deal.commission) : deal.commission) + ' ريال'
                                    },
                                    {
                                        label: t("Address"),
                                        value: (regionsOptions.filter((i) => +i.id === +deal?.region)?.[0]?.label || null) + ' - ' + (citiesOptions.filter((i) => +i.id === +deal?.city)?.[0]?.label || null) + ' - ' + (districtsOptions.filter((i) => deal?.neighbourhoods?.includes(+i.id))?.map((i, index) => i.label + (index === deal?.neighbourhoods?.length - 1 ? '' : ' ')) || null)
                                    }
                                ]?.map((item) => item.label === "البائع" && !deal.sellerObject?.name ? null : (
                                    <div className='col-12 col-md-6 col-lg-4 mb-4 mb-lg-3' key={item?.label}>
                                        <strong>{item?.label}</strong>
                                        <span className="d-block mt-2 mb-3 text-right">{item?.value}</span>
                                    </div>
                                ))}
                            </div>

                            <Divider textAlign="left" className="col-11 mt-2 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
                                <div className="d-flex align-items-center" style={{ gap: '5px' }}>
                                    التعليقات
                                </div>
                            </Divider>
                           
                            <div className='row mx-0 mb-2 flex-column'>
                                {deal?.comments?.length > 0 && <ul className="p-0">
                                    {deal.comments?.map(item => (
                                        <li key={item.id} style={{ marginBottom: '15px', listStyle: 'none', display: 'flex', gap: '10px' }}>
                                            <Avatar
                                                alt={item.avatar}
                                                sx={{ bgcolor: '#fff', width: '50px', height: '50px', objectFit: 'cover', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
                                                src={item.avatar || publicUrl + "assets/img/defimgs/1_account.svg"}
                                            />
                                            <div className="comment-wrapper">
                                                <div className="d-flex justify-content-between w-100" style={{ gap: '7px' }}>
                                                    <h6 style={{ marginBottom: '2px' }}>{item?.createdBy === companyName ? t("MainAccount") : item?.createdBy}</h6>
                                                    <p style={{ fontSize: '12px', marginTop: 0 }}> {moment(new Date(item.createdAt)).add(3, 'hours').fromNow()}</p>
                                                </div>
                                                <p style={{ fontSize: '12px', margin: 0, textAlign: 'right' }}> {item?.content}</p>
                                            </div>
                                        </li>
                                    ))}</ul>}
                                <FormControl className="my-2 comment-box">
                                    <TextField
                                        placeholder="اكتب تعليقك..."
                                        sx={{
                                            width: "100%",
                                            direction: 'rtl'
                                        }}
                                        className="text-right"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={content}
                                        size='small'
                                        multiline
                                        rows={2}
                                        InputProps={{
                                            endAdornment: (
                                                <LoadingButton
                                                style={{ justifyContent: "center",alignItems:'center' }}
                                                OKdisabled={content?.length === 0}
                                                    loading={isSubmittingData}
                                                    classes='primary-btn px-2 py-1'
                                                    handleClick={() => {
                                                        setIsSubmittingData(true);
                                                        saveBargainComment({
                                                            userId: token,
                                                            bargainId: deal.id,
                                                            content: content,
                                                            language: '0'
                                                        }).then(res => {
                                                            if (res.resCode === 0) {
                                                                setContent("")
                                                                toast.success('تمّت إضافة التعليق بنجاح');
                                                                getBargain({
                                                                    userId: token,
                                                                    language: "0",
                                                                    bargainId: deal?.id
                                                                }).then((res) => {
                                                                    if (res.resCode === 0) {
                                                                        setDeal(res.response);
                                                                    }
                                                                });
                                                                getData();
                                                            } else {
                                                                toast.error(res.resStr);
                                                            }
                                                        }).finally(() => setIsSubmittingData(false));
                                                    }}
                                                    label={<Send sx={{transform:'rotate(-180deg)'}} />}
                                                />
                                            ),
                                        }}
                                        onChange={(e) => setContent(e.currentTarget.value)}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        
                        {deal?.propertyObject && (<div className="col-12 col-lg-4">
                            <Divider textAlign="left" className="col-11 mt-2 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
                                الإعلان العقاري المرتبط بالصفقة
                            </Divider>
                            <div
                                dir="ltr"
                                className='col-12 col-md-6 col-lg-8 m-auto m-lg-0'
                                style={{ cursor: "pointer" }}>
                                <PropertyCardForAdmin item={deal?.propertyObject} hideActions />
                            </div>
                        </div>)}
                    </div>
                </div>
               
            </DialogContent>
            <DialogActions sx={{ paddingInline: '25px', flexDirection: { xs: 'column', lg: 'row' }, alignItems: 'flex-start', justifyContent: 'flex-start', gap: '10px' }}>
                <span style={{ fontSize: '13px' }}><b>{t("AddedBy")}:</b> {deal.added_by === companyName ? t("MainAccount") : deal.added_by}</span>
                <span style={{ fontSize: '13px' }}><b>{t("UpdatedAt")}:</b> {deal.updatedAt ? moment(new Date(deal.updatedAt)).add(3, 'hours').fromNow() : t("NotAvailable")}</span>
            </DialogActions>
        </Dialog>
    );
}

export default DealDetails;
