import React from "react";
import { Link } from "react-router-dom";

const ChatMessage = (props) => {
  // let profile = "";

  // switch (props.profileType) {
  //   case ProfileType.Image:
  //     profile = (
  //       <div className={"avatar " + status}>
  //         <img src={props.profile} alt=""></img>
  //       </div>
  //     );
  //     break;
  //   case ProfileType.Svg:
  //     profile = (
  //       <div className="avatar bg-success text-light">
  //         <span>{props.profile}</span>
  //       </div>
  //     );
  //     break;
  //   case ProfileType.Text:
  //     profile = (
  //       <div className={"avatar bg-info text-light " + status}>
  //         <span>EW</span>
  //       </div>
  //     );
  //     break;
  //   default:
  //     break;
  // }

  return (
    <li
      className={
        "contacts-item friends " +
        (props.ChatUserId &&
        window.location.href.substring(
          window.location.href.lastIndexOf("/") + 1
        ) === props.ChatUserId
          ? "active "
          : " ")
        // + (props.badge ? "unread" : "")
      }>
      <Link
        className='contacts-link'
        to={props.to}
        onClick={props.handleChatClick}>
        {/* {profile} */}
        <div className='contacts-content text-right mr-3'>
          <div className='contacts-info text-right'>
            <h6
              className='chat-name'
              style={{fontWeight:600 }}>
              {props.name}
            </h6>
            <div className='chat-time'>{props.time}</div>
          </div>

          {/* <h6 className='chat-time'>{props.name}</h6> */}
          <div className='contacts-texts'>
            <h6 className='chat-time text-truncate'>{props.message}</h6>
            {props.endIcon ? (
              <div className='d-inline-flex align-items-center'>
                {props.endIcon}
              </div>
            ) : null}
            {props.badge ? (
              <div className='badge badge-rounded badge-primary'>
                {props.badge}
              </div>
            ) : null}
          </div>
        </div>
      </Link>
    </li>
  );
};
export default ChatMessage;
