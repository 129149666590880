import React, { useEffect, useState, useRef } from "react";
import { getListOfChannelsForUser } from "../../../api/userApi";
import { ProfileType, UserStatus } from "../../../Config/Config";
import ChatMessage from "./Message/ChatMessage";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useAuth } from "../../../helpers/context";
import NoData from "../../global-components/no-data";
import { useTranslation } from "react-i18next";

const publicUrl = process.env.REACT_APP_URL + "/";

const ChatList = (props) => {
  const isAdmin = window.location.pathname.includes('admin');
  const { t } = useTranslation();
  const ENDPOINT = process.env.REACT_APP_CHAT_URL;
  const { token, UserId } = useAuth();
  const history = useNavigate();
  let { id } = useParams();

  const [user] = useState({
    userId: token,
    language: "0",
    page: "1",
    pageSize: "50",
  });
  const [Mesg, setMesg] = useState("wait");
  // const [TotalCount, setTotalCount] = useState("0");
  const socketRef = useRef();

  const [Chats, setChats] = useState([]);
  const [selected, setSelected] = useState(false);

  function getDateName(unix_timestamp) {
    let date = moment(unix_timestamp, "YYYY-MM-DD HH:mm").toDate();
    let todaysDate = new Date();
    let Yesterday = new Date(new Date().setDate(todaysDate.getDate() - 1));
    if (date.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0))
      return new Intl.DateTimeFormat("ar-SY", {
        hour: "numeric",
        minute: "numeric",
      }).format(moment(unix_timestamp, "YYYY-MM-DD HH:mm").toDate());
    else if (date.setHours(0, 0, 0, 0) === Yesterday.setHours(0, 0, 0, 0))
      return "الأمس";
    else
      return new Intl.DateTimeFormat("ar-SY", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(date);
  }

  useEffect(() => {
    if (token) {
      socketRef.current = window.io(ENDPOINT);
      socketRef.current.emit("inboxRefresh", {
        myUserId: UserId,
      });
      socketRef.current.on("inboxRefresh", (msg) => {
        setTimeout(function () {
          getListOfChannelsForUser(user).then((_userInfo) => {
            if (_userInfo.resCode === 0) {
              // setTotalCount(_userInfo.response.totalCount);
              let chat = [];
              chat = _userInfo.response.chatArray;
              if (chat.length > 0) {
                let chatArray = [];
                chat.forEach((element) => {
                  // let reciverId = element.userId1;

                  let reciverName = element.userName1;
                  if (element.userId1 === UserId)
                    reciverName = element.userName2;

                  let propertyImage =
                    publicUrl +
                    "assets/img/defimgs/" +
                    element.propertyType +
                    ".svg";

                  if (element.photos) {
                    let photos = element.photos.split(",");
                    propertyImage = photos[0];
                  }

                  chatArray.push({
                    ChatUserId: element.channelId,
                    to: (isAdmin ? "/admin/chat/": "/Chats/") + element.channelId,
                    name: reciverName,
                    profile: propertyImage,
                    profileType: ProfileType.Image,
                    time: getDateName(element.updatedAt),
                    message: element.lastMessage,
                    status: UserStatus.Online,
                    propertyTypeName: element.propertyTypeName,
                    listedFor: element.listedFor,
                    badge: parseInt(element.unreadCount),
                  });
                });
                setChats(chatArray);
                if(!id)
                  history(chatArray[0].to);
              } else {
                setMesg("لم يتم العثور على رسائل");
              }
            } else {
              const _errors = {};
              _errors.phone = _userInfo.resStr;
            }
          });
        }, 350);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserId, publicUrl, token, user]);
  // Get userid from parameter to load perticular user chat history

  function handleChatClick(id) {
    let newChats = Chats.map((el) => {
      if (el.ChatUserId === id) return Object.assign({}, el, { badge: 0 });
      return el;
    });
    setChats(newChats);
    setSelected({ id: id, selected: true });
    props.handleChatClick();
  }

  return token && (
    <aside className={`sidebar ${isAdmin ? 'radius-16 border shadow' : ''}`}>
      <div className='tab-content'>
        <div className='tab-pane active radius-16 border' id='chats-content'>
          <div className='d-flex flex-column h-100'>
            <div
              className='h-100'
              id='chatContactsList'
              style={{ backgroundColor: "#fff" }}>
              <div
                className='sidebar-header sticky-top py-2 d-flex justify-content-between align-items-center'
                style={{
                  height: "81px",
                }}>
                <div className='d-flex justify-content-between align-items-center'>
                  {!isAdmin && (
                    <Link className='contacts-link' to='/'>
                      <button
                        className='btn btn-secondary btn-icon btn-minimal btn-sm text-muted'
                        style={{
                          paddingTop: "8px",
                          marginLeft: "8px",
                        }}
                        type='button'>
                        <ArrowBack style={{ transform: 'rotate(180deg)' }} />
                      </button>
                    </Link>
                  )}
                  
                  <h5
                    className='font-weight-semibold mb-0  w-100'
                    style={{
                      paddingTop: "8px",
                      textAlignLast: "right",
                      marginRight: "20px",
                    }}>
                    {t("Messages")}
                  </h5>
                </div>
              </div>
              <ul
                className='contacts-list'
                id='chatContactTab'
                data-chat-list=''>
                {Chats.length > 0 ? (
                  Chats.map((chat, index) => {
                    return (
                      <ChatMessage
                        key={"chat" + index}
                        {...chat}
                        handleChatClick={() => {
                          handleChatClick(chat.ChatUserId);
                        }}
                        selected={selected}
                      />
                    );
                  })
                ) : (
                  <div>
                    <div className='text-align-last-center mt-2' dir='rtl'>
                    {Mesg==='wait' ? <CircularProgress/> : <NoData msg={Mesg}/>}
                    </div>
                  </div>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};
export default ChatList;
