import React from "react";
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import Projects from "./components/projects";
import Properties from "./components/properties";
import TopStatistics from "./components/top-statistics";

const DashboardPage = () => {
  const { AccountType, UserType, currentPlan } = useAuth();

  return (
    <>
      <SubHeader OKElement={<></>} />
      {UserType !== '5' && <TopStatistics />}
      {AccountType === '2' && UserType !== '5' && [8, 9].includes(+currentPlan?.plan?.id) && (<Projects />)}
      <Properties />
    </>
  );
}

export default DashboardPage;
