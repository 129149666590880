import React from "react";
import { FormControlLabel, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";

const inlineStyle = {
  marginTop: "40px",
  direction: "ltr",
  marginBottom: "40px",
  textAlignLast: "right"
};

const SectionWrapper = ({ children, section, sectionShowHide, description, extraClasses = '', extraStyles = {}, customHeader }) => {
 
  const { t } = useTranslation();

  return (
    <div className={`custom-card with-switcher col-12 p-0 ${extraClasses}`} style={{ ...inlineStyle, ...extraStyles }}>
      <div className="card-header">
        {customHeader ? customHeader : (
          <div>
            <h4>{section.nameAr}</h4>
            <h6>{description}</h6>
          </div>
        )}
       
        <div>
          <FormControlLabel
            sx={{ margin: 0 }}
            control={
              <Switch
                checked={section.isVisible === "1"}
                onChange={(e) =>
                  sectionShowHide(section.key, section.isVisible)
                }
              />
            }
            label={t("Show")}
          />
        </div>
      </div>
      <div className="card-body" style={{ opacity: section.isVisible === "0" ? 0.5 : 1 }}>
        {children}
      </div>
    </div>
  );
}

export default SectionWrapper;
