import React, { useEffect, useState } from "react";
import { getUserProile } from "../../../api/userApi";
import { Link, useNavigate } from "react-router-dom";
import CompanyValidation from "./components/company-validation";
import LoginPopup from "../../../components/user-components/login-popup";
import { Box, CircularProgress } from "@mui/material";
import AppLayout from "../../../components/layouts/app-layout";
import { useAuth } from "../../../helpers/context";
import { useTranslation } from "react-i18next";

export default function Verify() {
  const { token, setToken, setUserId, setUserType } = useAuth();
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [loadingUserInfo, setLoadingUserInfo] = useState(true);
  const [userInformation, setuserInformation] = useState();
  const [userType, setuserType] = useState("");
  const history = useNavigate();
  let publicUrl = process.env.REACT_APP_URL + "/";
  const { t } = useTranslation();

  // const [errors, setErrors] = useState({});
  // const [user, setUser] = useState({
  //   licenseNumber: "",
  //   licenseType: "0",
  //   userId: token,
  //   language: "0",
  // });
  // let options = [
  //   { value: "1", label: "ترخيص الهيئة العامة للعقار" },
  //   { value: "2", label: "رخصة عمل حر" },
  // ];

  // const groupStyles = {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "space-between",
  // };
  // const groupBadgeStyles = {
  //   backgroundColor: "#EBECF0",
  //   borderRadius: "2em",
  //   color: "#172B4D",
  //   display: "inline-block",
  //   fontSize: 12,
  //   fontWeight: "normal",
  //   lineHeight: "34px",
  //   minWidth: 1,
  //   padding: "0.16666666666667em 0.5em",
  //   textAlign: "center",
  // };

  // const formatGroupLabel = (data) => (
  //   <div style={groupStyles}>
  //     <span>{data.label}</span>
  //     <span style={groupBadgeStyles}>{data.options.length}</span>
  //   </div>
  // );

  // function formIsValid() {
  //   const _errors = {};
  //   if (user.licenseType === "0") _errors.licenseType = "اختر نوع الترخيص";

  //   if (!user.licenseNumber) _errors.licenseNumber = "ادخل رقم الرخصة";

  //   setErrors(_errors);
  //   return Object.keys(_errors).length === 0;
  // }

  function brokerIAMVerified() {
    // if (userType === "3") {
    //   if (!formIsValid()) return;

    //   saveLicenseNumber(user).then((_userInfo) => {
    //     if (_userInfo.resCode === 0)
    //       window.location.replace("https://iam.amakkn.com");
    //   });
    // } else {
    window.location.replace("https://iam.amakkn.com");
    // }
  }
  useEffect(() => {
    if (token)
      getUserProile({
        userId: token,
        language: "0",
      })
        .then((_userInfo) => {
          if (_userInfo.resCode === 0) {
            setLoadingUserInfo(false);
            setuserInformation(_userInfo.response.userProfile);
            // if (_userInfo.response.userProfile.accountType === "1")
            //   window.location.replace("https://iam.amakkn.com");

            switch (_userInfo.response.userProfile.isUserVerified) {
              case "0":
                setuserType(_userInfo.response.userProfile.userType);
                break;
              case "1":
                history(
                  "/companylocation/" +
                  (_userInfo.response.userProfile.latitude
                    ? _userInfo.response.userProfile.latitude
                    : "24.74772941029266") +
                  "/" +
                  (_userInfo.response.userProfile.longitude
                    ? _userInfo.response.userProfile.longitude
                    : "46.619290983645406") +
                  "/" +
                  (_userInfo.response.userProfile.address
                    ? _userInfo.response.userProfile.address
                    : "empty")
                );
                break;
              case "2":
                history("/successverfication");
                break;
              case "3":
                setIsUserVerified(true);
                break;
              default:
                break;
            }
          } else {
            setLoadingUserInfo(false);
          }
        })
        .catch((err) => setLoadingUserInfo(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <AppLayout needAuth pageTitle={t("AccountVerification")}>
      <div className='page-wrapper page-width'>
        <div className='row page-wrapper-without-top'>
          <div className='col-12 col-lg-6 details-wrapper scrolled'>
            <div
              style={{
                maxHeight: "100vh",
                overflowY: "auto",
                padding: "20px 10% 70px",
              }}>
              <Box
                sx={{
                  marginBottom: "40px",
                  textAlign: "right",
                  fontSize: "28px",
                  fontWeight: 600,
                  padding: "10px 0",
                  color: "#000",
                }}>
                {t("AccountVerification")}
              </Box>
              {loadingUserInfo ? (
                <div className='d-flex align-items-center justify-content-center w-100 h-100'>
                  <CircularProgress />
                </div>
              ) : userInformation ? (
                userType !== "1" ? (
                  !isUserVerified ? (
                    <>
                      {userType === "4" ? (
                        <CompanyValidation />
                      ) : (
                        <>
                          {/* {userType === "3" && (
                <div className='container-fluid' style={{ marginTop: "30px" }}>
                  <div className='row justify-content-md-center'>
                    <div className='col col-lg-3 form-group'>
                      <label
                        style={{
                          float: "right",
                          direction: "rtl",
                          width: "100%",
                          textAlignLast: "right",
                        }}>
                        نوع الترخيص
                      </label>
                      <div className='field'>
                        <Select
                          name='name'
                          value={options.filter(
                            (option) => option.value === user.licenseType
                          )}
                          options={options}
                          onChange={(e) =>
                            setUser({ ...user, licenseType: e.value })
                          }
                          formatGroupLabel={formatGroupLabel}
                          isRtl={true}
                          defaultValue={{
                            value: "0",
                            label: "أختر نوع الترخيص",
                          }}
                        />
                        {errors.licenseType && (
                          <div className='alert alert-danger'>
                            {errors.licenseType}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='row justify-content-md-center mt-4'>
                    <div className='col col-lg-3'>
                      <TextInput
                        id='licenseNumber'
                        label='رقم الرخصة'
                        onChange={(e) =>
                          setUser({
                            ...user,
                            licenseNumber: e.currentTarget.value,
                          })
                        }
                        name='licenseNumber'
                        value={user.licenseNumber}
                        type='number'
                        error={errors.licenseNumber}
                      />
                    </div>
                  </div>
                </div>
              )} */}
                          <div className='col-12 p-0'>
                            <h5 className='mb-4'>
                              يتم توثيق الحساب عن طريق بوابة النفاذ الوطني
                              الموحد
                            </h5>
                          </div>

                          <div className='col-12 p-0'>
                            <button
                              className='primary-btn py-2 px-4 mb-3'
                              onClick={(e) => brokerIAMVerified()}>
                              التوثيق عبر النفاذ الوطني
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <div className='col-12 p-0'>
                        <h4 className='mb-4' dir='rtl'>
                          حسابك موثق!
                        </h4>
                      </div>
                      <div className='col-12 p-0'>
                        <Link
                          to='/'
                          className='primary-btn py-2 px-4 mb-3 d-flex justify-content-center'
                          style={{ width: "fit-content" }}>
                         {t("BackToHomePage")}
                        </Link>
                      </div>
                    </>
                  )
                ) : (
                  <>
                    <div className='col-12 p-0'>
                      <h5 className='mb-4' style={{ width: "90%" }}>
                        أنت مستخدم عادي! يجب عليك تغير نوع المستخدم (لمالك عقار
                        / مُسوّق مُفوّض / حساب أعمال) لكي تتمكن من توثيق حسابك
                        في أماكن
                      </h5>
                    </div>
                    <div className='col-12 p-0'>
                      <Link
                        to='/my-profile'
                        className='primary-btn py-2 px-4 mb-3'
                        style={{
                          marginBottom: "30px",
                          marginTop: "20px",
                          width: "fit-content",
                        }}>
                        صفحتي الشخصية
                      </Link>
                    </div>
                  </>
                )
              ) : (
                <>
                  <div className='col-12 p-0'>
                    <h4 className='mb-4'>من فضلك قم بتسجيل الدخول</h4>
                  </div>
                  <div className='col-12 p-0'>
                    <LoginPopup
                      setToken={setToken}
                      setUserId={setUserId}
                      setUserType={setUserType}
                      settext={t("Login")}
                      btnTyp='1'
                      path='/verify'
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            className='col-12 d-none d-lg-flex col-lg-6 bg-cover py-5 py-lg-0'
            style={{
              background: `url(${publicUrl}assets/img/pattern.png) repeat`,
            }}>
            <div className='img-container'>
              <img
                src={publicUrl + "assets/img/others/others/2.png"}
                alt='whitelabel'
              />
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
