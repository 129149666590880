import React, { useState, useEffect } from "react";
import { getCompanyDetailsNew } from "../../api/propertyApi";
import CorpreateProfile from "../../components/section-components/company-profile";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import AppLayout from "../../components/layouts/app-layout";
import Forbidden from "../shared/403";
import { useAuth } from "../../helpers/context";
import { useTranslation } from "react-i18next";

const CompanyProfile = (props) => {
  const { token } = useAuth();
  let userId;
  let { id } = useParams();
  let location = useLocation();
  // const [page, setPage] = useState('1');
  const isAdmin = location.pathname.includes('admin');
  if (id) userId = id;
  else if (location.state.userId) userId = location.state.userId;
  
  const [user] = useState({
    userId: userId,
    language: "0",
  });

  const [userInformation, setuserInformation] = useState();
  const { t } = useTranslation();
  const [msg, setMsg] = useState("wait");

  // function isNumeric(str) {
  //   if (typeof str != "string") return false; // we only process strings!
  //   return (
  //     !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  //     !isNaN(parseFloat(str))
  //   ); // ...and ensure strings of whitespace fail
  // }
  useEffect(() => {
    if (token)
      getCompanyDetailsNew({
        companyUserId: user.userId,
        page: '1',
        sortBy: "updatedAt",
        sortOrder: "desc",
        pageSize: "99999",
        userId: token,
        isCompany: "1",
        language: "0",
      }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          setuserInformation(_userInfo.response);
          setMsg("Done");
        } else {
          toast.error("الشركة غير موجودة");
          setMsg("الشركة غير موجودة");
        }
      });
  }, [token, user]);

  return (<AppLayout withoutNav={isAdmin} pageTitle={userInformation?.hostInfo?.userType === '5' ? 'تفاصيل المعلن' : t("CompanyProfile")} needAuth>
    {
      token && (
        msg === "Done" ? (
          <CorpreateProfile
            user={userInformation.hostInfo}
            plan={userInformation.plan}
            properties={userInformation.propertyArray}
            totalCount={userInformation.totalCount}
            token={token}
          // setPage={setPage}
          // page={page}
          />
        ) :
          msg === 'wait' ?
            <div className='add-new-property-area pd-top-90 mg-bottom-100 text-center'> <CircularProgress />  </div>
            : <Forbidden btnLink={'/'} btnText={t("BackToHomePage")} desc={msg} />
      )
    }
  </AppLayout>
  );
};

export default CompanyProfile;
