import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import {
  editAgent,
  deleteAgent,
  uploadProfileImageForAgent,
  saveProfilePictureForAgent,
} from "../../../api/userApi";
import { getPropertiesOfOwnerNew } from "../../../api/propertyApi";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import md5 from "md5";
import AppLayout from "../../../components/layouts/app-layout";
import { openDeleteModal, resolvePersianAndArabicNumbers, validateEmail,getDemoSnakbar } from "../../../helpers";
import { Edit } from "@mui/icons-material";
import { Trash } from "../../../constants/icons";
import LoadingButton from "../../../components/global-components/loading-btn";
import { useAuth } from "../../../helpers/context";
import { Alert, Avatar, Box, FormControl, FormHelperText } from "@mui/material";
import DragAndDropFileUploader from "../../../components/global-components/drag-drop-file-uploader";
import ChangePasswordModal from "../../../components/global-components/change-password-modal";
import { DEMO_VENDORS } from "../../../constants";
import { useTranslation } from "react-i18next";

let publicUrl = process.env.REACT_APP_URL + "/";
  
const EditAgent = () => {
  const { token, UserId, vendor } = useAuth();
  const history = useNavigate();
  const [loadingButton, setLoadingButton] = useState(false);
  const [AgentID, setAgentID] = useState();
  const [Update, setUpdate] = useState();
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [Agent, setAgent] = useState({
    name: "",
    email: "",
    phone: "",
    avatar: ''
  });
  const [errors, setErrors] = useState({});
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  let { agentId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (token)
      getPropertiesOfOwnerNew({
        ownerId: agentId,
        page: "1",
        sortBy: "updatedAt",
        sortOrder: "desc",
        pageSize: "10",
        userId: token,
        language: "0",
      }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          setAgent(_userInfo.response.hostInfo);
          const userId =
            _userInfo.response.hostInfo.createdAt +
            _userInfo.response.hostInfo.id;

          setAgentID(md5(userId));
        } else {
          toast.error(t("UserNotFound"));
        }
      });
  }, // eslint-disable-next-line
    [agentId, token, Update]);

  const uploadUserImage = async (file) =>
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "JPEG",
      100,
      0,
      async (uri) => {
        if (DEMO_VENDORS.includes(vendor)) {
          setAgent({ ...Agent, avatar: uri });
        } else {
          await uploadProfileImageForAgent({
            pathWithFileName: "User/" + Agent.id + "/Profile/" + file.name,
            superUserId: UserId,
            agentId: AgentID,
            base64Data: uri,
          })
            .then((property) => {
              if (property.resCode === 0) saveUserProfile(false);
            })
            .catch((error) => {
              throw error;
            });
        }
      },
      "base64"
    );
  
  function formIsValid() {
    const _errors = {};
  
    if (!Agent.name) _errors.name = "ادخل الاسم";
    if (Agent.email && !validateEmail(Agent.email))
      _errors.email = "الرجاء ادخال بريد إلكتروني صحيح";
    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  function deleteUserImage() {
    if (DEMO_VENDORS.includes(vendor)) {
      setAgent({ ...Agent, avatar: undefined });
    } else {
      saveProfilePictureForAgent({
        superUserId: token,
        agentId: agentId,
        profilePicture: "-1",
        language: "0",
      }).then((e) => {
        if (e.resCode === 0) saveUserProfile(false);
      });
    }
  }

  function saveUserProfile(changePage = true) {
    if (!formIsValid()) return;
    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);
    } else {
      setLoadingButton(true);
      editAgent({
        superUserId: UserId,
        agentId: AgentID,
        name: Agent.name,
        email: Agent.email,
        phone: Agent.phone
      }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          toast.success("تم التعديل بنجاح");
          setUpdate(Math.random());
          if (vendor !== 'amakkn' && changePage) {
            history('/admin/agents');
          } else if (vendor === 'amakkn' && changePage) {
            history('/agents');
          }
        }
        setLoadingButton(false);
      }).catch(e => setLoadingButton(false));
    }
  }

  return (
    <AppLayout needAuth pageTitle="تعديل حساب فرعي" withoutNav={vendor !== 'amakkn'}>

      {token && Agent && (
        <div className={`${vendor !== 'amakkn' ? 'admin' : ''} page-wrapper page-width`}>
          {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}
          <div className="row page-wrapper-without-top">
            <div className="col-12 col-lg-6 details-wrapper scrolled">
              <div style={{ maxHeight: '100vh', overflowY: 'auto', padding: '20px 10% 70px' }}>
                <div className='container'>
                  <h4 className="page-heading">تعديل حساب فرعي</h4>
                  <div className='col-12 my-4'>
                    <div className=' avatar avatar-xl  mb-4 d-flex align-items-center' style={{ gap: '10px' }}>
                      <Avatar
                        sx={{ width: 80, height: 80, bgcolor: Agent.avatar ? '#fff' : 'var(--main-color-one)' }}
                        src={Agent.avatar}
                        alt='' />
                      <div className='d-flex h-100 align-items-center justify-content-center flex-column' style={{ gap: '10px' }}>
                        <div className='btn-outline-default btn-icon rounded-circle'>
                          <DragAndDropFileUploader
                            singleFile
                            ImageOnly
                            icon={<Edit />}
                            style={{
                              borderRadius: '100%',
                              padding: '2px'
                            }}
                            handleChange={(e) => uploadUserImage(e.target.files[0])}
                            id="file-input"
                            buttonMode
                          />
                        </div>
                        {Agent.avatar !== "" && (
                          <div
                            className='btn btn-danger btn-icon rounded-circle text-light mx-1'
                            onClick={(e) => {
                              deleteUserImage();
                            }}>
                            <Trash />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-12 mb-4 p-0 mt-4" dir="rtl">
                    <FormControl
                      error={Boolean(errors.name)}
                      className="col-12" dir="rtl"
                    >
                      <TextField
                        label={t("Name")}
                        type='text'
                        disabled
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        sx={{ width: '100%' }}
                        // onChange={(e) => {
                        //   setAgent({ ...Agent, name: resolvePersianAndArabicNumbers(e.currentTarget.value) });
                        // }}
                        value={Agent.name}
                        error={Boolean(errors.name)}
                      />
                      {errors.name &&
                        <FormHelperText component={Box}>
                          <Alert severity="error" className="custom-alert">
                            {errors.name}
                          </Alert>
                        </FormHelperText>}
                    </FormControl>
                    <span
                      onClick={() => {
                        setOpenChangePasswordModal(true);
                      }}
                      style={{ cursor: 'pointer', fontSize: '13px', textDecoration: 'underline', textAlign: 'right' }}>
                      تغير كلمة المرور
                    </span>
                  </div>

                  <div className="col-12 col-md-6 col-lg-12 mb-4 p-0 mt-4 d-flex flex-column" dir='ltr' style={{ gap: '4px' }}>

                    <TextField
                      label={t("PhoneNumber")}
                      disabled
                      type='text'
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      sx={{ width: '100%' }}
                      value={"0" + Agent.phone}
                    />
                    <Link
                      to={vendor === 'amakkn' ? `/change-agent-phone/${Agent.id}` : `/admin/change-agent-phone/${AgentID}`}
                      style={{ fontSize: '13px', textDecoration: 'underline', textAlign: 'right' }}>
                      {t('ChangePhoneNumber')}
                    </Link>
                   
                  </div>
                  
                  <div className="col-12 col-md-6 col-lg-12 mb-4 p-0 mt-4" dir="ltr">
                    <FormControl
                      error={Boolean(errors.email)}
                      className="col-12" dir="rtl"
                    >
                      <TextField
                        label={t("Email")}
                        type='text'
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        sx={{ width: '100%' }}
                        onChange={(e) => {
                          setAgent({
                            ...Agent,
                            email: resolvePersianAndArabicNumbers(e.currentTarget.value),
                          });
                        }}
                        value={Agent.email}
                        error={Boolean(errors.email)}
                      />
                      {errors.email &&
                        <FormHelperText component={Box}>
                          <Alert severity="error" className="custom-alert">
                            {errors.email}
                          </Alert>
                        </FormHelperText>}
                    </FormControl>
                  </div>
                   
                  <div className='row justify-content-start my-2 mx-0' style={{ gap: '15px' }}>
              
                    <LoadingButton
                      classes="primary-btn d-flex  justify-content-center py-2 mt-2 px-5"
                      label={t("SaveChanges")}
                      loading={loadingButton}
                      handleClick={saveUserProfile}
                    />
                    <button
                      className="primary-btn delete-btn d-flex  justify-content-center py-2 mt-2 px-5"
                      onClick={() => {
                        if (DEMO_VENDORS.includes(vendor)) {
                          setOpenDemoSnackBar(true);
                        } else {
                          openDeleteModal("حذف حساب فرعي", "هل أنت متأكد من حذف هذا الحساب؟ لا يمكنك التراجع عن هذه العملية", () => {
                            deleteAgent({
                              superUserId: UserId,
                              agentId: AgentID,
                            }).then((_userInfo) => {
                              if (_userInfo.resCode === 0) {
                                toast.success("تم الحذف بنجاح");
                                history(vendor === 'amakkn' ? "/agents" : '/admin/agents');
                              }
                            });
                          }, 'حذف المستخدم');
                        }
                      }}>
                      حذف المستخدم
                    </button>
                  </div>
                 
                  
                </div>
              </div>
            </div>
            <div className="col-12 d-none d-lg-flex col-lg-6 bg-cover py-5 py-lg-0" style={{ background: `url(${publicUrl}assets/img/pattern.png) repeat` }}>
              <div className="img-container">
                <img src={publicUrl + 'assets/img/others/others/2.png'} alt="whitelabel" />
              </div>
            </div>
          </div>
       
          <ChangePasswordModal
            openChangePasswordModal={openChangePasswordModal}
            setOpenChangePasswordModal={setOpenChangePasswordModal}
            agentId={AgentID}
            agent
          />
        </div>
      )}
    </AppLayout>
  );
}

export default EditAgent;
