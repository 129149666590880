import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import UploadImage from "../global-components/upload-image";
import LoadingButton from "../global-components/loading-btn";
import { useAuth } from "../../helpers/context";
import { resolvePersianAndArabicNumbers } from "../../helpers";
import { CLASSIC_TEMPLATES, DARK_TEMPLATES, MODERN_TEMPLATES } from "../../constants";
import YoutubeDialog from "../youtube-dialog";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BannerV2 = (props) => {
  const [Data, setData] = useState(props.data);
  const { token, vendor, logo, templateId } = useAuth();
  const [publicUrl, setPublicUrl] = useState("https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" + vendor + "/");
  const [ImageTOView, setImageTOView] = useState(publicUrl + "assets/img/banner/1.jpg?" + Math.random());
  const youtubeLink = props.youtubeLink;
  const { t } = useTranslation();

  useEffect(() => {
    if (vendor) {
      setPublicUrl("https://amakknbackendupload.s3.me-south-1.amazonaws.com/whitelabel/" + vendor + "/");
    }

    const homeStorage = localStorage.getItem(`home-${vendor}`);
    if (homeStorage) {
      setImageTOView(homeStorage || publicUrl + "assets/img/banner/1.jpg?" + Math.random());
    }

  },// eslint-disable-next-line
    [vendor]);



  function changeImage(img) {
    setImageTOView(img ? img : publicUrl + "assets/img/banner/1.jpg?" + Math.random());
    handleSave();
  }

  function handleSave() {
    props.saveDetails({ data: Data });
  }

  const inlineStyle = {
    background: "url(" + ImageTOView + ") no-repeat center",
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed'
  };
  return (
    <>
      {CLASSIC_TEMPLATES.includes(+templateId) && !props.isEdit ? (
        <section className="home-section">
          <div className="bg-shape-5 d-none d-lg-block"></div>
          <div className="bg-shape-6"></div>
          <div className="container position-relative min-height-100vh d-flex align-items-center pt-100 pb-100 mx-auto">
          
            <div className="home-content text-start">
              <div className="row">
                <div className="col-lg-6 d-flex align-items-center">
                  <div className="w-100 ps-xl-3">
                    <div className="composition">
                      <div className="composition-decoration-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="151" height="131" viewBox="0 0 151 131">
                          <path fillRule="evenodd" fill="var(--paragraph-color)" d="M5.5,2A3.5,3.5,0,1,1,2,5.5,3.5,3.5,0,0,1,5.5,2Zm0,20A3.5,3.5,0,1,1,2,25.5,3.5,3.5,0,0,1,5.5,22Zm0,20A3.5,3.5,0,1,1,2,45.5,3.5,3.5,0,0,1,5.5,42Zm0,20A3.5,3.5,0,1,1,2,65.5,3.5,3.5,0,0,1,5.5,62Zm0,20A3.5,3.5,0,1,1,2,85.5,3.5,3.5,0,0,1,5.5,82Zm0,20A3.5,3.5,0,1,1,2,105.5,3.5,3.5,0,0,1,5.5,102Zm0,20A3.5,3.5,0,1,1,2,125.5,3.5,3.5,0,0,1,5.5,122Z" />
                          <path fillRule="evenodd" fill="var(--paragraph-color)" d="M25.5,2A3.5,3.5,0,1,1,22,5.5,3.5,3.5,0,0,1,25.5,2Zm0,20A3.5,3.5,0,1,1,22,25.5,3.5,3.5,0,0,1,25.5,22Zm0,20A3.5,3.5,0,1,1,22,45.5,3.5,3.5,0,0,1,25.5,42Zm0,20A3.5,3.5,0,1,1,22,65.5,3.5,3.5,0,0,1,25.5,62Zm0,20A3.5,3.5,0,1,1,22,85.5,3.5,3.5,0,0,1,25.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,25.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,25.5,122Z" />
                          <path fillRule="evenodd" fill="var(--paragraph-color)" d="M45.5,2A3.5,3.5,0,1,1,42,5.5,3.5,3.5,0,0,1,45.5,2Zm0,20A3.5,3.5,0,1,1,42,25.5,3.5,3.5,0,0,1,45.5,22Zm0,20A3.5,3.5,0,1,1,42,45.5,3.5,3.5,0,0,1,45.5,42Zm0,20A3.5,3.5,0,1,1,42,65.5,3.5,3.5,0,0,1,45.5,62Zm0,20A3.5,3.5,0,1,1,42,85.5,3.5,3.5,0,0,1,45.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,45.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,45.5,122Z" />
                          <path fillRule="evenodd" fill="var(--paragraph-color)" d="M65.5,2A3.5,3.5,0,1,1,62,5.5,3.5,3.5,0,0,1,65.5,2Zm0,20A3.5,3.5,0,1,1,62,25.5,3.5,3.5,0,0,1,65.5,22Zm0,20A3.5,3.5,0,1,1,62,45.5,3.5,3.5,0,0,1,65.5,42Zm0,20A3.5,3.5,0,1,1,62,65.5,3.5,3.5,0,0,1,65.5,62Zm0,20A3.5,3.5,0,1,1,62,85.5,3.5,3.5,0,0,1,65.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,65.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,65.5,122Z" />
                          <path fillRule="evenodd" fill="var(--paragraph-color)" d="M85.5,2A3.5,3.5,0,1,1,82,5.5,3.5,3.5,0,0,1,85.5,2Zm0,20A3.5,3.5,0,1,1,82,25.5,3.5,3.5,0,0,1,85.5,22Zm0,20A3.5,3.5,0,1,1,82,45.5,3.5,3.5,0,0,1,85.5,42Zm0,20A3.5,3.5,0,1,1,82,65.5,3.5,3.5,0,0,1,85.5,62Zm0,20A3.5,3.5,0,1,1,82,85.5,3.5,3.5,0,0,1,85.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,85.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,85.5,122Z" />
                          <path fillRule="evenodd" fill="var(--paragraph-color)" d="M105.5,2A3.5,3.5,0,1,1,102,5.5,3.5,3.5,0,0,1,105.5,2Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,105.5,22Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,105.5,42Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,105.5,62Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,105.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,105.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,105.5,122Z" />
                          <path fillRule="evenodd" fill="var(--paragraph-color)" d="M125.5,2A3.5,3.5,0,1,1,122,5.5,3.5,3.5,0,0,1,125.5,2Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,125.5,22Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,125.5,42Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,125.5,62Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,125.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,125.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,125.5,122Z" />
                          <path fillRule="evenodd" fill="var(--paragraph-color)" d="M145.5,2A3.5,3.5,0,1,1,142,5.5,3.5,3.5,0,0,1,145.5,2Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,145.5,22Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,145.5,42Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,145.5,62Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,145.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,145.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,145.5,122Z" />
                        </svg>
                      </div>
                      <div className="composition-decoration-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="151" height="131" viewBox="0 0 151 131">
                          <path fillRule="evenodd" fill="var(--paragraph-color)" d="M5.5,2A3.5,3.5,0,1,1,2,5.5,3.5,3.5,0,0,1,5.5,2Zm0,20A3.5,3.5,0,1,1,2,25.5,3.5,3.5,0,0,1,5.5,22Zm0,20A3.5,3.5,0,1,1,2,45.5,3.5,3.5,0,0,1,5.5,42Zm0,20A3.5,3.5,0,1,1,2,65.5,3.5,3.5,0,0,1,5.5,62Zm0,20A3.5,3.5,0,1,1,2,85.5,3.5,3.5,0,0,1,5.5,82Zm0,20A3.5,3.5,0,1,1,2,105.5,3.5,3.5,0,0,1,5.5,102Zm0,20A3.5,3.5,0,1,1,2,125.5,3.5,3.5,0,0,1,5.5,122Z" />
                          <path fillRule="evenodd" fill="var(--paragraph-color)" d="M25.5,2A3.5,3.5,0,1,1,22,5.5,3.5,3.5,0,0,1,25.5,2Zm0,20A3.5,3.5,0,1,1,22,25.5,3.5,3.5,0,0,1,25.5,22Zm0,20A3.5,3.5,0,1,1,22,45.5,3.5,3.5,0,0,1,25.5,42Zm0,20A3.5,3.5,0,1,1,22,65.5,3.5,3.5,0,0,1,25.5,62Zm0,20A3.5,3.5,0,1,1,22,85.5,3.5,3.5,0,0,1,25.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,25.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,25.5,122Z" />
                          <path fillRule="evenodd" fill="var(--paragraph-color)" d="M45.5,2A3.5,3.5,0,1,1,42,5.5,3.5,3.5,0,0,1,45.5,2Zm0,20A3.5,3.5,0,1,1,42,25.5,3.5,3.5,0,0,1,45.5,22Zm0,20A3.5,3.5,0,1,1,42,45.5,3.5,3.5,0,0,1,45.5,42Zm0,20A3.5,3.5,0,1,1,42,65.5,3.5,3.5,0,0,1,45.5,62Zm0,20A3.5,3.5,0,1,1,42,85.5,3.5,3.5,0,0,1,45.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,45.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,45.5,122Z" />
                          <path fillRule="evenodd" fill="var(--main-color-one)" d="M65.5,2A3.5,3.5,0,1,1,62,5.5,3.5,3.5,0,0,1,65.5,2Zm0,20A3.5,3.5,0,1,1,62,25.5,3.5,3.5,0,0,1,65.5,22Zm0,20A3.5,3.5,0,1,1,62,45.5,3.5,3.5,0,0,1,65.5,42Zm0,20A3.5,3.5,0,1,1,62,65.5,3.5,3.5,0,0,1,65.5,62Zm0,20A3.5,3.5,0,1,1,62,85.5,3.5,3.5,0,0,1,65.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,65.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,65.5,122Z" />
                          <path fillRule="evenodd" fill="var(--main-color-one)" d="M85.5,2A3.5,3.5,0,1,1,82,5.5,3.5,3.5,0,0,1,85.5,2Zm0,20A3.5,3.5,0,1,1,82,25.5,3.5,3.5,0,0,1,85.5,22Zm0,20A3.5,3.5,0,1,1,82,45.5,3.5,3.5,0,0,1,85.5,42Zm0,20A3.5,3.5,0,1,1,82,65.5,3.5,3.5,0,0,1,85.5,62Zm0,20A3.5,3.5,0,1,1,82,85.5,3.5,3.5,0,0,1,85.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,85.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,85.5,122Z" />
                          <path fillRule="evenodd" fill="var(--main-color-one)" d="M105.5,2A3.5,3.5,0,1,1,102,5.5,3.5,3.5,0,0,1,105.5,2Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,105.5,22Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,105.5,42Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,105.5,62Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,105.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,105.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,105.5,122Z" />
                          <path fillRule="evenodd" fill="var(--main-color-one)" d="M125.5,2A3.5,3.5,0,1,1,122,5.5,3.5,3.5,0,0,1,125.5,2Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,125.5,22Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,125.5,42Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,125.5,62Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,125.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,125.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,125.5,122Z" />
                          <path fillRule="evenodd" fill="var(--main-color-one)" d="M145.5,2A3.5,3.5,0,1,1,142,5.5,3.5,3.5,0,0,1,145.5,2Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,145.5,22Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,145.5,42Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,145.5,62Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,145.5,82Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,145.5,102Zm0,20a3.5,3.5,0,1,1-3.5,3.5A3.5,3.5,0,0,1,145.5,122Z" />
                        </svg>
                      </div>
                      <div className="composition-image">
                        <img src={ImageTOView} alt={vendor} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 d-flex align-items-center mb-md-60 mb-sm-40">
                  <div className="w-100 text-center text-lg-right">
                    <h1 className="section-title mb-3 wow fadeInUp">
                      {Data.title2}
                    </h1>
                    <p className="section-descr mb-5 wow fadeInUp" data-wow-delay="0.15s">
                      {Data.title1}
                    </p>
                           
                    <div className="wow fadeInUp d-flex align-items-center justify-content-end" style={{ gap: '20px' }} data-wow-delay="0.3s" data-wow-offset="0">
                      {youtubeLink && (
                        <YoutubeDialog
                          position={0}
                          youtubeLink={youtubeLink}
                        />
                      )}
                      <Link
                        to='/properties'
                        className='primary-btn d-flex align-items-center mb-xs-10' data-btn-animate="y">
                        تصفّح إعلاناتنا العقاريّة
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div className={`banner-area2 banner-area ${MODERN_TEMPLATES.includes(+templateId) ? 'template-3__banner' : ''} ${props.isEdit ? 'admin' : ''}`} style={inlineStyle}>
          {props.isEdit && (
            <UploadImage
              className="upload-button"
              token={token}
              imgUrl='/assets/img/banner/1.jpg'
              imgSource='assets/img/banner/'
              section="home"
              goNext={(e) => changeImage(e.base64Data)}
            />
          )}
          <div className='container'>
            <div
              className='banner-inner-wrap'
              style={{
                placeContent: { 0: "start", 1: "center", 2: "end" }[Data.postion],
              }}>
              <div className='row'>
                <div className='col-12'>
                  <div className='banner-inner'>
                    {props.isEdit ? (
                      <div className='editSection'>
                        <div className='title mb-3 text-align-last-center text-center'>
                          <Button
                            onClick={(e) => setData({ ...Data, postion: "0" })}>
                            يسار
                          </Button>
                          <Button
                            onClick={(e) => setData({ ...Data, postion: "1" })}>
                            المنتصف
                          </Button>
                          <Button
                            onClick={(e) => setData({ ...Data, postion: "2" })}>
                            يمين
                          </Button>
                        </div>
                        <div
                          className={
                            {
                              0: "sub-title text-align-last-left",
                              1: "sub-title text-align-last-center",
                              2: "sub-title text-align-last-right",
                            }[Data.postion]
                          }>
                          <TextField
                            label='العنوان الرئيسي'
                            fullWidth={true}
                            sx={{ direction: 'rtl', textAlign: 'right' }}
                            variant='outlined'
                            InputLabelProps={{ shrink: true }}
                            value={Data.title2}
                            onChange={(v) => {
                              setData({ ...Data, title2: resolvePersianAndArabicNumbers(v.currentTarget.value) });
                            }}
                          />
                        </div>
                        <div
                          className={
                            {
                              0: " text-align-last-left",
                              1: " text-align-last-center",
                              2: " text-align-last-right",
                            }[Data.postion]
                          }>
                          <TextField
                            label='العنوان الثانوي'
                            variant='outlined'
                            fullWidth={true}
                            multiline
                            InputLabelProps={{ shrink: true }}
                            sx={{ direction: 'rtl', textAlign: 'right' }}
                            rows={3}
                            value={Data.title1}
                            onChange={(v) => {
                              setData({ ...Data, title1: resolvePersianAndArabicNumbers(v.currentTarget.value) });
                            }}
                          />
                        </div>
                        {/* <div className='mt-3 '>
                         <TextField
                           id='outlined-basic'
                           label='رابط يوتيوب'
                           variant='outlined'
                           value={Data.yuotube}
                           onChange={(v) => {
                             setData({
                               ...Data,
                               yuotube: v.currentTarget.value,
                             });
                           }}
                           InputProps={{
                             startAdornment: (
                               <InputAdornment position='start'>
                                 <YouTube />
                               </InputAdornment>
                             ),
                           }}
                         />
                       </div> */}
                        <div className='mt-4 w-100'>
                          <LoadingButton
                            classes="primary-btn px-3 m-auto"
                            handleClick={handleSave}
                            label={t("SaveChanges")}
                            loading={props.loading}
                          />
                        </div>
                      </div>
                    ) : (
                      <div data-aos='zoom-in' className="content" style={{
                        alignItems: MODERN_TEMPLATES.includes(+templateId) ? (+Data.postion === 0 ? 'flex-start' : +Data.postion === 1 ? 'center' : 'flex-end') : 'inherit'
                      }}>
                        {DARK_TEMPLATES.includes(+templateId) && youtubeLink && (
                          <YoutubeDialog
                            position={Data.postion}
                            youtubeLink={youtubeLink}
                          />
                        )}
                        <img
                          src={logo} alt="logo"
                          className={
                            {
                              0: "mobile-logo text-align-last-left",
                              1: "mobile-logo text-align-last-center",
                              2: "mobile-logo text-align-last-right",
                            }[Data.postion]
                          } />
                        <h3
                          className={
                            {
                              0: "sub-title text-align-last-left",
                              1: "sub-title text-align-last-center",
                              2: "sub-title text-align-last-right",
                            }[Data.postion]
                          }>
                          <img src={logo} alt="logo" />
                          {Data.title2}
                        </h3>
                        <h1
                          className={
                            {
                              0: "title text-align-last-left text-left",
                              1: "title text-align-last-center text-center",
                              2: "title text-align-last-right text-right",
                            }[Data.postion]
                          }>
                          {Data.title1}
                        </h1>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
     
    </>
  );
}

export default BannerV2;
