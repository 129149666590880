import React, { useState, useEffect } from "react";
import AddNew from "./components/add-new";
import { canAddProperty, getUserProile } from "../../../api/userApi";
import { useParams } from "react-router-dom";
import AppLayout from "../../../components/layouts/app-layout";
import Forbidden from "../403";
import { useAuth } from "../../../helpers/context";
import { toast } from "react-toastify";
import { getLocation } from "../../../api/generalAPI";
import { useTranslation } from "react-i18next";

const AddProperty = () => {
  const { token, setToken, setAccountType, setUserId, setUserType } = useAuth();
  let { propertyType, listingType, userId } = useParams();
  const { t } = useTranslation();
  const isAdmin = window.location.href.includes("admin");
  const [UserVerified, setUserVerified] = useState({
    show: true,
    text: "",
    to: "",
  });
  const [msg, setMsg] = useState("");
  const [user, setUser] = useState();
  const [userOutsideSABorders, setUserOutsideSABorders] = useState(0);
  
  const getUserData = (id) => {
    getLocation().then((_DefLocation) => {
      let latLng = new window.google.maps.LatLng(_DefLocation.latitude, _DefLocation.longitude);
      let gecod = new window.google.maps.Geocoder();
      gecod.geocode(
        {
          location: latLng,
          region: "SA",
        },
        (results, status) => {
          if (status === "OK") {
            results[0].address_components.map((ad) => {
              if (ad.types.includes("country")) {
                if (ad.short_name === "SA") {
                  setUserOutsideSABorders(0);
                } else {
                  setUserOutsideSABorders(1);
                }
              }
              return ad.short_name;
            });
          } else {
            setUserOutsideSABorders(2);
          }
        }
      );
    
    }).catch(() => {
      setUserOutsideSABorders(2);
    });

    getUserProile({
      userId: id,
      language: "0",
    }).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        setToken(id);
        setUser(_userInfo.response.userProfile);
        setAccountType(_userInfo.response.userProfile?.accountType);
        setUserId(_userInfo.response.userProfile?.userId);
        setUserType(_userInfo.response.userProfile?.userType);
        
        if (_userInfo.response.userProfile.userType !== "1") {
          if (_userInfo.response.userProfile.isUserVerified === "3") {
            canAddProperty({ userId: id, language: '0' }).then((result) => {
              if (result.resCode === 0) {
                if (result.response.result === '1') {
                  setMsg("Done");
                } else {
                  if (_userInfo.response.userProfile.userType === '5') {
                    setMsg(<>
                      {result.resStr}
                      <br />  تواصل مع مدير الحساب للمزيد من التفاصيل
                    </>);
                  } else {
                    setMsg(result.resStr);
                  }
                 
                  setUserVerified({
                    show: false,
                    text: _userInfo.response.userProfile.userType === '5' ? t("GoToHomePage") : t("UpgradeThePlan"),
                    to: _userInfo.response.userProfile.userType === '5' ? '/' : "/plans-and-prices",
                  });
                }
              } else {
                toast.error(result.resStr);
              }
            });
          } else {
            setMsg(t("YourAccountIsNotVerified"));
            setUserVerified({
              show: false,
              text: t("AccountVerification"),
              to: "/verify",
            });
          }
        } else {
          setMsg(
            "أنت مستخدم عادي! يجب عليك تغير نوع المستخدم (لمالك عقار / مُسوّق مُفوّض / حساب أعمال) لكي تتمكن من إضافة إعلانك العقاري جديد في أماكن"
          );
          setUserVerified({
            show: false,
            text: t("MyProfile2"),
            to: "/my-profile",
          });
        }
      } else {
        setMsg(t("UserNotFound"));
        setUserVerified({
          show: false,
          text: t("HomePage"),
          to: "/",
        });
        setToken(undefined);
      }
    });
  };

  useEffect(
    () => {
      if (userId) {
        getUserData(userId);
        setTimeout(() => {
          let cookiesWrapper = document.querySelector(".CookieConsent");
          if (cookiesWrapper) cookiesWrapper.style.display = "none";
        }, 200);
      } else if (token) {
        getUserData(token);
      }
    }, // eslint-disable-next-line
    [token, userId]
  );

  useEffect(
    () => {
      if (userId) {
        let cookiesWrapper = document.querySelector(".CookieConsent");
        if (cookiesWrapper) cookiesWrapper.style.display = "none";
      }
    }, // eslint-disable-next-line
    [userId]
  );

  return (
    <AppLayout needAuth pageTitle={t("AddRealEstateAD")} withoutNav={isAdmin || userId}>
      {userOutsideSABorders === 0 ? msg === "Done" ? (
        <div
          className='add-new-property-area px-4 pb-4'
          // style={{ paddingTop: "70px" }}
          style={{ marginTop: userId || isAdmin ? 0 : "75px" }}
          dir='rtl'>
          <AddNew
            user={user}
            userId={token}
            isAdmin={isAdmin}
            withoutNavbar={userId ? true : false}
            propertyType={propertyType || ""}
            listingType={listingType || ""}
          />
        </div>
      ) : (
        UserVerified.show === false && (
          <Forbidden
            btnLink={UserVerified.to}
            btnText={UserVerified.text}
            desc={msg}
          />
        )
      ) : (
        <Forbidden
          desc={userOutsideSABorders === 1 ? 'عذراً, لا يمكنك إضافة إعلان عقاري من خارج حدود المملكة' : 'الرجاء السماح بالوصول إلى موقعك الحالي, ومن ثم معاودة محاولة إضافة الإعلان العقاري '}
        />
      )}
     
    </AppLayout>
  );
}

export default AddProperty;
