import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { getMyProperties, getPropertyTypesForCategory, getUserStatus, publishMyListing, saveFeatured } from "../../../../api/propertyApi";
import AddPropertyBtn from "../../properties/components/add-property-btn";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Avatar, Badge, Box, Dialog, DialogContent, DialogTitle, FormControl, Grid, InputLabel, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, MenuItem, OutlinedInput, Select, Tooltip } from "@mui/material";
import NoData from "../../../../components/global-components/no-data";
import LoadingData from "../../../../components/global-components/loading-data";
import SubHeader from "../../../../components/global-components/sub-header";
import { useAuth } from "../../../../helpers/context";
import { getDemoSnakbar, openDeleteModal } from "../../../../helpers";
import { Close } from "@mui/icons-material";
import { assignPropertiesToAgent, getAgentsForCompany, getCustomers, getUserPlan, getUserProile, unassignPropertiesToAgent } from "../../../../api/userApi";
import { DEMO_VENDORS } from "../../../../constants";
import PropertyInterestsModal from "./interests-modal";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PropertyCardForAdmin from "./property-card-for-admin";
import { useTranslation } from "react-i18next";
import SetPropertyLocationDialog from "./set-property-location-dialog";
import ActionsPopper from "../../../../components/global-components/actions-popper";

const MyListings = (props) => {
  const [Properties, setProperties] = useState([]);
  const [SelectedProperties, setSelectedProperties] = useState([]);
  const { token, vendor } = useAuth();
  const [loadingData, setLoadingData] = useState(true);
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const { search } = useLocation();
  const [agents, setAgents] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [Settings, setSettings] = useState({
    userId: props.userId,
    page: searchParams.get('page') || "1",
    status: searchParams.get('status') || "1",
    pageSize: searchParams.get('pageSize') || "50",
    isAssigned: searchParams.get('isAssigned') || (props.accountType === "2" && props.userType === "4" ? "0" : "na"),
    language: "0",
    propertyType: searchParams.get('propertyType') || '-1',
    assignedTo: searchParams.get('assignedTo') || '-1',
  });
  
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [TotalCount, setTotalCount] = useState(0);
  const [propertyStats, setPropertyStats] = useState({
    pending: 0,
    published: 0,
    sold: 0,
    unpublished: 0
  });
  const [isUserVerified, setIsUserVerified] = useState(undefined);
  const [openInterestsModal, setOpenInterestsModal] = useState({ visible: false, data: [], property: '', propertyName: '', loading: false, totalCount: 0, intialized: false });
  const [interestsPaginationOptions, setInterestsPaginationOptions] = useState({ page: 1, pageSize: 10 });
  const [currentPlan, setCurrentPlan] = useState();
  const isAdmin = window.location.href.includes('admin');
  const [openPopper, setOpenPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState();
  const [loadingFavButton, setLoadingFavButton] = useState(false);
  const [loadingPublishButton, setLoadingPublishButton] = useState(false);
  const [loadingSoldoutButton, setLoadingSoldoutButton] = useState(false);
  const [openMapDialog, setOpenMapDialog] = useState({ visible: false, property: undefined });
  const [propertyTypes, setPropertyTypes] = useState([]);
  const { t } = useTranslation();
  const history = useNavigate();

  function onChange() {
    setSettings({ ...Settings, pageSize: "100" });
    setSearchParams({
      page: Settings.page,
      status: Settings.status,
      pageSize: "100",
      isAssigned: Settings.isAssigned,
      propertyType: Settings.propertyType,
      assignedTo: Settings.assignedTo
    },
      { replace: true });
  }

  function onSelect(id) {
    let item = SelectedProperties.filter((e) => e === id);
    if (item.length < 1) {
      setSelectedProperties([...SelectedProperties, id]);
    }
  }

  function onUnSelect(id) {
    let index = SelectedProperties.indexOf(id);
    if (index > -1) {
      setSelectedProperties(SelectedProperties.filter((item) => item !== id));
    }
  }

  function handlePublishUnPublishClick(Status) {
    let propertyIds = "";

    SelectedProperties.forEach((element) => {
      propertyIds = propertyIds + element + ",";
    });
    let isPublish = "4";
    if (Status === 1) isPublish = Settings.status === "1" ? "0" : "1";

    if (propertyIds.length > 1) propertyIds = propertyIds.slice(0, -1);
    if (propertyIds.length < 2) propertyIds = "-1";

    publishMyListing({
      propertyIds: propertyIds,
      userId: token,
      isPublish: isPublish,
    })
      .then((property) => {
        if (property.resCode === 0) toast.success("تم بنجاح");
        else toast.error(property.resStr);
        onChange();
      })
      .catch((error) => {
        throw error;
      });
  }

  useEffect(() => {
    getPropertyTypesForCategory({
      category: '1,2',
      language: "0",
    }).then((res) => setPropertyTypes(res.response));
  }, []);

  useEffect(() => {
    if (token)
      getUserProile({
        userId: token,
        language: "0",
      }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          setIsUserVerified(_userInfo.response.userProfile.isUserVerified);
        } else {
          toast.error(_userInfo.resStr);
        }
      });
  }, [token]);

  useEffect(() => {
    setLoadingData(true);
    getMyProperties({
      ...Settings,
      propertyType: Settings.propertyType === "-1" ? "" : Settings.propertyType,
      assignedTo: Settings.assignedTo === "-1" ? "" : Settings.assignedTo
    })
      .then((property) => {
        if (property.resCode === 0) {
          setProperties(property.response.propertyArray);
          setTotalCount(property.response.totalCount);
          setLoadingData(false);
          setSelectedProperties([]);
          getUserStatus({ language: '1', userId: token }).then(
            res => {
              if (res.resCode === 0) {
                setPropertyStats(res.response);
              }
            }
          );
          
        } else {
          toast.error(property.resStr);
          setLoadingData(false);
        }
      });
    
    if (token)
      getUserPlan({ userId: token, language: '0' }).then((result) => {
        if (result.resCode === 0) {
          setCurrentPlan(result?.response);
        }
      });
    
    if (((props.accountType === "2" && props.userType !== "5") || props.userType === "4") && agents.length === 0) {
      getAgentsForCompany({ superUserId: token }).then((result) => {
        if (result.resCode === 0) setAgents(result.response.userArray);
      });
    }
  },// eslint-disable-next-line
    [Settings]);

  function handleStatusClick(event, newValue) {
    let nValue = newValue.toString();
    setSettings({ ...Settings, status: nValue, isAssigned: '0' });
    setSearchParams({
      page: Settings.page,
      status: nValue,
      pageSize: Settings.pageSize,
      isAssigned: '0',
      propertyType: Settings.propertyType,
      assignedTo: Settings.assignedTo
    },
      { replace: true });
  }



  const tabStyle = {
    fontSize: "16px",
    color: "#111",
    "&.Mui-selected": {
      background: "#fff",
      color: "var(--main-color-one)",
      borderRadius: "4px",
    }
  };

  const badgeStyle = {
    display: 'flex',
    '& .MuiBadge-badge': {
      top: '0',
      right: 'unset',
      // display: loadingData ? 'none': '',
      position: 'relative',
      left: '7%',
      transform: 'translateX(-50%)'
    }
  };

  const callUnAssignApi = () => {
    let propertyIds = "";

    SelectedProperties.forEach((element) => {
      propertyIds = propertyIds + element + ",";
    });
    if (propertyIds.endsWith(',')) propertyIds = propertyIds.slice(0, propertyIds.length - 1);
    
    unassignPropertiesToAgent({
      propertyIds: propertyIds,
      superUserId: token
    })
      .then((result) => {
        if (result.resCode === 0) {
          toast.success("تم إلغاء التخصيص بنجاح");
          setSettings({ ...Settings, isAssigned: '0' });
          setSearchParams({
            page: Settings.page,
            status: Settings.status,
            pageSize: Settings.pageSize,
            isAssigned: '0',
            propertyType: Settings.propertyType,
            assignedTo: Settings.assignedTo
          },
            { replace: true });
        }
        else toast.error(result.resStr);
      });
  };

  const callAssignApi = (id) => {
    let propertyIds = "";

    SelectedProperties.forEach((element) => {
      propertyIds = propertyIds + element + ",";
    });
    if (propertyIds.endsWith(',')) propertyIds = propertyIds.slice(0, propertyIds.length - 1);
      
    assignPropertiesToAgent({
      propertyIds: propertyIds,
      superUserId: token,
      agentId: id
    })
      .then((result) => {
        if (result.resCode === 0) {
          setOpenAssignModal(false);
          toast.success("تم التخصيص بنجاح");
          setSettings({ ...Settings, isAssigned: '1' });
          setSearchParams({
            page: Settings.page,
            status: Settings.status,
            pageSize: Settings.pageSize,
            isAssigned: '1',
            propertyType: Settings.propertyType,
            assignedTo: Settings.assignedTo
          },
            { replace: true });
        } else {
          toast.error(result.resStr);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  const handleAssignUnAssignClick = () => {
    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);
    } else {
      if (Settings.isAssigned === "0") {
        setOpenAssignModal(true);
      } else if (Settings.isAssigned === "1") {
        openDeleteModal('تأكيد إلغاء التخصيص', `هل أنت متأكد من إلغاء تخصيص ${SelectedProperties.length} إعلانات عقاريّة محددة؟`, () => callUnAssignApi(), 'إلغاء تخصيص');
      }
    }
  };

  const getPropertyCustomrs = (item, page, pageSize) => {
    setOpenInterestsModal({
      property: item,
      visible: true,
      data: [],
      loading: true,
      propertyName: `${item?.propertyTypeName} ${item?.listedFor === "1" ? t("ForRent") : t("ForSale")}`,
      intialized: false
    });
    getCustomers({
      propertyId: item.propertyId,
      page: page,
      pageSize: pageSize,
      userId: token
    }).then(res => {
      if (res.resCode === 0) {
        setOpenInterestsModal({
          visible: true,
          property: item,
          data: res.response.array,
          totalCount: res.response.totalCount,
          loading: false,
          propertyName: `${item?.propertyTypeName} ${item?.listedFor === "1" ? t("ForRent") : t("ForSale")}`,
          intialized: false
        });
      } else {
        toast.error(res.resStr);
      }
    }).catch(() => setOpenInterestsModal({ ...openInterestsModal, intialized: false, loading: false, visible: true, property: item }));
  }
  
  useEffect(() => {
    if (search.includes("propertyId=") && vendor !== 'amakkn' && openInterestsModal.data?.length === 0 && !openInterestsModal.visible && !openInterestsModal?.intialized) {
      let query = search?.split("&");
      let id = query?.[0]?.substring(12);
      getPropertyCustomrs({
        propertyId: id
      }, interestsPaginationOptions.page, interestsPaginationOptions.pageSize);
    
    }
    if (!search.includes("propertyId=") && vendor !== 'amakkn' && openInterestsModal.property && openInterestsModal.data?.length > 0) {
      getPropertyCustomrs(openInterestsModal.property, interestsPaginationOptions.page, interestsPaginationOptions.pageSize);
    }
  },
    // eslint-disable-next-line
    [vendor, interestsPaginationOptions, openInterestsModal.property, search]);

  function handleFav1(isFeatured) {
    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);
    } else {
      openDeleteModal(isFeatured === "0" ? 'تأكيد التمييز' : 'تأكيد إلغاء التمييز',
        isFeatured === "0" ? 'هل أنت متأكد من تمييز هذا الإعلان العقاري' : 'هل أنت متأكد من إلغاء تمييز هذا الإعلان العقاري'
        , () => callFavApi(isFeatured), 'تأكيد');
    }
  }

  function callFavApi(isFeatured) {
    setLoadingFavButton(true);
    saveFeatured({
      propertyIds: selectedProperty.propertyId,
      userId: token,
      properties: [selectedProperty.propertyId],
      isFeatured: isFeatured === '0' ? '1' : '0'
    })
      .then((property) => {
        if (property.resCode === 0) {
          toast.success(isFeatured === "0" ? "تم تمييز الإعلان بنجاح" : "تم إلغاء تمييز الإعلان بنجاح");
          onChange();
        } else {
          toast.error(property.resStr);
        }
      })
      .finally(() => {
        setLoadingFavButton(false);
      });
  }
  
  function handleDelete() {
    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);
    } else {
      openDeleteModal(t("ConfirmDeletion"), 'هل أنت متأكد من حذف هذا الإعلان العقاري, لن تستطيع استرجاع المعلومات مرّة آخرى', () => callDeleteApi());
    }
  }

  function callDeleteApi() {
    publishMyListing({
      propertyIds: selectedProperty.propertyId,
      userId: token,
      isPublish: "4",
    })
      .then((property) => {
        if (property.resCode === 0) {
          toast.success("تم حذف الإعلان بنجاح");
          onChange();
          // platformCompliance({
          //   userId: token,
          //   language: '0',
          //   propertyId: item.propertyId,
          //   operationType: 'CancelAd',
          //   operationReason: 'Other'
          // });
        } else {
          toast.error(property.resStr);
        }
      });
  }

  function handlePublishAndUnPublish(selectedProperty) {
    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);
    } else {
      let isPub = "0";
      if (selectedProperty.status === "0") isPub = "1";
      setLoadingPublishButton(true);
      publishMyListing({
        propertyIds: selectedProperty.propertyId,
        userId: token,
        isPublish: isPub,
      })
        .then((property) => {
          if (property.resCode === 0) {
            toast.success(
              property.response.status === "1"
                ? "تم نشر الإعلان بنجاح"
                : "تم إلغاء نشر الإعلان بنجاح"
            );
            onChange();
          } else {
            toast.error(property.resStr);
          }
        })
        .finally(() => {
          setLoadingPublishButton(false);
        });
    }
  }

  function handleSoldOut(listedFor) {
    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);
    } else {
      openDeleteModal(listedFor === "2" ? 'تأكيد البيع' : "تأكيد التأجير", listedFor === "2" ? 'هل أنت متأكد من ان هذا العقار تم بيعه؟' : 'هل أنت متأكد من ان هذا العقار تم تأجيره؟', () => callSouldOut(listedFor), 'تأكيد');
    }
  }

  function callSouldOut(listedFor) {
    setLoadingSoldoutButton(true);
    publishMyListing({
      propertyIds: selectedProperty.propertyId,
      userId: token,
      isPublish: '2',
    })
      .then((property) => {
        if (property.resCode === 0) {
          toast.success(listedFor === "2" ? "تم بيع العقار بنجاح" : "تم تأجير العقار بنجاح");
          onChange();
          // platformCompliance({
          //   userId: token,
          //   language: '0',
          //   propertyId: item.propertyId,
          //   operationType: 'CancelAd',
          //   operationReason: 'Other'
          // });

        } else {
          toast.error(property.resStr);
        }
      })
      .finally(() => {
        setLoadingSoldoutButton(false);
      });
  }

  const renderPropertiesList = () => {
    return (
      <div className="properties-list col-12 px-0 pt-0 pb-5">
        <div className='row mx-0'>
          {loadingData ? (
            <LoadingData />
          ) : Properties && Properties.length > 0 ? (
            <Grid container spacing={2}>
              {Properties.map((item, i) => (
                <Grid item xs={12} md={6} lg={4} xl={3} key={i} className="custom-grid-item">
                  <PropertyCardForAdmin
                    item={item}
                    setOpenPopper={setOpenPopper}
                    handlePublishAndUnPublish={handlePublishAndUnPublish}
                    setAnchorEl={setAnchorEl}
                    isUserVerified={isUserVerified}
                    openPopper={openPopper}
                    loadingPublishButton={loadingPublishButton}
                    anchorEl={anchorEl}
                    isAssigned={Settings.isAssigned === "1"}
                    setSelectedProperty={setSelectedProperty}
                    onChange={onChange}
                    onSelect={onSelect}
                    currentPlan={currentPlan}
                    onUnSelect={onUnSelect}
                    userId={props.userId}
                  />
                </Grid>
              ))}
              <ActionsPopper
                openPopper={openPopper}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setOpenPopper={setOpenPopper}
                items={
                  [
                    {
                      title: t("ViewDetails"),
                      action: () => history(`${isAdmin ? "/admin/property-details" : "/property-details"}/${selectedProperty.idToShare}`)
                    },
                    {
                      title: t("Delete"),
                      action: () => handleDelete()
                    },
                    selectedProperty?.status === '1' && {
                      title: selectedProperty?.isFeatured === '1' ? 'إلغاء التمييز' : 'تمييز',
                      action: () => handleFav1(selectedProperty.isFeatured),
                      disabled: loadingFavButton
                    },
                    selectedProperty?.status !== '2' && {
                      title: selectedProperty?.listedFor === "2" ? "تم البيع" : "تم التأجير",
                      action: () => handleSoldOut(selectedProperty?.listedFor),
                      disabled: loadingSoldoutButton
                    },
                    vendor !== 'amakkn' && {
                      title: "عرض العملاء المهتمين",
                      action: () => getPropertyCustomrs(selectedProperty, 1, 10)
                    },
                    selectedProperty?.latitude === '24' && selectedProperty?.longitude === '24' && {
                      title: t("SetLocationOnMap"),
                      action: () => setOpenMapDialog({ visible: true, property: selectedProperty })
                    }
                  ]
                }
              />
            </Grid>) : (
            <NoData msg='عذراً, لا توجد إعلانات عقاريّة' />
          )}
        </div>
      </div>
    );
  }

  const renderSelectionList = () => {
    return SelectedProperties.length > 0 ? (
      <div className='top-btns justify-content-center justify-content-lg-end flex-wrap'>
        <span className="col-12 text-center col-sm-auto"> الإعلانات المحددة: {SelectedProperties.length}</span>
       
        <button
          id='delete-btn'
          className='btn btn-danger'
          onClick={(e) => {
            if (DEMO_VENDORS.includes(vendor)) {
              setOpenDemoSnackBar(true);
            } else {
              openDeleteModal(t("ConfirmDeletion"), 'هل أنت متأكد من حذف جميع الإعلانات العقاريّة المحددة, لن تستطيع استرجاع المعلومات مرّة آخرى', () => handlePublishUnPublishClick(0));
            }
          }}>
          {t("Delete")}
        </button>
       
        {parseInt(Settings.status) > 0 ?
          (<button
            id='publish-btn'
            className='btn btn-yellow'
            onClick={(e) => {
              if (DEMO_VENDORS.includes(vendor)) {
                setOpenDemoSnackBar(true);
              } else {
                openDeleteModal(`تأكيد ${parseInt(Settings.status) < 2 ? "إلغاء النشر" : "النشر"}`, `هل أنت متأكد من ${parseInt(Settings.status) < 2 ? t("Unpublish") : t("Publish")}  جميع الإعلانات العقاريّة المحددة؟`, () => handlePublishUnPublishClick(1), parseInt(Settings.status) < 2 ? t("Unpublish") : t("Publish"));
              }
            }}
          >
            {parseInt(Settings.status) < 2 ? "إلغاء النشر" : t("Publish")}
          </button>) : null
        }
       
        {((props.accountType === "2" && props.userType !== "5") || props.userType === "4") &&
          (<button
            id='publish3-btn'
            className='btn btn-yellow'
            onClick={handleAssignUnAssignClick}
          >
            {Settings.isAssigned === '1' ? "إلغاء التخصيص" : "تخصيص"}
          </button>)
        }
      </div>
    ) : (
      !props.isAdmin && (
        <div className='top-btns justify-content-center justify-content-lg-end'>
          <AddPropertyBtn isAdmin={props.isAdmin} />
        </div>
      )
    );
  }
  
  return (
    <div
      className={
        !props.isAdmin
          ? "add-new-property-area mg-bottom-100 text-center"
          : "px-1"
      }
      dir='rtl'>
      {props.isAdmin ? (
        <SubHeader
          extra={
            <Tooltip title={<div style={{ direction: 'rtl', textAlign: 'right', fontSize: '13px', lineHeight: 2 }}>
              <ul>
                <li>
                  يمكنك إضافة إعلانات عقاريّة مهما كان مصدر الترخيص الخاص بها,
                  ولكن سيتمكن زوار منصتّك من مشاهدة الإعلانات العقاريّة التي تحمل ترخيص مصدره الهيئة العامة للعقار فقط
                </li>
                {+currentPlan?.permanentUpgradeToNumberOfAds > 0 ? <li>
                  {`يمكنك تمييز ${+currentPlan?.permanentUpgradeToNumberOfAds > 2 ? `${currentPlan?.permanentUpgradeToNumberOfAds} إعلانات عقاريّة` : "إعلان عقاري واحد فقط"}`}
                  {` (قمت بتمييز ${+currentPlan?.permanentUpgradeToNumberOfAds - +currentPlan?.featuredPropertiesLeft} إعلانات عقاريّة) `}
                </li>
                  : null
                }
              </ul>
            </div>}>
              <HelpOutlineIcon sx={{ cursor: 'pointer', marginRight: '2px' }} />
            </Tooltip>
          }
          OKElement={<AddPropertyBtn isAdmin={props.isAdmin} />}
        />
      ) : (
        <Box
          sx={{
            marginBottom: "40px",
            textAlign: "center",
            fontSize: "28px",
            fontWeight: 600,
            verticalAlign: 'middle',
            color: "#000",
          }}>
          {t("MyProperties")} 
          {/* <Tooltip title={<div style={{ direction: 'rtl', textAlign: 'right', fontSize: '13px' }}>
            {+currentPlan?.permanentUpgradeToNumberOfAds > 0 ? <>
              {`يمكنك تمييز ${+currentPlan?.permanentUpgradeToNumberOfAds > 2 ? `${currentPlan?.permanentUpgradeToNumberOfAds} إعلانات عقاريّة` : "إعلان عقاري واحد فقط"}`}
              {` (قمت بتمييز ${+currentPlan?.permanentUpgradeToNumberOfAds - +currentPlan?.featuredPropertiesLeft} إعلانات عقاريّة) `}
            </>
              : null
            }
          </div>}>
            <Star sx={{
              cursor: 'pointer',
              marginRight: '-12px',
              position: 'relative',
              top: '-4px',
              color: 'gold',
              fontSize: '34px',
              transform: 'rotate(50deg)',
              animation: 'pulse-animation 1.8s linear',
              animationDelay: '2s'
            }} />
          </Tooltip> */}
          <Box
            sx={{
              textAlign: "center",
              fontSize: "16px",
              fontWeight: 400,
              color: "#000",
            }}>
            يمكنك إدارة إعلاناتك العقاريّة من إضافة, تعديل, وحذف
          </Box>
        </Box>
      )}

      <div className='row justify-content-center section-add-property'>
        <Tabs
          value={parseInt(Settings.status)}
          onChange={handleStatusClick}
          centered
          sx={{
            background: "#EAEAEA",
            padding: '5px',
            borderRadius: "6px",
            borderTop: 0,
            '& .MuiTabs-flexContainer': { flexWrap: 'wrap' }
          }}
          TabIndicatorProps={{ style: { display: "none" } }}>
          <Tab value={1} label={
            <Badge sx={badgeStyle} color="primary" badgeContent={propertyStats.published} showZero max={999}>
              <span>منشور</span>
            </Badge>
          } sx={tabStyle} />
          <Tab value={3} label={
            <Badge sx={badgeStyle} color="primary" badgeContent={propertyStats.unpublished} showZero max={999}>
              <span>غير منشور</span>
            </Badge>
          } sx={tabStyle} />
          {/* <Tab value={0} label={
            <Badge sx={badgeStyle} color="primary" badgeContent={propertyStats.pending} showZero max={999}>
              <span>غير مكتمل</span>
            </Badge>
          } sx={tabStyle} /> */}
          <Tab value={2} label={
            <Badge sx={badgeStyle} color="primary" badgeContent={propertyStats.sold} showZero max={999}>
              <span>مباع / مؤجّر</span>
            </Badge>
          } sx={tabStyle} />
        </Tabs>
      </div>
     
      <div className='row properties-list-wrapper m-0 radius-16'>
        <div className='col-12 p-0 heading'>
          <div className='row '>
            <div className='col-12 col-lg-6 text-center text-lg-right mb-0 d-flex flex-wrap align-items-center' style={{ gap: '15px' }}>
              <h4 className="mb-0">
                قائمة الإعلانات العقاريّة ({!loadingData ? TotalCount : <>&nbsp;&nbsp;&nbsp;</>})
              </h4>
              <div className="d-flex" style={{ gap: '8px' }}>
                <FormControl variant="outlined" className="text-right" dir="rtl">
                  <InputLabel shrink htmlFor='unitid1'>{t("PropertyType")}</InputLabel>
                  <Select
                    sx={{ width: "120px" }}
                    size="small"
                    onChange={(event) => {
                      setSettings({
                        ...Settings,
                        propertyType: event.target.value,
                      });
                      setSearchParams({
                        page: Settings.page,
                        status: Settings.status,
                        pageSize: Settings.pageSize,
                        isAssigned: Settings.isAssigned,
                        propertyType: event.target.value,
                        assignedTo: Settings.assignedTo
                      },
                        { replace: true });
                    }}
                    label={t("PropertyType")}
                    inputProps={{
                      name: "unitid1",
                      id: "unitid1",
                    }}
                    value={Settings.propertyType}
                               
                    input={<OutlinedInput
                      notched
                      value={Settings.propertyType}
                      label={t("PropertyType")} />}
                    labelId="unitid1"
                  >
                    <MenuItem value={'-1'} key={'-1'}>{t("All")}</MenuItem>
                    {propertyTypes.map((item) => <MenuItem value={item.key} key={item.key}>{item.name}</MenuItem>)}
                  </Select>
                </FormControl>
                {props.userType !== "5" && (
                  <FormControl variant="outlined" className="text-right" dir="rtl">
                    <InputLabel shrink htmlFor='unitid2'>مضافة من قبل </InputLabel>
                    <Select
                      sx={{ width: "160px" }}
                      size="small"
                      onChange={(event) => {
                        setSettings({
                          ...Settings,
                          assignedTo: event.target.value,
                          isAssigned: event.target.value === '-2' ? '0' : '1'
                        });
                        setSearchParams({
                          page: Settings.page,
                          status: Settings.status,
                          pageSize: Settings.pageSize,
                          isAssigned: Settings.isAssigned,
                          assignedTo: event.target.value,
                          propertyType: Settings.propertyType
                        },
                          { replace: true });
                      }}
                      label="مضافة من قبل"
                      inputProps={{
                        name: "unitid2",
                        id: "unitid2",
                      }}
                      value={Settings.assignedTo}
                               
                      input={<OutlinedInput
                        notched
                        value={Settings.assignedTo}
                        label="مضافة من قبل" />}
                      labelId="unitid2"
                    >
                      <MenuItem value={'-2'} key={'-2'}>{t("MainAccount")}</MenuItem>
                      <ListSubheader sx={{ fontSize: '11px' }}>{t("Agents")}</ListSubheader>
                      <MenuItem sx={{ paddingInlineEnd: '28px' }} value={'-1'} key={'-1'}>{t("All")}</MenuItem>
                      {agents.map((item) => <MenuItem sx={{paddingInlineEnd:'28px'}} value={item.userId} key={item.userId}>{item.name}</MenuItem>)}
                    </Select>
                  </FormControl>
                )}
                 
              </div>
             
             
            </div>
            
            <div className='col-12 col-lg-6 '>
              {renderSelectionList()}
            </div>
          </div>
        </div>
        {renderPropertiesList()}
      </div>
     

      {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}
      
      <PropertyInterestsModal
        open={openInterestsModal.visible}
        data={openInterestsModal.data || []}
        property={openInterestsModal.property}
        totalCount={openInterestsModal.totalCount || 0}
        paginationOptions={interestsPaginationOptions}
        setPaginationOptions={setInterestsPaginationOptions}
        onClose={() => setOpenInterestsModal({ visible: false, data: [], property: '', propertyName: '', loading: false, totalCount: 0, intialized: true })}
        loadingData={openInterestsModal.loading}
        propertyName={search?.includes("propertyId=") ? '' : openInterestsModal.propertyName}
      />
      <SetPropertyLocationDialog
        openMapDialog={openMapDialog}
        setOpenMapDialog={setOpenMapDialog}
      />

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={openAssignModal}
        className="custom-dialog model"
        style={{ direction: "rtl", textAlignLast: "right", zIndex: 90 }}
        sx={{ '& .MuiPaper-root': { margin: 0, width: 'calc(100% - 20px)' } }}
        onClose={() => setOpenAssignModal(false)}>
        <DialogTitle>
          <div className="dialog-head">
            <div>
              <h2>تخصيص الإعلانات العقاريّة</h2>
              <p>الرجاء اختيار الحساب الفرعي المراد تخصيص {SelectedProperties.length} إعلانات عقاريّة له</p>
            </div>
            <div className="dialog-actions close-only">
              <span onClick={() => setOpenAssignModal(false)} style={{ cursor: 'pointer' }}>
                <Close />
              </span>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className='row justify-content-start'>
            <div style={{ display: 'flex', height: '400px', width: '100%' }}>
              <div style={{ flexGrow: 1, width: '100%', padding: '0', textAlign: 'right' }}>
                {agents.length > 0 ? (
                  <List sx={{ width: '100%' }}>
                    {agents.map(item => (
                      <ListItem key={item.userId} sx={{ cursor: 'pointer', bgcolor: 'inherit', '&:hover': { bgcolor: '#fff' } }} onClick={() => openDeleteModal('تأكيد التخصيص', `هل أنت متأكد من تخصيص ${SelectedProperties.length} إعلانات عقاريّة للحساب الفرعي (${item.name})؟`, () => callAssignApi(item.userId), 'تخصيص')}>
                        <ListItemAvatar>
                          {item.avatar ? <Avatar src={item.avatar} sx={{ bgcolor: "#fff" }} /> : <Avatar />}
                        </ListItemAvatar>
                        <ListItemText sx={{ color: 'var(--paragraph-color)', '& .MuiListItemText-secondary': { direction: 'ltr', color: 'var(--paragraph-color)' } }} primary={item.name} secondary={`${item.countryCode}-${item.phone}`} />
                      </ListItem>
                    ))}
                  </List>
                ) : <NoData msg="لا يوجد حسابات فرعية" />
                }
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default MyListings;
