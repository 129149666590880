import React, { useEffect, useState } from 'react'
import BoardColumn from './board-column'
// import { useMediaQuery } from '@mui/material';
import { changeOrder } from '../../../../api/userApi';
import { toast } from 'react-toastify';
import { useAuth } from '../../../../helpers/context';
import { DragDropContext, useKeyboardSensor, useMouseSensor } from "react-beautiful-dnd";
import useTouchSensor from '../../../../helpers/use-touch-sensor';
import { ClickAwayListener, Grow, List, ListItem, ListItemButton, ListItemText, Paper, Popper } from '@mui/material';
import { DEMO_VENDORS } from '../../../../constants';
import { openDeleteModal } from '../../../../helpers';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddCommentDialog from './add-comment-dialog';

const move = (list, source, destination) => {
    const result = Array.from(list);
    const [removed] = result[source.droppableId.substring(source.droppableId.length-1)].children.splice(source.index, 1);
    result[destination.droppableId.substring(destination.droppableId.length-1)].children.splice(destination.index, 0, removed);
  return result;
};

const reorder = (list, source, destination) => {
    const result = Array.from(list);
    const [removed] = result[source.droppableId.substring(source.droppableId.length-1)].children.splice(source.index, 1);
    result[source.droppableId.substring(source.droppableId.length-1)].children.splice(destination.index, 0, removed);
  return result;
  };
  
export default function DealsBoard({ companyName, onOK, deal, data, handleDelete, UserType, setDeal, setOpenDealDialog, setOpenDemoSnackBar, userName, openSettingsPopper, setAnchorSettingsEl, anchorSettingsEl, setOpenSettingsPopper, setOpenChangeStatusDialog }) {
    const { t } = useTranslation();
    const [columns, setColumns] = useState([{
        id: "column-1",
        title: "صفقة جديدة",
        color: 'gold',
        children: data?.filter(i => +i.status === 0)?.sort((a, b) => +a.order - +b.order)?.map(item => {
            return {
                ...item,
            }
        })
    },
    {
        id: "column-2",
        color: 'blue',
        title: "مفاوضات",
        children: data?.filter(i => +i.status === 1)?.sort((a, b) => +a.order - +b.order)?.map(item => {
            return {
                ...item,
            }
        })
    },
    {
        id: "column-3",
        title: "مفاوضات متقدّمة",
        color: 'green',
        children: data?.filter(i => +i.status === 2)?.sort((a, b) => +a.order - +b.order)?.map(item => {
            return {
                ...item,
            }
        })
    },
    {
        id: "column-4",
        title: "إتمام الصفقة",
        color: 'black',
        children: data?.filter(i => +i.status === 3)?.sort((a, b) => +a.order - +b.order)?.map(item => {
            return {
                ...item,
            }
        })
    },
    {
        id: "column-5",
        title: "خسارة الصفقة",
        color: '#d50000',
        children: data?.filter(i => +i.status === 4)?.sort((a, b) => +a.order - +b.order)?.map(item => {
            return {
                ...item,
            }
        })
    },
    ]);
    const history = useNavigate();
    const { token, vendor } = useAuth();
    const [addCommentDialog, setAddCommentDialog] = useState({ visible: false, id: '' });

    const onDragEnd = (result) => {
        let temp = columns;
        if (!result.destination) return;
        if (result.destination.droppableId === result.source.droppableId) {
            if (result.destination.index === result.source.index) return;
            const newColumns = reorder(
                columns,
                result.source,
                result.destination
            );
            setColumns(newColumns);
            changeOrder({
                userId: token,
                bargainId: result.draggableId,
                status: +result.destination.droppableId.substring(result.destination.droppableId.length - 1),
                order: +result.destination.index
            }).then(res => {
                if (res.resCode !== 0) {
                    toast.error(res.resStr);
                    setColumns(temp);
                } else {
                    onOK();
                }
            });
        } else {
            const newColumns = move(
                columns,
                result.source,
                result.destination
            );
            setColumns(newColumns);
            changeOrder({
                userId: token,
                bargainId: result.draggableId,
                status: +result.destination.droppableId.substring(result.destination.droppableId.length - 1),
                order: +result.destination.index
            }).then(res => {
                if (res.resCode !== 0) {
                    toast.error(res.resStr);
                    setColumns(temp);
                } else {
                    onOK();
                }
            });
        }
       
    }

    useEffect(() => {
        setColumns(
            [{
                id: "column-1",
                title: "صفقة جديدة",
                color: 'gold',
                children: data?.filter(i => +i.status === 0)?.sort((a, b) => +a.order - +b.order)?.map(item => {
                    return {
                        ...item,
                    }
                })
            },
            {
                id: "column-2",
                color: 'blue',
                title: "مفاوضات",
                children: data?.filter(i => +i.status === 1)?.sort((a, b) => +a.order - +b.order)?.map(item => {
                    return {
                        ...item,
                    }
                })
            },
            {
                id: "column-3",
                title: "مفاوضات متقدّمة",
                color: 'green',
                children: data?.filter(i => +i.status === 2)?.sort((a, b) => +a.order - +b.order)?.map(item => {
                    return {
                        ...item,
                    }
                })
            },
            {
                id: "column-4",
                title: "إتمام الصفقة",
                color: 'black',
                children: data?.filter(i => +i.status === 3)?.sort((a, b) => +a.order - +b.order)?.map(item => {
                    return {
                        ...item,
                    }
                })
            },
            {
                id: "column-5",
                title: "خسارة الصفقة",
                color: '#d50000',
                children: data?.filter(i => +i.status === 4)?.sort((a, b) => +a.order - +b.order)?.map(item => {
                    return {
                        ...item,
                    }
                })
            },
            ]
        );
    }, [data]);

    const renderColumn = (column, currentPath) => {
        return (
            <BoardColumn
                key={column.id}
                data={column}
                setDeal={setDeal}
                path={currentPath}
                companyName={companyName}
                openSettingsPopper={openSettingsPopper}
                setOpenDealDialog={setOpenDealDialog}
                setAnchorSettingsEl={setAnchorSettingsEl}
                anchorSettingsEl={anchorSettingsEl}
                setOpenSettingsPopper={setOpenSettingsPopper}
            />
        );
    };

    return (<>
        <DragDropContext onDragEnd={onDragEnd}
            enableDefaultSensors={false}
            sensors={[useMouseSensor, useKeyboardSensor, useTouchSensor]}>
            <div className="base d-flex" style={{ flex: 1 }}>
                <div className="deal-cards columns">
                    {columns?.map((column, index) => {
                        return (
                            <React.Fragment key={column.id}>
                                {renderColumn(column, index)}
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </DragDropContext>
        <Popper
            sx={{
                zIndex: 100,
                offset: '55px 0 0 5px',
            }}
            open={openSettingsPopper}
            role={undefined}
            transition
            anchorEl={anchorSettingsEl}
            disablePortal={false}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={(event) => {
                            if (event.target.nodeName === 'BODY' && event.type === 'click') {
                                return;
                            } else {
                                setAnchorSettingsEl(null); setOpenSettingsPopper(false);
                            }
         
                        }}>
                            <List>
  
                                <ListItem disablePadding>
                                    <ListItemButton
                                        sx={{ padding: '2px 15px' }}
                                        onClick={() => {
                                            setOpenDealDialog(true);
                                        }}>
                                        <ListItemText primary={t("ViewDetails")} sx={{ textAlign: 'right' }} />
                                    </ListItemButton>
                                </ListItem>
                                {userName !== deal.owner && UserType === "5" ? <></> :
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => {
                                            history(`/admin/edit-deal/${deal.id}`);
                                        }}
                                            sx={{ padding: '2px 15px' }}
                                        >
                                            <ListItemText primary={t("Edit")} sx={{ textAlign: 'right' }} />
                                        </ListItemButton>
                                    </ListItem>
                                }
                                <ListItem disablePadding>
                                    <ListItemButton
                                        sx={{ padding: '2px 15px' }}
                                        onClick={(e) => {
                                            setAddCommentDialog({ visible: true, id: deal.id })
                                        }}>
                                        <ListItemText primary={t("AddComment")} sx={{ textAlign: 'right' }} />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton
                                        sx={{ padding: '2px 15px' }}
                                        onClick={(e) => {
                                            if (DEMO_VENDORS.includes(vendor)) {
                                                setOpenDemoSnackBar(true);
                                            } else {
                                                openDeleteModal(t("ConfirmDeletion"), t("AreYouSureToDeleteThisDeal"),
                                                    () => handleDelete(deal.id))
                                            }
                                        }}>
                                        <ListItemText primary={t("Delete")} sx={{ textAlign: 'right' }} />
                                    </ListItemButton>
                                </ListItem>
  
                                <ListItem disablePadding>
                                    <ListItemButton
                                        sx={{ padding: '2px 15px' }}
                                        onClick={(e) => {
                                            if (DEMO_VENDORS.includes(vendor)) {
                                                setOpenDemoSnackBar(true);
                                            } else {
                                                setOpenChangeStatusDialog({
                                                    open: true,
                                                    id: deal.id,
                                                    status: +deal.status
                                                })
                                           
                                            }
                                        }}>
                                        <ListItemText primary="تغيير المرحلة" sx={{ textAlign: 'right' }} />
                                    </ListItemButton>
                                </ListItem>
                            </List>
      
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
        <AddCommentDialog
            onOK={onOK}
            dialog={addCommentDialog}
            setDialog={setAddCommentDialog}
        />
    </>
    );
}
