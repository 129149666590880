import React from "react";
import AddAgent from "../../amakkn/agents/add-agent";

function WLAddAgent() {
  return <>
    <AddAgent />
  </>
}

export default WLAddAgent;
