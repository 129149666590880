import Close from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';
import { getFontspace, renderFeatureIcon, renderFeatureIconCondition, renderRoomIcon, renderRoomIconCondition } from '../../../helpers';
import { useTranslation } from 'react-i18next';

const publicUrl = process.env.REACT_APP_URL + "/";

const MobileInfoWindow = ({ showSearchBox, setMobileInfowindow, mobileInfowindow }) => {
    const { t } = useTranslation();
    
    return (
        <div
            className={`${!showSearchBox ? "full-screen" : ""} mobile-info-window`}>
            <IconButton
                sx={{
                    bgcolor: "#fff",
                    cursor: "pointer",
                    position: "absolute",
                    top: "6px",
                    left: "6px",
                    width: 23,
                    height: 23,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                onClick={() => setMobileInfowindow({ activeMarker: null, showingInfoWindow: false, })}>
                <Close sx={{ color: "#828282", width: 16, height: 16 }} />
            </IconButton>
            <Link
                to={{ pathname: `/property-details/${mobileInfowindow?.selectedPlace?.idToShare}` }}
                state={{
                    aboutProps: { propertyId: mobileInfowindow?.selectedPlace?.propertyId }
                }}>
                {mobileInfowindow?.selectedPlace &&
                    mobileInfowindow?.selectedPlace.photos !== undefined ? (
                    <div>
                        {mobileInfowindow?.selectedPlace?.isFeatured === "1" && <div className="featured-ribbon pr-3">إعلان مميّز</div>}

                        <div className={`pxp-marker-details-fig pxp-cover ${mobileInfowindow?.selectedPlace?.photos?.length > 0 ? "" : "default"}`}>
                            <img
                                style={{ borderRduis: "" }}
                                src={
                                    mobileInfowindow?.selectedPlace?.photos?.length > 0
                                        ? mobileInfowindow?.selectedPlace?.photos?.split(",")[0]
                                        : publicUrl +
                                        "assets/img/defimgs/" +
                                        mobileInfowindow?.selectedPlace?.propertyType +
                                        ".svg"
                                }
                                alt='img'
                            />
                        </div>
                        <div className='px-2 text-right'>
                            <h6 style={{ lineHeight: "33px", marginBottom: 0 }}>
                                {mobileInfowindow?.selectedPlace?.propertyTypeName}{" "}
                                {mobileInfowindow?.selectedPlace?.listedFor === "1" ? t("ForRent") : t("ForSale")}
                            </h6>
                        </div>
                        <div>
                            <ul className='info-list map px-2 '>
                                    {mobileInfowindow?.selectedPlace?.features?.map(
                                        (feature, i) =>
                                            renderFeatureIconCondition(
                                                mobileInfowindow?.selectedPlace?.propertyType,
                                                feature.key
                                            ) && (
                                                <li key={"li" + i} title={feature.name}>
                                                    <span className='feature-value'>
                                                        {feature.key !== '7'
                                                            ? Intl.NumberFormat("en").format(feature.value) + " " + feature.unit
                                                            : getFontspace(feature.value)}
                                                    </span>
                                                    {renderFeatureIcon(feature.key)}
                                                </li>
                                            )
                                    )}

                                {mobileInfowindow?.selectedPlace?.rooms?.map(
                                    (room, i) =>
                                        renderRoomIconCondition(
                                            mobileInfowindow?.selectedPlace?.propertyType,
                                            room.key
                                        ) && (
                                            <li key={"li-" + i} title={room.name}>
                                                <span className='feature-value'>{room.value}</span>
                                                {renderRoomIcon(room.key)}
                                            </li>
                                        )
                                )}
                            </ul>
                        </div>
                        {mobileInfowindow?.selectedPlace?.additionalInfo?.filter(i => i.key === '10')?.[0]?.valueText &&
                            mobileInfowindow?.selectedPlace?.additionalInfo?.filter(i => i.key === '10')?.[0]?.valueText !== 'لا' && (
                                <div className='pxp-marker-details-info px-2'>
                                    <div className='pxp-marker-details-info-price' dir='rtl'>
                                        <h5>
                                            {mobileInfowindow?.selectedPlace?.defaultPriceFomratted}{" "}
                                            ريال
                                        </h5>
                                    </div>
                                </div>
                            )}

                    </div>
                ) : (
                    <></>
                )}
            </Link>
        </div>
    );
}

export default MobileInfoWindow;
