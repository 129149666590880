import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { getFavouritesForUser } from "../../../../api/propertyApi";
import PropertyBox from "../../../../components/section-components/property-box";
import NoData from "../../../../components/global-components/no-data";
import LoadingData from "../../../../components/global-components/loading-data";
import { Box } from "@mui/material";

const MyFavorites = (props) => {
  const [Properties, setProperties] = useState([]);
  const [Settings, setSettings] = useState({
    userId: props.userId,
    page: "1",
    pageSize: "50",
    language: "0",
    totalCount: "0",
  });

  const [TotalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(true);

  function onChange() {
    setSettings({ ...Settings, totalCount: "50" });
  }

  useEffect(() => {
    setLoadingData(true);
    getFavouritesForUser(Settings)
      .then((property) => {
        if (property.resCode === 0) {
          setProperties(property.response.propertyArray);
          setTotalCount(property.response.totalCount);
        } else {
          toast.error(property.resStr);
        }
        setLoadingData(false);
      })
      .catch((error) => {
        setLoadingData(false);
        throw error;
      });
  }, [Settings]);

  return (
    <div className='page-width favorite-area pd-top-90 mg-bottom-100 text-center'>
      {loadingData ?
        <div className="pt-5">
          <LoadingData />
        </div>
        :
        <>
          <Box
            sx={{
              marginBottom: "40px",
              textAlign: "center",
              fontSize: "28px",
              fontWeight: 600,
              color: "#000",
            }}>
            الإعلانات العقاريّة المفضّلة
            {TotalCount > 0 && (
               <Box
               sx={{
                 textAlign: "center",
                 fontSize: "16px",
                 fontWeight: 400,
                 color: "#000",
               }}>
              
               قمت بتفضيل {TotalCount} إعلانات عقاريّة
             </Box>
            )}
           
          </Box>
            
          <div className='row align-items-center justify-content-end pd-top-30'>
            {Properties.length > 0 ? Properties.map((item, i) => (
              <div
                key={i}
                data-aos='flip-left'
                className='col-xl-3 col-lg-4 col-md-6 col-sm-6'
                style={{ cursor: "pointer" }}>
                <PropertyBox
                  propertey={item}
                  isEdit={false}
                  isFav={true}
                  userId={props.userId}
                  onChange={onChange}
                />
              </div>
            )) : <NoData msg="عذراً, لم تقم بإضافة أيّة إعلان عقاري للمفضلة"/>}
          </div>
        </>}
    </div>
  );
};

export default MyFavorites;
