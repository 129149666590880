import { confirmAlert } from "react-confirm-alert";
import { Apartment, Area, AreaForRent, BathRooms, BedRooms, Building2, BuildingYear, Compass, ConstructionArea, Elevator, Entrance, Farm, Flat, FloorArea, Floors, Frontispiece, Gallery, Height, Land, Office, Offices, Parking, Price, Resort, Roads, Store, Store2, Table, Villa, Wells, Width } from "../constants/icons";
import Cookies from "universal-cookie";
import { getDomainOwner } from "../api/userApi";
import { Alert, Snackbar } from "@mui/material";
import NavigationIcon from '@mui/icons-material/Navigation';
import { t } from "i18next";

const renderRoomIcon = (key) => {
  switch (key) {
    case "1":
    case "15":
    case "16":
    case "22":
      return <Roads />;
    case "8":
    case "12":
    case "27":
    case "40":
    case "43":
      return <BedRooms />;
    case "9":
    case "13":
    case "23":
    case "28":
      return <BathRooms />;
    case "10":
    case "2":
    case "14":
    case "17":
    case "24":
    case "29":
      return <Parking />;
    case "11":
    case "26":
      return <Frontispiece />;
    case "25":
    case "21":
      return <Offices />;
    case "6":
      return <Flat />;
    case "7":
      return <Entrance />;
    case "19":
    case "4":
      return <Store />;
    case "31":
      return <Table />;
    case "30":
      return <Wells />;
    case "20":
    case "5":
      return <Elevator />;
    case "18":
    case "3":
      return <Floors />;
    default:
      return <></>;
  }
}
  
const renderFeatureIcon = (key) => {
  switch (key) {
    case "1":
    case "3":
    case "10":
    case "13":
    case "15":
    case "20":
    case "27":
    case "30":
    case "42":
    case '39':
    case '41':
          return <Area />;
    case "2":
    case "8":
    case "12":
    case "14":
    case "19":
    case "22":
    case "24":
    case "25":
    case "29":
    case "32":
    case "38":
    case '52':
    case '50':
    case '51':
    case '60':
    case '61':
      return <Width />;
    case "17":
      return <AreaForRent />;
    case "18":
    case "5":
      return <FloorArea />;
    case "6":
      return <BuildingYear />;
    case "7":
      return <Compass />;
    case "4":
    case "9":
    case "11":
    case "16":
    case "21":
    case "23":
    case "26":
    case "28":
    case "31":
    case "40":
    case '43':
      return <ConstructionArea />;
    case "33":
    case "34":
    case "35":
    case "36":
      return <Height />;
    case "100":
      return <Price />;
    default:
      return <></>;
  }
}

const renderFeatureIconCondition = (type, key) => {
  if ((type === '1' && key === "1") ||
    (type === '2' && key === "3") ||
    (type === '3' && key === "9") ||
    (type === '13' && key === "9") ||
    (type === '14' && key === "40") ||
    (type === '4' && key === "10") ||
    (type === '5' && key === "13") ||
    (type === '6' && key === "15") ||
    (type === '7' && (key === "20" || key === "21" || key === "42")) ||
    (type === '8' && (key === "23" || key === "7")) ||
    (type === '9' && key === "26") ||
    (type === '10' && (key === "27" || key === "29")) ||
    (type === '11' && (key === "30" || key === "32")) ||
    (type === '12' && (key === "41")) ||
    (type === '13' && (key === "39")) ||
    (type === '15' && (key === "20" || key === "43"))) {
    return true;
  }
  return false;
}

const renderRoomIconCondition = (type, key) => {
  if ((type === '2' && key === "3") ||
    (type === '3' && (key === "8" || key === "9")) ||
    (type === '13' && (key === "8" || key === "9")) ||
    (type === '14' && (key === "8" || key === "9")) ||
    (type === '4' && (key === "12" || key === "13")) ||
    (type === '6' && key === "18") ||
    (type === '9' && key === "25") ||
    (type === '10' && key === "28") ||
    (type === '12' && (key === "8" || key === "9")) ||
    (type === '11' && key === "30")) {
    return true;
  }
  return false;
}
  
const openDeleteModal = (title, desc, onOK, OKTitle,dark) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className={`custom-ui ${dark ? 'dark' : ''}`}>
          <h3>{title}</h3>
          <h5 style={{ marginBottom: "15px",textAlign: 'right', direction: 'rtl' }}>
            {desc}
          </h5>
          <div className='btns-wrapper'>
            <button
              className='outlined-btn px-3'
              onClick={() => {
                onClose();
              }}>
              {t("Cancel")}
            </button>
            <button
              className={`primary-btn px-4 ${OKTitle && OKTitle !== 'تجاوز' ? '' : 'delete-btn'}`}
              onClick={() => {
                onClose();
                onOK();
              }}>
             {OKTitle || 'حذف' } 
            </button>
          </div>
        </div>
      );
    },
    closeOnEscape: true,
    closeOnClickOutside: true,
    willUnmount: () => { },
    afterClose: () => { },
    onClickOutside: () => { },
    onKeypressEscape: () => { },
    overlayClassName: `overlay-custom-class-name ${dark ? 'dark' : ''}`,
  });
}

const openChangePageConfirmationModal = (title, desc, onOK, onCancel) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='custom-ui'>
          <h3>{title}</h3>
          <h5 style={{ marginBottom: "15px" }}>
            {desc}
          </h5>
          <div className='btns-wrapper'>
            <button
              className='outlined-btn px-3'
              onClick={() => {
                onClose();
                onCancel();
              }}>
              {t("Cancel")}
            </button>
            <button
              className='primary-btn px-4'
              onClick={() => {
                onClose();
                onOK();
              }}>
             نعم, اعلم ذلك 
            </button>
          </div>
        </div>
      );
    },

  });
}

const renderPropertyIcon = (key) => {
  switch (key) {
    case "1":
    case "5":
      return <Land />;
    case "2":
    case "6":
    case '14':
      return <Building2 />;
    case "3":
    case '13':
      return <Apartment />;
    case "4":
      return <Villa />;
    case "7":
      return <Store2 />;
    case "8":
      return <Gallery />;
    case "9":
    case '15':
      return <Office />;
    case "10":
      return <Resort />;
    case "11":
      return <Farm />;
    default:
      return <></>;
  }
}

const renderPropertyIcon2 = (key) => {
  switch (key) {
    case "1":
      return <Land />;
    case "26":
    case "2":
      return <Building2 />;
    case "3":
    case "5":
    case "6":
      return <Apartment />;
    case "4":
      return <Villa />;
    case "12":
      return <Store2 />;
    case "10":
      return <Gallery />;
    case "11":
      return <Office />;
    case "7":
      return <Resort />;
    case "25":
      return <Farm />;
    default:
      return <></>;
  }
}

const renderPropertyTypeName = (key) => {
  switch (key) {
    case "1":
      return "أرض";
    case "2":
      return "عمارة";
    case "3":
      return "شقة";
    case "4":
      return "فيلا";
    case "5":
      return "أرض";
    case "6":
      return "عمارة";
    case "7":
      return "مستودع";
    case "8":
      return "معرض";
    case "9":
      return "مكتب";
    case "10":
      return "إستراحة";
    case "11":
      return "مزرعة";
    case "12":
      return "شقة صغيرة (استوديو)";
    case "13":
      return "غرفة";
    case "14":
      return "دور";
    default:
      return "";
  }
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const validateNumber = (number) => {
  return String(number)
    .match(
      /^[0-9]*$/
    );
};

const validatePassword = (password) => {
  return String(password)
    .match(
      /^[\s\S]{6,}$/
    );
};

const validatePhone = (phone) => {
  // return String(phone)
  //   .match(
  //     /^[^ 966][0-9]{8}$/
  //   );
  return String(phone)
  .match(
    /^[0-9]*$/
  );
};

const preventString = (str) => {
  return resolvePersianAndArabicNumbers(String(str).replace(/[a-zA-Z\u0621-\u064A\s]/g, ""));
};

const acceptNumbersAndStrings = (str) => {
  return String(str).replace(/[^a-zA-Z0-9]/gi, "");
};

const saveCookie = (key, value, maxAge = 60 * 60 * 24 * 100) => {
  if (value)
    new Cookies().set(key, JSON.stringify(value), { maxAge, path: '/' });
  else
    new Cookies().remove(key);
};

const getCookie = (key) => {
  return new Cookies().get(key);
}

const removeZeroFromNumberIfExists = (number) => {
  if (number.startsWith('0'))
    return number.substring(1);
  return number;
}

// const p2e = s => s.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));
// const a2e = s => s.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d));

const persianNumbers = [/۰/g, /۱/g, /۲/g, /۳/g, /۴/g, /۵/g, /۶/g, /۷/g, /۸/g, /۹/g],
  arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g],
  resolvePersianAndArabicNumbers = (str) => {
    if (typeof str === 'string') {
      for (let i = 0; i < 10; i++) {
        str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
      }
    }
    return str;
  };

  const validateInstagramURL = (url) => {
    return url ?resolvePersianAndArabicNumbers(url)
      .toLowerCase()
      .match(
        /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/
      ):true;
  };
  const validateFacebookURL = (url) => {
    return url ? resolvePersianAndArabicNumbers(url)
      .toLowerCase()
      .match(
        /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/
      ): true;
};
const validateTwitterURL = (url) => {
  return url ? resolvePersianAndArabicNumbers(url)
    .toLowerCase()
    .match(
      /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/
    ): true;
};
const parseUsername = (url) => {
  let matches = url.match(/(?:https?:\/\/)?(?:www.)?(?:twitter|facebook|instagram)(?:.com\/)?([@a-zA-Z0-9-_]+)/im);
  return matches?.length>0 ? matches[1] : url;
};

const getVendorName = async () => {
  let vendorName = '';
  if (window.location.hostname.includes('inme.com.sa')) {
    vendorName = 'amakkn';
  } else {
    if (window.location.hostname !== 'localhost' && !window.location.hostname.includes('amakkn.')) {
      let res = await getDomainOwner({ domain: window.location.hostname.includes('www.') ? window.location.hostname.substring(4) : window.location.hostname });
      vendorName = res?.response?.userName;
    } else {
      vendorName = window.location.hostname.split(".")[0];
      if (vendorName === "localhost")
        vendorName = process.env.REACT_APP_LOCALHOST_VENDOR;
      else if (vendorName === "www")
        vendorName = window.location.hostname.split(".")[1];
    }
  }

  return vendorName;
}

const validateURL = (url) => {
  const parsed = new URL(url);
  return ['https:', 'http:'].includes(parsed.protocol);
};

const getFontspace = (name) => {
  switch (name) {
    case "North":
      return "شمال";

    case "South":
      return "جنوب";

    case "East":
      return "شرق";

    case "West":
      return "غرب";

    case "North-West":
      return "شمال غرب";

    case "North-East":
      return "شمال شرق";

    case "South-East":
      return "جنوب شرق";

    case "South-West":
      return "جنوب غرب";
    case "3-Streets":
      return "ثلاثة شوارع";
    case "4-Streets":
      return "أربعة شوارع";
    default:
      return t("NotAvailable");
  }
}

const getDemoSnakbar = (open, setOpen) => {
  return (
    <Snackbar
      anchorOrigin={{ 'vertical': 'top', 'horizontal': 'center' }}
      open={open}
      sx={{ zIndex: 99999999999 }}
      autoHideDuration={4500}
      onClose={(event, reason) => {
        if (reason === 'clickaway') return;
        setOpen(false);
      }}>
      <Alert onClose={(event, reason) => {
        if (reason === 'clickaway') return;
        setOpen(false);
      }} severity="warning" sx={{ width: '100%' }}>
        &nbsp;   لا يمكنك المتابعة من خلال حساب تجريبي &nbsp;
      </Alert>
    </Snackbar>
  );
}

const trimPhone = (phone = "") => {
  if (phone?.startsWith('+966')) {
    return '0' + phone.substring(4);
  } else if (phone?.startsWith('966')) {
    return '0' + phone.substring(3);
  } else if (phone?.startsWith('00966')) {
    return '0' + phone.substring(5);
  } else if (phone?.startsWith('0966')) {
    return '0' + phone.substring(4);
  }else if (!phone?.startsWith('+') && !phone?.startsWith('0')) {
    return '0' + phone;
  } else {
    return phone;
  }
}

const resolvePhonePrefix = (phone = "") => {
  if (phone?.startsWith('966')) {
    return phone;
  } else if (phone?.startsWith('00966')) {
    return '966' + phone.substring(5);
  } else if (phone?.startsWith('0966')) {
    return '966' + phone.substring(4);
  }
  else if (phone?.startsWith('0')) {
    return '966' + phone.substring(1);
  } else if (phone?.startsWith('5')) {
    return '966' + phone;
  } else {
    return phone;
  }
}

const validateSAPhone = (phone) => {
  let reqex = /^5(5|0|3|6|4|9|1|8|7)([0-9]{7})$/;
  let phon = preventString(String(phone).replace(/[a-zA-Z\u0621-\u064A\s]/g, ""));
  if (phon !== "" && !reqex.test(phon)) {
    return false;
  }
  return true;
};

const getVideoId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11)
    ? match[2]
    : null;
}

const priceFormat = (price) => {
  let K = "K";
  let M = "M";
  let B = "B";

  if (price < 1000) {
    return price;
  } else if (price < 10000) {
    let priceText =
      price.substring(0, 1) +
      (price.substring(1, 2) === "0" ? "" : "." + price.substring(1, 2)) +
      (price.substring(2, 3) === "0" ? "" : price.substring(2, 3));
    return priceText + K;
  } else if (price < 100000) {
    let priceText =
      price.substring(0, 2) +
      (price.substring(2, 3) === "0" ? "" : "." + price.substring(2, 3));
    return priceText + K;
  } else if (price < 1000000) {
    let priceText = price.substring(0, 3);
    return priceText + K;
  } else if (price < 10000000) {
    let priceText =
      price.substring(0, 1) +
      (price.substring(1, 2) === "0" ? "" : "." + price.substring(1, 2)) +
      (price.substring(2, 3) === "0" ? "" : price.substring(2, 3));
    return priceText + M;
  } else if (price < 100000000) {
    let priceText =
      price.substring(0, 2) +
      (price.substring(2, 3) === "0" ? "" : "." + price.substring(2, 3));
    return priceText + M;
  } else if (price < 1000000000) {
    let priceText = price.substring(0, 3);
    return priceText + M;
  } else {
    let priceText = "";
    if (price)
      priceText =
        price.substring(0, 1) +
        (price.substring(1, 2) === "0" ? "" : "." + price.substring(1, 2)) +
        (price.substring(2, 3) === "0" ? "" : price.substring(2, 3));
    return priceText + B;
  }
}

const mStyles = [
  {
    featureType: "administrative",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#E4E4E4",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#B2B2B2",
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text",
    stylers: [
      {
        color: "#434343",
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

const renderLocationTitle = (index) => {
  switch (index) {
    case 0: return t("Region");
    case 1: return t("City");
    case 2: return 'الحي';
    case 3: return 'الشارع';
    case 4: return 'الرمز البريدي';
    case 5: return 'رقم المبنى';
    case 6: return 'الرقم الإضافي';
    default: return '';
  }
}

const resolveRerBordersValue = (value) => {
  switch (value) {
    case 'Road': return 'شارع';
    case 'Land': return 'أرض';
    case 'East': return 'شرقاً';
    case 'West': return 'غرباً';
    case 'South': return 'جنوباً';
    case 'North': return 'شمالاً';
    default: return value;
  }
}

const resolveRerBorders = (item, index) => {
  return (
    <div
      className="box col-12 pr-0"
      key={index}>
      <div className='icon blue'>
        <NavigationIcon sx={{ transform: index === 0 ? '' : index === 1 ? 'rotate(90deg)' : index === 3 ? 'rotate(180deg)' : 'rotate(-90deg)' }} />
      </div>
      <div className='info'>
        <span>
          <b>{item?.split('&')?.[0] === "0" ? '' : resolveRerBordersValue(item?.split('&')?.[0]) + ": "}</b>
          {resolveRerBordersValue(item?.split('&')?.[1]) + " "}
          <b>بطول: </b>
          {parseFloat(item?.split('&')?.[2]).toFixed(2) + ' متر'}
        </span>
      </div>
    </div>
  );
}

const renderMeterIfNotExist = (item) => {
  return item.includes('متر') ? item : item + " متر";
}

// const checkNoAdditionalInfo = (AIItem) => {
//   return (HIDDEN_ADDITIONAL_INFO_IF_NO.includes(AIItem.key) &&
//     AIItem.valueText !== '0' &&
//     AIItem.valueText !== 'لا' &&
//     AIItem.valueText !== 'لا يوجد' &&
//     AIItem.valueText !== 'لايوجد' &&
//     AIItem.valueText !== 'No' &&
//     AIItem.valueText !== 'no') || !HIDDEN_ADDITIONAL_INFO_IF_NO.includes(AIItem.key);
// }

const resolveUserTypeName = (userTypeKey) => {
  let userType;

  switch (userTypeKey) {
    case "1":
      userType = "مستخدم عادي";
      break;
    case "2":
      userType = "مالك عقار";
      break;
    case "3":
      userType = "حساب أفراد";
      break;
    case "4":
      userType = "منشأة عقاريّة";
      break;
    case "5":
      userType = "حساب فرعي لمنشأة عقاريّة";
      break;
    default:
      break;
  }
  return userType;
}


export { resolvePhonePrefix, resolveUserTypeName, renderMeterIfNotExist, renderLocationTitle, resolveRerBorders, mStyles, priceFormat, getVideoId, validateSAPhone, trimPhone, getDemoSnakbar, renderPropertyIcon2, getFontspace, validateURL, getVendorName, parseUsername, validateTwitterURL,validateFacebookURL, validateInstagramURL, acceptNumbersAndStrings, preventString, validateNumber, validatePassword, validatePhone ,openChangePageConfirmationModal, resolvePersianAndArabicNumbers, removeZeroFromNumberIfExists, getCookie, saveCookie, validateEmail, renderPropertyTypeName, renderPropertyIcon, openDeleteModal, renderFeatureIcon, renderRoomIcon, renderFeatureIconCondition, renderRoomIconCondition };