import { Skeleton } from "@mui/material";
import React from "react";

export default function LoadingTemplate() {
 
  return (
    <div className="mt-5 w-100 page-width mx-auto">
      <div className='row'>
        <div className='col-10 mx-auto mb-5'>
          <Skeleton
            className='d-block'
            variant='rectangular'
            width='100%'
            height={550}
            sx={{ borderRadius: "12px" }}
          />
        </div>
        <div className='col-10 mx-auto mb-4 d-flex align-items-center flex-column'>
          <Skeleton variant='rectangular' width={100} height={25} sx={{borderRadius: "12px",marginBottom:'10px'}} />
          <Skeleton variant='rectangular' width={180} height={15} sx={{ borderRadius: "12px" }} />
        </div>
        <div className='col-10 mx-auto mb-4'>
          <Skeleton variant='rectangular' width='100%' sx={{ borderRadius: "12px" }} height={70} />
        </div>
        <div className='col-10 mx-auto mb-4 d-flex align-items-center flex-column'>
          <Skeleton variant='rectangular' width={100} height={25} sx={{ borderRadius: "12px", marginBottom: '10px' }} />
          <Skeleton variant='rectangular' width={180} height={15} sx={{ borderRadius: "12px" }} />
        </div>
        <div className='col-10 mx-auto mb-5'>
          <Skeleton variant='rectangular' width='100%' sx={{ borderRadius: "12px" }} height={70} />
        </div>
        <div className='col-10 mx-auto mb-4 d-flex align-items-center flex-column'>
          <Skeleton variant='rectangular' width={100} height={25} sx={{borderRadius: "12px",marginBottom:'10px'}} />
          <Skeleton variant='rectangular' width={180} height={15} sx={{ borderRadius: "12px" }} />
        </div>
        <div className='col-10 mx-auto mb-5'>
          <Skeleton
            className='d-block'
            variant='rectangular'
            width='100%'
            height={550}
            sx={{ borderRadius: "12px" }}
          />
        </div>
        <div className='col-10 mx-auto mb-4 d-flex align-items-center flex-column'>
          <Skeleton variant='rectangular' width={100} height={25} sx={{ borderRadius: "12px", marginBottom: '10px' }} />
          <Skeleton variant='rectangular' width={180} height={15} sx={{ borderRadius: "12px" }} />
        </div>
        <div className='col-10 mx-auto mb-5'>
          <Skeleton variant='rectangular' width='100%' sx={{ borderRadius: "12px" }} height={70} />
        </div>
        <div className='col-10 mx-auto mb-4 d-flex align-items-center flex-column'>
          <Skeleton variant='rectangular' width={100} height={25} sx={{ borderRadius: "12px", marginBottom: '10px' }} />
          <Skeleton variant='rectangular' width={180} height={15} sx={{ borderRadius: "12px" }} />
        </div>
        <div className='col-10 mx-auto mb-5'>
          <Skeleton
            className='d-block'
            variant='rectangular'
            width='100%'
            height={550}
            sx={{ borderRadius: "12px" }}
          />
        </div>
        <div className='col-10 mx-auto mb-5'>
          <Skeleton variant='rectangular' width='100%' sx={{ borderRadius: "12px" }} height={70} />
        </div>
      </div>
    </div>
  );
}
