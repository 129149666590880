import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import EditProperty from "./components/edit-property";
import * as userApi from "../../../api/userApi";
import * as propertyApi from "../../../api/propertyApi";
import { toast } from "react-toastify";
import { getAllParamsForAddProperty } from "../../../api/propertyApi";
import { CircularProgress } from "@mui/material";
import AppLayout from "../../../components/layouts/app-layout";
import Forbidden from '../403';
import { useAuth } from "../../../helpers/context";
import { useTranslation } from "react-i18next";

const isAdmin = window.location.href.includes("admin");

const EditTheProperty = () => {
  const { token, UserId } = useAuth();
  let { id } = useParams();
  const [user] = useState({
    userId: token,
    language: "0",
  });
  const [AllParams, setAllParams] = useState({});
  const [msg, setMsg] = useState("wait");
  const [UserVerified, setUserVerified] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (token)
      userApi.getUserProile(user).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          if (_userInfo.response.userProfile.isUserVerified === "3") {
            propertyApi
              .getPropertyDescription({
                propertyId: id,
                language: "0",
                userId: UserId,
              })
              .then((property) => {
                if (property.resCode === 0) {
                  if (
                    _userInfo.response.userProfile.userId === property.response.userId ||
                    _userInfo.response.userProfile.userId === property.response.superUserId
                  ) {
                    getAllParamsForAddProperty({
                      propertyType: property.response.propertyType,
                      userId: token,
                      language: "0",
                    })
                      .then((params) => {
                        if (params.resCode === 0) {
                          setAllParams(params.response);
                          setMsg("Done");
                        }
                      })
                      .catch((error) => {
                        throw error;
                      });
                  } else setMsg("أنت لا تملك أحقية تعديل هذا الإعلان العقاري");
                } else {
                  toast.error(property.resStr);
                }
              })
              .catch((error) => {
                throw error;
              });
          } else {
            setUserVerified(false);
            setMsg(
              "حسابك غير موثق، يرجى توثيق الحساب لكي تتمكن من تعديل إعلانك العقاري على أماكن"
            );
          }
        } else {
          const _errors = {};
          _errors.phone = _userInfo.resStr;
        }
      });
  }, [UserId, id, token, user]);

  return (
    <AppLayout needAuth pageTitle={t("EditAD")} withoutNav={isAdmin}>
      {token && (
        <>
          {msg === "Done" ? (
            isAdmin ? <EditProperty
              userId={user.userId}
              propertyId={id}
              AllParams={AllParams}
              isEdit={true}
              isAdmin={false}
            /> :
              <div
                className='add-new-property-area px-4 pb-4'
                // style={{ paddingTop: "70px" }}
                dir="rtl">
                <EditProperty
                  userId={user.userId}
                  propertyId={id}
                  AllParams={AllParams}
                  isEdit={true}
                  isAdmin={false}
                />
              </div>
          ) : (
            <>
              {msg === 'wait' ?
                <div className='add-new-property-area pd-top-90 mg-bottom-100 text-center'>
                  <CircularProgress />
                </div>
                :
                UserVerified === false ?
                  <Forbidden btnLink={'/verify'} btnText={t("AccountVerification")} desc={msg} />
                  : <Forbidden btnLink={'/'} btnText={t("BackToHomePage")} desc={msg} />
              }
            </>
          )}
        </>
      )}
    </AppLayout>
  );
};

export default EditTheProperty;
