import React from "react";
import { Box, Button, Step, StepLabel, Stepper,CircularProgress, Typography, Hidden } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingButton from "./loading-btn";
import { useAuth } from "../../helpers/context";
import data from "../admin/data";
import { useTranslation } from "react-i18next";

const SubHeader = ({ extra, style, completedSteps = [], currentStep = 0, withSteps, steps, loading, OKdisabled, OKElement, pageTitle, pageDesc, onOK, withCancel = false, CancelElement }) => {
  const navigate = useNavigate();
  const { vendor } = useAuth();
  const { t } = useTranslation();

  const content = (
    <Box sx={{
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '16px auto 32px'
    }}
      style={style || {}}
    >
      <Box className="sub-header" sx={{ marginBottom: { xs: '10px', sm: '0' }, textAlign: { xs: 'center', sm: 'right' } }}>
        <>{vendor === 'amakkn' ? pageTitle : t(data?.menus?.filter(i => window.location.pathname.includes(i.link))?.[0]?.text) || ""}{extra}</>
        {vendor === 'amakkn' ? pageDesc ?
          <Box className="sub-header-des" sx={{ textAlign: { xs: 'center', sm: 'right' } }}>{pageDesc}</Box> : <></> :
          data?.menus?.filter(i => window.location.pathname.includes(i.link))?.[0]?.description && <Box className="sub-header-des" sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
            {t(data?.menus?.filter(i => window.location.pathname.includes(i.link))?.[0]?.description)}
          </Box>
        }
        
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '16px', alignItems: 'center' }}>
        {CancelElement ? CancelElement : withCancel && <Button onClick={() => navigate(-1)}
          className="outlined-btn px-3">{t("Cancel")}</Button>}
        {OKElement ? OKElement : <LoadingButton
          OKdisabled={OKdisabled}
          handleClick={() => onOK ? onOK() : () => { }}
          label={t("SaveChanges")}
          loading={loading}
        />}
      
      </Box>
    </Box>
  );

  return (
    <>
      {vendor === 'amakkn' ? content : <Hidden mdUp>{content}</Hidden>}
      <Hidden mdDown>
        <div style={{ height: '20px' }} />
        {/* {CancelElement ? CancelElement : withCancel && null} */}
        {OKElement ? OKElement : null}
      </Hidden>
      {withSteps && (
        <Box sx={{ width: '100%', margin: '16px auto 32px', padding: 0 }}>
          <Stepper
            className='amakkn-steps'
            nonLinear activeStep={currentStep} >
            {steps.map((label, index) => (
              <Step key={label} completed={completedSteps[index] !== undefined ? true : false} >
                <StepLabel color="inherit">
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <div className='onboarding-mobile-steps'>
            <Box sx={{ position: "relative", display: "inline-flex" }}>
              <CircularProgress
                size={60}
                thickness={6}
                variant='determinate'
                value={(100 / steps.length) * (currentStep + 1)}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: '-3px',
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <Typography
                  variant='caption'
                  component='div'
                  color='text.secondary'>
                  خطوة
                  <br />
                  {`${currentStep + 1}/${steps.length}`}
                </Typography>
              </Box>
            </Box>
            <p style={{ margin: 0 }}>{steps[currentStep]}</p>
          </div>
        </Box>
      )}
    </>
  );
}

export default SubHeader;
