import { Card, CardContent, CardHeader } from '@mui/material'
import React from 'react'
import DealCard from './deal-card'
import { Draggable, Droppable } from 'react-beautiful-dnd';

export default function BoardColumn({ companyName, setOpenDealDialog, data, path, setDeal, openSettingsPopper, setAnchorSettingsEl, anchorSettingsEl, setOpenSettingsPopper }) {
    
    const renderComponent = (item) => {
        return (
            <DealCard
                item={item}
                companyName={companyName}
                setDeal={setDeal}
                setOpenDealDialog={setOpenDealDialog}
                key={item.id}
                openPopper={openSettingsPopper}
                setAnchorEl={setAnchorSettingsEl}
                anchorEl={anchorSettingsEl}
                setOpenPopper={setOpenSettingsPopper}
            />
        );
    };
    
    return data?.children && (data?.children?.length === 0 || data?.children?.length > 0) && (
        <Card className="deal-card">
            <CardHeader
                title={data?.title}
                sx={{
                    paddingBottom: '10px',
                    borderBottom: `3px solid ${data?.color}`,
                    '& .MuiTypography-root': {
                        fontWeight: 600,
                        fontSize: '15px'
                    }
                }}
            />
            <CardContent className='draggable'>
                <Droppable droppableId={`list-${path}`}>
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{ width: '100%', height: '100%' }}
                        >
                            <div className="row deals-col base column">

                                {data?.children?.map((item, index) => {
                                    return (
                                        <>
                                            <Draggable draggableId={item.id} key={item.id} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        {renderComponent(item)}
                                                    </div>
                                                )}
                                            </Draggable>
                                            {provided.placeholder}
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </Droppable>
                   
            </CardContent>
        </Card>
    );
}