import React, { useState } from 'react';
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, IconButton, TextField } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import LoadingButton from '../../../../components/global-components/loading-btn';
import { useAuth } from '../../../../helpers/context';
import { saveTaskComment } from '../../../../api/userApi';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function AddCommentDialog({ dialog, setDialog, onOK }) {
    const [isSubmittingData, setIsSubmittingData] = useState(false);
    const { token } = useAuth();
    const [content, setContent] = useState('');
    const [errors, setErrors] = useState({});
    const { t } = useTranslation();

    function formIsValid() {
        const _errors = {};
        if (!content)
            _errors.content = "الرجاء ادخال التعليق";
        
        setErrors(_errors);
        return Object.keys(_errors).length === 0;
    }

    const handleSubmit = () => {
        if (!formIsValid()) return;
        setIsSubmittingData(true);
        saveTaskComment({
            userId: token,
            taskId: dialog.id,
            content: content,
            language: '0'
        }).then(res => {
            if (res.resCode === 0) {
                setContent("")
                toast.success('تمّت إضافة التعليق بنجاح');
                setDialog({ visible: false, id: '' });
                onOK?.();
            } else {
                toast.error(res.resStr);
            }
        }).finally(() => setIsSubmittingData(false));
    }

    return (
        <Dialog
            onClose={() => setDialog({ visible: false, id: '' })}
            maxWidth={"xs"}
            className='custom-dialog'
            style={{ direction: "rtl", textAlignLast: "right", zIndex: 9999999 }}
            sx={{
                "& .MuiPaper-root": {
                    margin: 0,
                    width: "calc(100% - 20px)",
                    zIndex: 9999999,
                },
            }}
            open={dialog.visible}>
            <DialogTitle>
                <div className='dialog-head mb-4'>
                    <div>
                        <h2 className='mb-0'>{t("AddComment")}</h2>
                    </div>
                    <div className='dialog-actions'>
                        <IconButton
                            aria-label='close'
                            onClick={() => setDialog({ visible: false, id: '' })}
                            sx={{
                                color: (theme) => theme.palette.grey[500],
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <FormControl className="my-2 w-100">

                    <TextField
                        label="التعليق"
                        required
                        sx={{
                            width: "100%",
                            direction: 'rtl'
                        }}
                        className="text-right"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={content}
                        size='small'
                        multiline
                        rows={3}
                        onChange={(e) => setContent(e.currentTarget.value)}
                    />

                    {errors?.contnet &&
                        <FormHelperText dir="rtl">
                            <Alert severity="error" style={{ width: 'fit-content' }} className="custom-alert">
                                {t("ThisFieldIsMandatory")}
                            </Alert>
                        </FormHelperText>
                    }

                </FormControl>
            </DialogContent>
            <DialogActions sx={{ gap: '8px' }}>
                <LoadingButton
                    loading={isSubmittingData}
                    classes='primary-btn px-3'
                    label={'إضافة'}
                    handleClick={handleSubmit}
                />
                    
                <button
                    className='outlined-btn px-3'
                    onClick={() => setDialog({ visible: false, id: '' })}>
                    {t("Cancel")}
                </button>
            </DialogActions>
        </Dialog>
    );
}
