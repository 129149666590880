import { CircularProgress } from '@mui/material';
import React from 'react'

export default function LoadingButton({ icon, style, type, classes, loading, handleClick, OKdisabled, label,iconLeft }) {
    return (
        <button
            disabled={OKdisabled ? true : loading ? true : false}
            onClick={handleClick ? handleClick : () => { }}
            className={classes ? classes : "primary-btn py-2 px-3"}
            type={type ? type : 'button'}
            style={style ? style : {}}
            dir="rtl"
        >
            {typeof label === "string" ? (
                <>
                    {!iconLeft && icon ? icon: null}
                    <span>{label}</span>
                    {iconLeft ? icon : null}
                    {loading && <CircularProgress size={20} />}
                </>
            ) : loading ? <CircularProgress size={20} sx={{ color: '#fff' }} /> : label}
        </button>
    );
}
