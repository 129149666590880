import React from 'react'
import { Card, CardContent, CardHeader, CardMedia, IconButton, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Mail } from '@mui/icons-material';
import { Buildings, Tel } from '../../../constants/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const publicUrl = process.env.REACT_APP_URL + "/";

export default function AgentCardForAdmin({ item, setOpenPopper, setAnchorEl, openPopper, anchorEl, setSelectedAgent }) {

    const history = useNavigate();
    const { t } = useTranslation();
    
    return (
        <Card className='p-0 position-relative radius-16'>
            <CardHeader
                action={
                    <IconButton
                        onClick={(e) => {
                            setOpenPopper(!openPopper);
                            setAnchorEl(anchorEl ? null : e.target);
                            setSelectedAgent(item);
                        }}
                        sx={{
                            background: '#fff',
                            color: 'var(--main-color-one)',
                            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                            '&:hover,&:focus': {
                                color: '#fff',
                                background: 'var(--main-color-one)'
                            }
                        }}>
                        <MoreVertIcon />
                    </IconButton>
                }
                sx={{ position: 'absolute', top: 0 }}
            />
            <CardMedia
                component="img"
                height="194"
                onClick={() => history(`/admin/user-profile/${item.userId}`)}
                style={{ cursor: 'pointer' }}
                className={item.avatar ? '' : "contain-img"}
                image={item.avatar || (publicUrl + "assets/img/defimgs/1_account.svg")}
                alt={item.name}
            />
            <CardContent
                onClick={() => history(`/admin/user-profile/${item.userId}`)}
                sx={{ minHeight: '170px', textAlign: 'right', cursor: 'pointer', borderTop: '1px solid #ddd', '&:last-child': { paddingBottom: 0 } }}>
                <Typography variant="h6" color="text.primary" className='admin-card-title'>
                    {item.name}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    {item.address}
                </Typography>
                <ul className='info-list'>
                    <li> <Mail /><span>{item.email || t("NotAvailable")}</span></li>
                    <li> <Tel /><span><span style={{ direction: 'ltr', display: 'inline-block' }}>{item.countryCode}{item.phone}</span></span></li>
                    <li> <Buildings /><span>{item.activeProperties}</span></li>
                </ul>
            </CardContent>
        </Card>
    );
}
