import React, { useState, useEffect } from "react";
import { getListOfAllSubjects, sendFeedback } from "../../api/generalAPI";
import PageHeader from "../../components/global-components/page-header";
import AppLayout from "../../components/layouts/app-layout";
import { getWLProfile, wlContactUs } from "../../api/userApi";
import { FormControl, InputLabel, TextField, Select, FormHelperText, MenuItem, OutlinedInput, Alert, Box } from "@mui/material";
import { Marker, Tel } from "../../constants/icons";
import { MailOutline, WhatsApp } from "@mui/icons-material";
import LoadingButton from "../../components/global-components/loading-btn";
import { preventString, resolvePersianAndArabicNumbers, trimPhone, validateEmail, validatePhone } from "../../helpers";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useAuth } from "../../helpers/context";
import { DARK_TEMPLATES, MODERN_TEMPLATES } from "../../constants";
import { useTranslation } from "react-i18next";

export const ContactForm = ({ variant }) => {
  const [IssueType, setIssueType] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [user, setuser] = useState({
    name: "",
    email: "",
    message: "",
    subjectId: ""
  });
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    getListOfAllSubjects({
      language: "0",
    }).then((_IssueType) => {
      if (_IssueType.resCode === 0) {
        setIssueType(_IssueType.response.subjectArray);
      }
    });
  }, []);


  function formIsValid() {
    const _errors = {};
    if (!user.name) _errors.name = "الرجاء ادخال الاسم";
    if (!user.subjectId) _errors.subjectId = "الرجاء اختيار الموضوع";
    if (!user.message) _errors.message = "الرجاء ادخال الرسالة";
    if (!user.email) _errors.email = "الرجاء ادخال البريد الإلكتروني";
    else if (!validateEmail(user.email)) _errors.email = "الرجاء ادخال بريد إلكتروني صحيح";

    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }

  function onSubmit() {
    if (!formIsValid()) return;
    setLoadingButton(true);
    sendFeedback({
      language: "0",
      subjectId: user.subjectId,
      name: user.name,
      email: user.email,
      message: user.message,
      userId: "",
    }).then((_FeedBackInfo) => {
      if (_FeedBackInfo.resCode === 0) {
        Swal.fire({
          title: 'تم بنجاح',
          text: "تم ارسال الرسالة بنجاح",
          icon: 'success',
          confirmButtonText: t("Close")
        });
        setuser({
          ...user,
          name: "",
          email: "",
          message: "",
          subjectId: ''
        });
      }
      else
        Swal.fire({
          title: 'حدث خطأ',
          text: _FeedBackInfo.resStr,
          icon: 'error',
          confirmButtonText: 'حسناً'
        });
      setLoadingButton(false);
    }).catch(e => setLoadingButton(false));
  }
  return (
    <div className={`contact-form-wrap ${variant === 'two-rows' ? 'row' : ''}`} dir="rtl">
      <div className={variant === 'two-rows' ? 'col-12 col-md-4 col-lg-4 mb-3' : 'mt-3'}>
        <FormControl
          error={Boolean(errors.email)}
          className="col-12" dir="rtl"
        >
          <TextField
            label={t("Email")}
            sx={{ width: "100%" }}
            type='text'
            InputLabelProps={{
              shrink: true,
            }}
            autoComplete="off"
            required
            variant={'outlined'}
            size='small'
            onChange={(e) => {
              setuser({ ...user, email: resolvePersianAndArabicNumbers(e.currentTarget.value) });
            }}
            error={Boolean(errors.email)}
            value={user.email}
          />
          {errors.email &&
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.email}
              </Alert>
            </FormHelperText>}
        </FormControl>
      </div>
      <div className={variant === 'two-rows' ? 'col-12 col-md-4 col-lg-4 mb-3' : 'mt-3'}>
        <FormControl
          error={Boolean(errors.name)}
          className="col-12" dir="rtl"
        >
          <TextField
            label={t("Name")}
            sx={{ width: "100%" }}
            type='text'
            InputLabelProps={{
              shrink: true,
            }}
            autoComplete="off"
            required
            variant={'outlined'}
            size='small'
            onChange={(e) => {
              setuser({ ...user, name: resolvePersianAndArabicNumbers(e.currentTarget.value) });
            }}
            error={Boolean(errors.name)}
            value={user.name}
          />
          {errors.name &&
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.name}
              </Alert>
            </FormHelperText>}
        </FormControl>
      </div>
 
      <div className={variant === 'two-rows' ? 'col-12 col-md-4 col-lg-4 mb-3' : 'mt-3'}>
        <FormControl
          required
          error={errors.subjectId ? true : false}
          className="col-12 "
        >
          <InputLabel id="subjectId-label">{t("Subject")}</InputLabel>
          <Select
            sx={{ textAlign: 'right' }}
            name="subjectId"
            labelId="subjectId-label"
            variant="outlined"
            size="small"
           
            input={<OutlinedInput
              notched
              label={t("Subject")}
              id="subjectId-label"
            />}
            label={t("Subject")}
            value={user.subjectId}
            onChange={(e) => setuser({ ...user, subjectId: resolvePersianAndArabicNumbers(e.target.value) })
            }
          >
            {IssueType.map((item) => <MenuItem value={item.subjectId} key={item.subjectId}>{item.subject}</MenuItem>)}
          </Select>
          {errors.subjectId &&
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.subjectId}
              </Alert>
            </FormHelperText>}
        </FormControl>
      </div>
 
      <div className={variant === 'two-rows' ? 'col-12 mb-4' : 'mt-3 mb-3'}>
        <FormControl
          error={Boolean(errors.message)}
          className="col-12" dir="rtl"
        >
          <TextField
            variant={'outlined'}
            multiline
            sx={{ width: "100%" }}
            InputLabelProps={{ shrink: true }}
            rows={8}
            label={t("Message")}
            required
            value={user.message}
            onChange={(e) =>
              setuser({ ...user, message: resolvePersianAndArabicNumbers(e.currentTarget.value) })
            }
            error={Boolean(errors.message)}
          />
          {errors.message &&
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.message}
              </Alert>
            </FormHelperText>}
        </FormControl>
      </div>
      <div className='btn-wrap text-center'>
        <LoadingButton
          classes={`primary-btn w-100 d-flex align-items-center justify-content-center mb-3 ${variant === 'two-rows' ? 'py-2' : 'btn primary-btn w-100  mx-auto'}`}
          label={t("Send")}
          loading={loadingButton}
          handleClick={onSubmit}
        />
      </div>
    </div>);
};

export const ContactForm2 = ({ data, vendorName, variant }) => {
  const [loadingButton, setLoadingButton] = useState(false);
  const { templateId } = useAuth();
  const [user, setuser] = useState({
    name: "",
    email: "",
    message: "",
    phone: '',
    subject: ''
  });
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  function formIsValid() {
    const _errors = {};
    if (!user.name) _errors.name = "الرجاء ادخال الاسم";
    if (!user.subject) _errors.subject = "الرجاء ادخال الموضوع";
  
    if (!user.phone) _errors.phone = "الرجاء ادخال رقم الهاتف";
    else if (!validatePhone(user.phone)) _errors.phone = "الرجاء ادخال رقم جوال صحيح";
    if (!user.message) _errors.message = "الرجاء ادخال الرسالة";
  
    if (!user.email) _errors.email = "الرجاء ادخال البريد الإلكتروني";
    else if (!validateEmail(user.email)) _errors.email = "الرجاء ادخال بريد إلكتروني صحيح";
  
    setErrors(_errors);
    // Form is valid if the errors object has no properties
    return Object.keys(_errors).length === 0;
  }
  function onSubmit() {
    if (!formIsValid()) return;
    setLoadingButton(true);
    wlContactUs({
      userName: vendorName,
      subject: user.subject,
      phone: user.phone,
      text: user.message,
      email: user.email,
      name: user.name,
      language: "1"
    }).then((_FeedBackInfo) => {
      if (_FeedBackInfo.resCode === 0) {
        Swal.fire({
          title: 'تم بنجاح',
          text: "تم ارسال الرسالة بنجاح",
          icon: 'success',
          confirmButtonText: t("Close")
        });
        setuser({
          ...user, name: "",
          email: "",
          message: "",
          phone: '',
          subject: ''
        });
      }
      else
        Swal.fire({
          title: 'حدث خطأ',
          text: _FeedBackInfo.resStr,
          icon: 'error',
          confirmButtonText: 'حسناً'
        });
      setLoadingButton(false);
      
    }).catch(e => setLoadingButton(false));
  }
  return (
    <div className="row m-0 flex-row-reverse">
      {[...MODERN_TEMPLATES,...DARK_TEMPLATES].includes(+templateId) ? (
        <div className="col-12 col-lg-4 text-right contact-section" dir="rtl">
        
          <div>
          <span className="circle circle-1"></span>
          <span className="circle circle-2"></span>
            <div style={{ marginBottom: '40px' }}>
              <h4 style={{ color: '#fff' }}>معلومات التواصل</h4>
            </div>
            {data?.address?.trim() && (
              <p className="flex-info">
                <Marker />
                {data?.address?.includes('@') ? data?.address?.substring(0, data?.address?.indexOf('@')) : data?.address}
              </p>
            )}

            <p className="flex-info">
              <MailOutline />
              <a className="inverse-link" href={`mailto:${data.email}`}>{data.email}</a>
            </p>
            <p className="flex-info">
              <Tel />
              <a className="inverse-link" href={`tel:${trimPhone(data.phone)}`}>
                {trimPhone(data.phone)}
              </a>
            </p>
         
            <p className="flex-info">
              <WhatsApp />
              <a className="inverse-link" href={`https://wa.me/${trimPhone(data.whatsapp)}`}>
                {trimPhone(data.whatsapp)}
              </a>
            </p>
          </div>
        </div>
      ) : null}
      <div className={`col-12 ${[...MODERN_TEMPLATES,...DARK_TEMPLATES].includes(+templateId) ? 'col-lg-8 mt-4 mt-lg-0 ' : ''} contact-form-wrap ${variant === 'two-rows' ? 'row ' : ''}`} dir="rtl">
  
        <div className={variant === 'two-rows' ? [...MODERN_TEMPLATES,...DARK_TEMPLATES].includes(+templateId) ? 'col-12 col-md-6 mb-3' : 'col-12 col-md-6 col-lg-3 mb-3' : 'mt-3'}>
          <FormControl
            error={Boolean(errors.email)}
            className="col-12" dir="rtl"
          >
            <TextField
              label={t("Email")}
              sx={{ width: "100%" }}
              type='text'
              InputLabelProps={{
                shrink: true,
              }}
              required
              variant={'outlined'}
              size='small'
              autoComplete="off"
              onChange={(e) => {
                setuser({ ...user, email: resolvePersianAndArabicNumbers(e.currentTarget.value) });
              }}
              error={Boolean(errors.email)}
              value={user.email}
            />
            {errors.email &&
              <FormHelperText component={Box}>
                <Alert severity="error" className="custom-alert">
                  {errors.email}
                </Alert>
              </FormHelperText>}
          </FormControl>
        </div>
        <div className={variant === 'two-rows' ? [...MODERN_TEMPLATES,...DARK_TEMPLATES].includes(+templateId) ? 'col-12 col-md-6 mb-3' : 'col-12 col-md-6 col-lg-3 mb-3' : 'mt-3'}>
          <FormControl
            error={Boolean(errors.name)}
            className="col-12" dir="rtl"
          >
            <TextField
              label={t("Name")}
              sx={{ width: "100%" }}
              type='text'
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
              required
              variant={'outlined'}
              size='small'
              onChange={(e) => {
                setuser({ ...user, name: resolvePersianAndArabicNumbers(e.currentTarget.value) });
              }}
              error={Boolean(errors.name)}
              value={user.name}
            />
            {errors.name &&
              <FormHelperText component={Box}>
                <Alert severity="error" className="custom-alert">
                  {errors.name}
                </Alert>
              </FormHelperText>}
          </FormControl>
        </div>

        <div className={variant === 'two-rows' ? [...MODERN_TEMPLATES,...DARK_TEMPLATES].includes(+templateId) ? 'col-12 col-md-6 mb-3' : 'col-12 col-md-6 col-lg-3 mb-3' : 'mt-3'}>
          <FormControl
            error={Boolean(errors.phone)}
            className="col-12" dir="rtl"
          >
            <TextField
              label={t("PhoneNumber")}
              sx={{ width: "100%" }}
              type='text'
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
              required
              variant={'outlined'}
              size='small'
              onChange={(e) => {
                setuser({ ...user, phone: preventString(e.currentTarget.value) });
              }}
              error={Boolean(errors.phone)}
              value={user.phone}
            />
            {errors.phone &&
              <FormHelperText component={Box}>
                <Alert severity="error" className="custom-alert">
                  {errors.phone}
                </Alert>
              </FormHelperText>}
          </FormControl>
        </div>

        <div className={variant === 'two-rows' ? [...MODERN_TEMPLATES,...DARK_TEMPLATES].includes(+templateId) ? 'col-12 col-md-6 mb-3' : 'col-12 col-md-6 col-lg-3 mb-3' : 'mt-3'}>
          <FormControl required className="w-100 text-right" dir="rtl">
            <InputLabel shrink htmlFor='subject1'>{t("Subject")}</InputLabel>

            <Select
              sx={{ width: "100%" }}
              size="small"
              value={user.subject}
              onChange={(event) => {
                setuser({
                  ...user,
                  subject: event.target.value,
                });
              }}
              required
              input={<OutlinedInput
                notched
                label={t("Subject")}
                id="subject1"
              />}
              label={t("Subject")}
              inputProps={{
                name: "subject1",
                id: "subject1",
              }}
              labelId="subject"
            >
              <MenuItem value='إبلاغ عن مشكلة'>إبلاغ عن مشكلة</MenuItem>
              <MenuItem value='استفسار'>استفسار</MenuItem>
              <MenuItem value='طلب تواصل'>طلب تواصل</MenuItem>
            </Select>
          </FormControl>
          {errors.subject &&
            <FormHelperText component={Box}>
              <Alert severity="error" className="custom-alert">
                {errors.subject}
              </Alert>
            </FormHelperText>}
        </div>
        <div className={variant === 'two-rows' ? 'col-12 ' : 'mt-3'}>
          <FormControl
            error={Boolean(errors.message)}
            className="col-12" dir="rtl"
          >
            <TextField
              variant={'outlined'}
              multiline
              sx={{ width: "100%" }}
              InputLabelProps={{ shrink: true }}
              rows={[...MODERN_TEMPLATES,...DARK_TEMPLATES].includes(+templateId) ? 5 : 8}
              label={t("Message")}
              required
              value={user.message}
              onChange={(e) =>
                setuser({ ...user, message: resolvePersianAndArabicNumbers(e.currentTarget.value) })
              }
              error={Boolean(errors.message)}
            />
            {errors.message &&
              <FormHelperText component={Box}>
                <Alert severity="error" className="custom-alert">
                  {errors.message}
                </Alert>
              </FormHelperText>}
          </FormControl>
        </div>
        <div className={`btn-wrap text-center ${variant === 'two-rows' ? 'col-12 d-flex justify-content-end' : ''}`}>
          <LoadingButton
            type="submit"
            style={{ width: 'fit-content' }}
            classes={`primary-btn mb-3 d-flex align-items-center justify-content-center ${variant === 'two-rows' ? 'py-2' : 'btn w-100 mx-auto'}`}
            label={t("Send")}
            loading={loadingButton}
            handleClick={onSubmit}
          />
        </div>
      </div>

    </div>
  );
};

const Contact = () => {
  const [data, setData] = useState({});
  const { vendor, templateId } = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    if (vendor) {
      getWLProfile({ userName: vendor, language: "0" }).then((_userInfo) => {
        if (_userInfo.resCode === 0)
          setData(_userInfo.response.wlUser);
      });
    }
  }, [vendor]);
  
  return (
    <AppLayout withFooter pageTitle={vendor === 'amakkn' ? "تواصل معنا" : 'اتصل بنا'}>
      <PageHeader name={vendor === 'amakkn' ? "تواصل معنا" : 'اتصل بنا'} />
      <div className='contact-area pd-top-60 pd-bottom-65 '>
        <div className="col-12 contact-top">
          <h4>لا تتردد في التواصل معنا</h4>
          <p>قمّ بتعبئة النموذج ادناه و اضغط على زر ارسل</p>
        </div>
        <div className='col-12 pd-top-60'>
          {vendor === 'amakkn' ? <ContactForm variant="two-rows" /> : <ContactForm2 data={data} vendorName={vendor} variant="two-rows" />}
        </div>
        {vendor !== 'amakkn' && ![...[...MODERN_TEMPLATES,...DARK_TEMPLATES],...DARK_TEMPLATES].includes(+templateId) && <div className='row contact-info position-relative mx-0'>
          <span className="circle circle-1"></span>
          <span className="circle circle-2"></span>
          <div className="col-12" style={{ marginBottom: '90px' }}>
            <h4>معلومات التواصل</h4>
            <p>يمكنك التواصل معنا بإحدى الطرق التالية</p>
          </div>
          {data?.address?.trim() && (
            <div className='col-xl-3 col-sm-6'>
              <div className='single-contact-info' data-aos='flip-left'>
                <p>
                  <Marker />
                  {t("Address")}
                </p>
                <h6>{data?.address?.includes('@') ? data?.address?.substring(0, data?.address?.indexOf('@')) : data?.address}</h6>
              </div>
            </div>
          )}

          <div className={data?.address?.trim() ? 'col-xl-3 col-sm-6' : 'col-xl-4'}>
            <div className='single-contact-info' data-aos='flip-left'>
              <p>
                <MailOutline />
                 {t("Email")}
              </p>
              <h6><a className="inverse-link" href={`mailto:${data.email}`}>{data.email}</a></h6>
            </div>
          </div>
          <div className={data?.address?.trim() ? 'col-xl-3 col-sm-6' : 'col-xl-4'}>
            <div className='single-contact-info' data-aos='flip-left'>
              <p>
                <Tel />
                {t("PhoneNumber")}
              </p>
              <h6 dir="ltr">
                <a className="inverse-link" href={`tel:${trimPhone(data.phone)}`}>
                  {trimPhone(data.phone)}
                </a>
              </h6>
            </div>
          </div>
           
          <div className={data?.address?.trim() ? 'col-xl-3 col-sm-6' : 'col-xl-4'}>
            <div className='single-contact-info' data-aos='flip-left'>
              <p>
                <WhatsApp />
                {t("WhatsApp")}
              </p>
              <h6 dir="ltr">
                <a className="inverse-link" href={`https://wa.me/${trimPhone(data.whatsapp)}`}>
                  {trimPhone(data.whatsapp)}
                </a>
              </h6>
            </div>
          </div>
        </div>
        }
      </div>
    </AppLayout>
  );
};

export default Contact;
