import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  Box,
  FormControl,
  Autocomplete,
  debounce,
  Select,
  InputLabel,
  MenuItem,
  OutlinedInput,
  LinearProgress,
  Typography,
  FormHelperText,
  Alert,
  Pagination
} from "@mui/material";
import { toast } from "react-toastify";
import {
  getCustomers,
  uploadWlFileBase64,
  getUserProile,
  getAgentsForCompany,
  getTask,
  editTask,
} from "../../../api/userApi";
import { useEffect } from "react";
import SubHeader from "../../../components/global-components/sub-header";
import LoadingButton from "../../../components/global-components/loading-btn";
import { useAuth } from "../../../helpers/context";
import { getDemoSnakbar, resolvePersianAndArabicNumbers } from "../../../helpers";
import { DEMO_VENDORS } from "../../../constants";
import NoData from "../../../components/global-components/no-data";
import DragAndDropFileUploader from "../../../components/global-components/drag-drop-file-uploader";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Scrollbar } from "swiper";
import { Trash } from "../../../constants/icons";
import Resizer from "react-image-file-resizer";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from "moment";
import LoadingData from "../../../components/global-components/loading-data";
import { getMyProperties, getPropertyTypesForCategory } from "../../../api/propertyApi";
import { useTranslation } from "react-i18next";
import PropertyCardForAdmin from "../../shared/properties/components/property-card-for-admin";

const publicUrl = process.env.REACT_APP_URL + "/";
const pageSize = 8;

const EditTask = () => {
  const { token, vendor, UserType, UserId, AccountType } = useAuth();
  const { id } = useParams();
  const editMode = window.location.href.includes('edit');
  const [agents, setAgents] = React.useState([]);
  const [userName, setUserName] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [task, setTask] = useState({
    attachments: "",
    priority: "",
    deadline: "",
    propertyId: '',
    customer: '',
    customerId: "",
    summary: "",
    status: "",
    assignedTo: "",
    description: "",
    addedById:""
  });
  const [relatedTo, setRelatedTo] = useState('');
  const [propertyType, setPropertyType] = useState('-1');
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const history = useNavigate();
  const [loadingProperties, setLoadingProperties] = React.useState(false);
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState();
  const [customersOptions, setCustomersOptions] = React.useState([]);
  const [customerInputValue, setCustomerInputValue] = React.useState('');
  const [isUploadingAttachment, setIsUploadingAttachment] = useState(false);
  const [Attachments, setAttachments] = useState("");
  const [uploadedAttachments, setUploadedAttachments] = useState(1);
  const [totalAttachments, setTotalAttachments] = useState(0);
  const [floorPlans, setFloorPlans] = useState([]);
  const [errors, setErrors] = React.useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    getPropertyTypesForCategory({
      category: '1,2',
      language: "0",
    }).then((res) => setPropertyTypes(res.response));
  }, []);

  const uploadedAttachmentsProgress = useMemo(() => {
    if (totalAttachments === 0 || (totalAttachments > 0 && (uploadedAttachments - 1 === totalAttachments)))
      return 0;
    return uploadedAttachments * 100 / totalAttachments > 100 ? 100 : uploadedAttachments * 100 / totalAttachments;
  }, [uploadedAttachments, totalAttachments]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (token && AccountType === "2" && UserType !== "5") {
      getUserProile({ userId: token, language: "0" }).then(user => {
        if (user.resCode === 0) {
          setUserName(user.response.userProfile.name);
          setCompanyName(user.response.userProfile.companyName);
          // setTask({ ...task, assignedTo: UserId })
        }
      });
      getAgentsForCompany({ superUserId: token }).then((users) => {
        setAgents(users.response.userArray);
      });
    }
      
  },// eslint-disable-next-line
    [token, AccountType]);
  
  const fetchCustomers = React.useMemo(
    () =>
      debounce((request, callback) => {
        getCustomers({
          userId: token,
          page: 1,
          pageSize: 10000,
          search: request,
          type: '',
          interestingType: '',
          propertyType: [],
          priceFrom: '',
          area: '',
          city: '',
          neighbourhood: '',
          priceTo: '',
          owner: undefined,
          updatedAt: ''
        }).then(callback);
      }, 400),
    [token],
  );

  function formIsValid() {
    const _errors = {};
    if (!task.summary)
      _errors.summary = "الرجاء ادخال عنوان المهمّة";
    if (!task.description)
      _errors.description = "الرجاء ادخال وصف المهمّة";
      
    if (!task.status)
      _errors.status = "الرجاء اختيار حالة المهمّة";

    if (!task.assignedTo && UserType !== "5")
      _errors.assignedTo = "الرجاء اختيار الحساب"
      
    setErrors(_errors);
    return Object.keys(_errors).length === 0;
  }


  React.useEffect(() => {
    let active = true;

    // if (customerInputValue === '') {
    //   setCustomersOptions(task.buyerId ? [task.buyerId] : []);
    //   return undefined;
    // }

    fetchCustomers(customerInputValue, (res) => {
      
      if (active) {
        let newOptions = [];
        if (res.resCode === 0) {
          newOptions = [...newOptions, ...res.response.array];
          setCustomersOptions(newOptions);
        } else {
          toast.error(res.resStr);
        }
      }
    });

    return () => {
      active = false;
    };
  }, // eslint-disable-next-line
    [customerInputValue]);
  
  useEffect(
    () => {
      if (id) {
        getData();
      }
      else
        setTask({
          attachments: "",
          priority: '0',
          deadline: "",
          propertyId: '',
          customer: '',
          customerId: "",
          summary: "",
          status: "0",
          assignedTo: "",
          addedById:"",
          description: ""
        });
    }, // eslint-disable-next-line
    [id, vendor]
  );

  const getData = () => {
    setUploadedAttachments(1);
    getTask({
      userId: token,
      language: "0",
      taskId: id
    }).then((res) => {
      if (res.resCode === 0) {
        setTask({
          ...res.response,
          assignedTo: res.response.assignedToId
        });
        setAttachments(res.response.attachments);
        setRelatedTo(res.response.propertyId ? 'property' : 'customer')
        setSelectedProperty(res.response.propertyId ? +res.response.propertyId : undefined);
        setPropertyType(res.response.propertyObject ? res.response.propertyObject?.propertyType : '-1')
      } else {
        toast.error(res.resStr);
      }
    });
  }

  function handleDeleteAttachment(target) {
    if (DEMO_VENDORS.includes(vendor)) {
      setFloorPlans((old) => [...old?.filter(i => i !== target?.currentTarget?.title)]);
    } else {
      let photos = "";
      let deleted = target.currentTarget.title;
      const photosArr = task.attachments.split(",");
      const index = photosArr.indexOf(deleted);
      if (index > -1) {
        photosArr.splice(index, 1);
      }
      photosArr.forEach((element) => {
        photos = photos + element + ",";
      });
      if (photos.length > 1) photos = photos.slice(0, -1);
      if (photos.length < 2) photos = "-1";
  
      editTask({
        ...task,
        userId: token,
        language: '0',
        taskId: id,
        attachments: photos,
      })
        .then((res) => {
          if (res.resCode === 0) {
            getData();
          } else {
            toast.error(res.resStr);
          }
        });
    }
  }

  const handleSave = () => {
    if (!formIsValid()) return;
    if (DEMO_VENDORS.includes(vendor)) {
      setOpenDemoSnackBar(true);
    } else {
      setLoadingButton(true);
      editTask({
        ...task,
        language: "0",
        userId: token,
        taskId: id,
        attachments: Attachments,
        deadline: task.deadline ? moment(task.deadline).locale('en').format('YYYY-MM-DD') : '',
      }).then(res => {
        if (res.resCode === 0) {
          toast.success(!editMode ? 'تمّت إضافة المهمّة بنجاح' : 'تمّ تعديل المهمّة بنجاح');
          history('/admin/tasks');
        } else {
          toast.error(res.resStr);
        }
      }).finally(() => setLoadingButton(false));
    }
  }

  const onDropAttachment = (files) => {
    dropFiles(files.length > 5 ? files.slice(0, 5) : files);
  };
  
  const resizeFile = (file, imgHeights, imgWidth, source, fileName, attachments1) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        imgHeights,
        imgWidth,
        "JPEG",
        100,
        0,
        async (uri) => {
          uploadWlFileBase64({
            pathWithFileName: "/Tasks/" + id + `/Attachments/` + Date.now() + file?.name?.substring(file?.name?.lastIndexOf('.')),
            base64Data: uri,
            typeOfFile: source,
            userId: token,
            language: "0",
          }).then(resp => {
            if (resp.resCode === 0) {
              attachments1.push(resp.response.url);
              setAttachments(attachments1.join(','));
              setUploadedAttachments((val) => val + 1);
            } else {
              setIsUploadingAttachment(false);
              toast.error(resp.resStr);
            }
          }).catch(e => {
            setIsUploadingAttachment(false);
            setTotalAttachments(0);
          });
        },
        "base64"
      );
    });
  
  useEffect(() => {
    if (totalAttachments > 0 && uploadedAttachments - 1 === totalAttachments) {
      if (!DEMO_VENDORS.includes(vendor)) {
        editTask({
          ...task,
          userId: token,
          language: '0',
          attachments: Attachments,
          taskId: id
        })
          .then((res) => {
            if (res.resCode === 0) {
              getData();
              setTotalAttachments(0);
              setIsUploadingAttachment("");
            } else {
              setIsUploadingAttachment(false);
              toast.error(res.resStr);
            }
          });
      } else {
        setTotalAttachments(0);
        setIsUploadingAttachment("");
        setUploadedAttachments(1);
      }
      
    }
  }, // eslint-disable-next-line
    [uploadedAttachments]);

  async function dropFiles(files) {
    let newAttachments = Attachments !== '' ? Attachments?.split(',') : [];
    const forLoop = async _ => {
      for (let imag of files) {
        let imgHeights = 100;
        let imgWidth = 100;
        let maxWidth = 1920;
        let maxHeight = 1080;
  
        let reader = new FileReader();
        reader.readAsDataURL(imag);
      
        setIsUploadingAttachment(true);

        if (imag.type !== 'application/pdf') {
          reader.onloadend = async () => {
         
            let img = document.createElement("img");
            img.src = reader.result;
            img.onload = async () => {
              imgHeights = img.height;
              imgWidth = img.width;
    
              let imgRatio = imgWidth / imgHeights;
              let maxRatio = maxWidth / maxHeight;
                 
              if (imgHeights > maxHeight || imgWidth > maxWidth) {
                if (imgRatio < maxRatio) {
                  imgRatio = maxHeight / imgHeights;
                  imgWidth = imgRatio * imgWidth;
                  imgHeights = maxHeight;
                } else if (imgRatio > maxRatio) {
                  imgRatio = maxWidth / imgWidth;
                  imgHeights = imgRatio * imgHeights;
                  imgWidth = maxWidth;
                } else {
                  imgHeights = maxHeight;
                  imgWidth = maxWidth;
                }
              }
    
              var mimeString = img.src
                .split(",")[0]
                .split(":")[1]
                .split(";")[0];
    
              var binary = atob(img.src.split(",")[1]);
              var array = [];
              for (var i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
              }
              var blobImage = new Blob([new Uint8Array(array)], {
                type: mimeString,
              });
              var uploadFile = new File([blobImage], imag.name);
    
              await resizeFile(
                uploadFile,
                imgHeights,
                imgWidth,
                mimeString,
                files[files.length - 1].name,
                newAttachments
              );
            };
            
          };
        } else {
          reader.onload = async () => {
            let fileInfo = {
              pathWithFileName: "/Tasks/" + id + `/Attachments/` + Date.now() + imag?.name?.substring(imag?.name?.lastIndexOf('.')),
              base64Data: reader.result,
              typeOfFile: imag.type !== 'application/pdf' ? imag.type : 'pdf',
              userId: token,
              language: "0",
            };
            if (DEMO_VENDORS.includes(vendor)) {
              newAttachments.push(fileInfo.base64Data);
              setUploadedAttachments((val) => val + 1);
              setFloorPlans((old) => [...old, fileInfo.base64Data]);
            } else {
              uploadWlFileBase64(fileInfo).then(resp => {
                if (resp.resCode === 0) {
                  newAttachments.push(resp.response.url);
                  setUploadedAttachments((val) => val + 1);
                  setAttachments(newAttachments.join(','));
                } else {
                  setIsUploadingAttachment(false);
                  toast.error(resp.resStr);
                }
              }).catch(e => {
                setIsUploadingAttachment(false);
              });
            }
           
          }
        }
      }
    }

    if (newAttachments.length + files.length > 5) {
      toast.error('لا يمكنك رفع أكثر من 5 ملفات');
      return;
    }
    setTotalAttachments(files.length);
    await forLoop();
  }
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (relatedTo === "property") {
      setLoadingProperties(true);
      getMyProperties({
        userId: token,
        page: page,
        status: "1",
        pageSize: pageSize,
        isAssigned: "na",
        language: "0",
        propertyType: propertyType === '-1' ? "" : propertyType
      })
        .then((property) => {
          if (property.resCode === 0) {
            setProperties(property.response.propertyArray);
            setLoadingProperties(false);
            setTotalCount(property.response.totalCount);
          } else {
            toast.error(property.resStr);
            setLoadingProperties(false);
          }
        });
    }
  }, [relatedTo, token, page, propertyType]);

  return (
    <div>
      <SubHeader
        withSteps={!editMode}
        steps={["البيانات الأساسيّة", "البيانات الإضافيّة"]}
        OKElement={<></>}
        completedSteps={[0]}
        currentStep={1}
      />

      {editMode && task.addedById === UserId && (<>
        <div className='col-12 custom-card' style={{ minHeight: 'auto' }}>
          <div className='card-header'>
            <h4>البيانات الأساسيّة</h4>
            <h6>يتوجب عليك ادخال عنوان و وصف للمهمة و اسنادها إليك أو لأحد حساباتك الفرعيّة</h6>
          </div>
          <div className='card-body' dir="rtl">
            <div className="row">
            
              <div className="col-12 col-md-6 mb-4 mb-lg-3 col-lg-4">
                <FormControl
                  error={Boolean(errors.summary)}
                  className="col-12" dir="rtl"
                >
                  <TextField
                    label='عنوان المهمّة'
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required
                    value={task.summary}
                    size='small'
                    disabled={UserType === '5' && task.assignedTo !== UserId}
                    onChange={(v) => {
                      setTask({
                        ...task,
                        summary: resolvePersianAndArabicNumbers(v.currentTarget.value)
                      });
                    }}
                    error={Boolean(errors.summary)}
                  />
                  {errors.summary &&
                    <FormHelperText component={Box}>
                      <Alert severity="error" className="custom-alert">
                        {errors.summary}
                      </Alert>
                    </FormHelperText>}
                </FormControl>
              </div>

              <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
                <FormControl
                  error={Boolean(errors.status)}
                  className="custom-select" dir="rtl">
                  <InputLabel htmlFor='type' required>حالة المهمّة</InputLabel>
                  <Select
                    value={task.status}
                    required
                    sx={{ width: "100%", direction: 'rtl', textAlign: 'right' }}
                    error={Boolean(errors.status)}
                    onChange={(event) => {
                      setTask({
                        ...task,
                        status: event.target.value,
                      });
                    }}
                    input={<OutlinedInput
                      notched
                      label='حالة المهمّة'
                      id="type"
                    />}
                    label='حالة المهمّة'
                    inputProps={{
                      name: "type",
                      id: "type",
                    }}>
                    <MenuItem value='0'>{t("New")}</MenuItem>
                    <MenuItem value='1'>{t("InProgress")}</MenuItem>
                    <MenuItem value='2'>{t("Postponed")}</MenuItem>
                    <MenuItem value='3'>{t("Done")}</MenuItem>
                  </Select>
                  {errors.status &&
                    <FormHelperText component={Box}>
                      <Alert severity="error" className="custom-alert">
                        {errors.status}
                      </Alert>
                    </FormHelperText>}
                </FormControl>
              </div>

              <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
                <FormControl
                  error={Boolean(errors.assignedTo)}
                  className="custom-select" dir="rtl">
                  <InputLabel htmlFor='type' required>{t("AssignedTo")}</InputLabel>
                  <Select
                    value={task.assignedTo}
                    required
                    sx={{ width: "100%", direction: 'rtl', textAlign: 'right' }}
                    error={Boolean(errors.assignedTo)}
                    onChange={(event) => {
                      setTask({
                        ...task,
                        assignedTo: event.target.value,
                      });
                    }}
                    input={<OutlinedInput
                      notched
                      label={t("AssignedTo")}
                      id="type"
                    />}
                    disabled={UserType === '5' && task.assignedTo !== UserId}
                    label={t("AssignedTo")}
                    inputProps={{
                      name: "type",
                      id: "type",
                    }}>
                    <MenuItem value={UserId} key="mother-account">{userName === companyName ? t("MainAccount") : userName}</MenuItem>
                    {agents?.map(agent => <MenuItem value={agent.userId} key={agent.userId}>{agent.name}</MenuItem>)}
                  </Select>
                  {errors.assignedTo &&
                    <FormHelperText component={Box}>
                      <Alert severity="error" className="custom-alert">
                        {errors.assignedTo}
                      </Alert>
                    </FormHelperText>}
                </FormControl>
              </div>
         
              <div className="col-12 mb-4 mb-lg-3">
                <FormControl
                  error={Boolean(errors.description)}
                  className="col-12" dir="rtl"
                >
                  <TextField
                    label='وصف المهمّة'
                    sx={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={UserType === '5' && task.assignedTo !== UserId}
                    required
                    inputProps={{ maxLength: 500 }}
                    value={task.description}
                    size='small'
                    multiline
                    rows={6}
                    error={Boolean(errors.description)}
                    onChange={(v) => {
                      setTask({
                        ...task, description: resolvePersianAndArabicNumbers(
                          v.currentTarget.value?.length >= 500 ? v.currentTarget.value?.substring(0, 500) : v.currentTarget.value
                        )
                      });
                    }}
                  />
                  <h6 className='rightalign mt-3'>
                    {task.description
                      ? parseInt(task.description.length) + "/500"
                      : "0/500"}
                  </h6>
                  {errors.description &&
                    <FormHelperText component={Box}>
                      <Alert severity="error" className="custom-alert">
                        {errors.description}
                      </Alert>
                    </FormHelperText>}
                </FormControl>
              </div>

            </div>
          </div>
        </div>
      </>)}
      <div className='col-12 custom-card' style={{ minHeight: 'auto' }}>
        <div className='card-header'>
          <h4>البيانات الإضافيّة (اختياري)</h4>
          <h6>يمكنك تحديد موعد نهائي لإنجاز المهمّة بالإضافة لتحديد أولويتها</h6>
        </div>
        <div className='card-body' dir="rtl">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
              <LocalizationProvider dateAdapter={AdapterMoment} localeText={{
                okButtonLabel: 'تم',
                cancelButtonLabel: t("Cancel"),
                datePickerToolbarTitle: t("ChooseDeadlineForTask")
              }}>
                <FormControl className="w-100" dir="rtl">
                  <MobileDatePicker
                    label={t("Deadline")}
                    inputFormat="DD/MM/YYYY"
                    slotProps={{
                      textField: {
                        size: "small",
                        InputLabelProps: { shrink: true }
                      },
                    }}
                    disablePast
                    value={task?.deadline ? moment(task.deadline) : undefined}
                    onChange={(val) => setTask({ ...task, deadline: val })}
                  />
                </FormControl>
              </LocalizationProvider>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
              <FormControl className="custom-select" dir="rtl">
                <InputLabel htmlFor='type'>أولويّة المهمّة</InputLabel>
                <Select
                  value={task.priority}
                  sx={{ width: "100%", direction: 'rtl', textAlign: 'right' }}
                  onChange={(event) => {
                    setTask({
                      ...task,
                      priority: event.target.value,
                    });
                  }}
                  input={<OutlinedInput
                    notched
                    label='أولويّة المهمّة'
                    id="type"
                  />}
                  label='أولويّة المهمّة'
                  inputProps={{
                    name: "type",
                    id: "type",
                  }}>
                  <MenuItem value='0'>{t("Low")}</MenuItem>
                  <MenuItem value='1'>{t("Medium")}</MenuItem>
                  <MenuItem value='2'>{t("High")}</MenuItem>
                </Select>
              </FormControl>
            </div>
          
          </div>
          
        </div>
      </div>

      
      <div className='col-12 custom-card' style={{ minHeight: 'auto' }}>
        <div className='card-header'>
          <h4>تحديد العميل أو الإعلان العقاري المرتبط بالمهمّة (اختياري)</h4>
          <h6>يمكنك ربط المهمّة بعميل أو إعلان عقاري محدد</h6>
        </div>
        <div className='card-body' dir="rtl">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
              <FormControl
                className="custom-select" dir="rtl">
                <InputLabel htmlFor='relatedTo' required>{t("RelatedTo")}</InputLabel>
                <Select
                  value={relatedTo}
                  required
                  sx={{ width: "100%", direction: 'rtl', textAlign: 'right' }}
                  onChange={(event) => {
                    setRelatedTo(event.target.value);
                    setTask({
                      ...task,
                      propertyId: '',
                      customer: '',
                      customerId: ''
                    });
                    setSelectedProperty();
                  }}
                  input={<OutlinedInput
                    notched
                    label={t("RelatedTo")}
                    id="type"
                  />}
                  label={t("RelatedTo")}
                  inputProps={{
                    name: "relatedTo",
                    id: "relatedTo",
                  }}>
                  <MenuItem value='customer'>عميل</MenuItem>
                  <MenuItem value='property'>إعلان عقاري</MenuItem>
                </Select>
              </FormControl>
            </div>
            {relatedTo === "property" && (
              <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
                <FormControl
                  className="custom-select" dir="rtl">
                  <InputLabel htmlFor='propertyType' required>{t("PropertyType")}</InputLabel>
                  <Select
                    value={propertyType}
                    required
                    sx={{ width: "100%", direction: 'rtl', textAlign: 'right' }}
                    onChange={(event) => {
                      setPropertyType(event.target.value);
                    }}
                    input={<OutlinedInput
                      notched
                      label={t("PropertyType")}
                      id="type"
                    />}
                    label={t("PropertyType")}
                    inputProps={{
                      name: "propertyType",
                      id: "propertyType",
                    }}>
                    <MenuItem value={'-1'} key={'-1'}>{t("All")}</MenuItem>
                    {propertyTypes.map((item) => <MenuItem value={item.key} key={item.key}>{item.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </div>
            )}
            
            {relatedTo === "customer" && (
              <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-3">
                <FormControl sx={{ width: "100%" }}>
                 
                  <Autocomplete
                    size="small"
                    getOptionLabel={(option) =>
                      typeof option === 'string' ? option : option.name
                    }
                    filterOptions={(x) => x}
                    options={customersOptions}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={task.customer}
                    noOptionsText="لم يتم العثور على عملاء"
                    onChange={(event, newValue) => {
                      // setBuyerOptions(newValue ? [newValue, ...buyerOptions] : buyerOptions);
                      setTask({ ...task, customer: newValue, customerId: newValue?.id });
                    }}
                    onInputChange={(event, newInputValue) => {
                      setCustomerInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="العميل" fullWidth InputLabelProps={{
                        shrink: true
                      }} />
                    )}
                    renderOption={(props, option) => {
                      return (
                        <li {...props}>
                          {option.name}
                        </li>
                      );
                    }}
                  />
                   
                </FormControl>
              </div>
            )}

            {relatedTo === "property" && (
              <div className="col-12 mb-4 mb-lg-3 row mx-0">
                <div className="col-12 ">
                  <h4 className="mb-3 text-right">تحديد الإعلان العقاري</h4>
                </div>
                {loadingProperties ? <LoadingData /> : properties?.length > 0 ? properties.map((item, i) => (
                  <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-3" key={i} dir="ltr">
                    <PropertyCardForAdmin
                      item={item}
                      hideActions
                      disableLink
                      customSelect
                      selectedItem={selectedProperty}
                      setSelectedItem={setSelectedProperty}
                      onOK={() => {
                        setSelectedProperty(+item.propertyId);
                        setTask({
                          ...task,
                          propertyId: +item.propertyId
                        });
                      }}
                    />
                  </div>
                )) : <NoData msg='عذراً, لا توجد إعلانات عقاريّة منشورة من قبلك' />}
                <div className="col-12">
                </div>
                {totalCount > pageSize && (
                  <Pagination
                    sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '15px', justifyContent: 'end' } }}
                    count={parseInt(Math.ceil(totalCount / pageSize))}
                    page={page}
                    onChange={handlePageChange}
                    color="primary" />
                )}
              </div>
            )}
          </div>
         
        </div>
      </div>
      

      <div className='col-12 custom-card' style={{ minHeight: 'auto' }}>
        <div className='card-header'>
          <h4>المرفقات (اختياري)</h4>
          <h6>يمكنك إضافة العديد من المرفقات</h6>
        </div>
        <div className='card-body' dir="rtl">
          <DragAndDropFileUploader
            OnOK={onDropAttachment}
            singleFile={false}
            id="property-plans"
            uploading={isUploadingAttachment}
            extra={'يمكنك رفع 5 مرفقات كحد أقصى'}
          />
          {isUploadingAttachment && (
            <Box sx={{ width: '100%', marginTop: '30px', position: 'relative', display: 'inline-flex' }}>
              <LinearProgress variant="determinate" value={uploadedAttachmentsProgress} sx={{ width: '100%', height: '20px' }} />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="caption" component="div" sx={{ fontWeight: 700, color: '#fff' }}>
                  {`${Math.round(uploadedAttachmentsProgress)}%`}
                </Typography>
              </Box>
            </Box>
          )}

          {((DEMO_VENDORS.includes(vendor) && floorPlans?.length > 0) || (!DEMO_VENDORS.includes(vendor) && task?.attachments && task?.attachments?.split(",")?.length > 0)) && (
            <div className='uploaded-images-wrapper'>
          
              <h4>المخططات التي تم تحميلها ({DEMO_VENDORS.includes(vendor) ? floorPlans?.length : task?.attachments && task.attachments.split(",").length})</h4>
              <Swiper
                spaceBetween={32}
                slidesPerView={5}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  450: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  680: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1000: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1250: {
                    slidesPerView: 5,
                    spaceBetween: 20,
                  },
                }}
                scrollbar={{ draggable: true, el: '.swiper-scrollbar2' }}
                modules={[Scrollbar, Keyboard]}
                keyboard={{ enabled: true }}
                speed={2600}
              >
                  
                {(DEMO_VENDORS.includes(vendor) ? floorPlans : task?.attachments && task?.attachments?.split(","))?.map((image, index) => (
                  <SwiperSlide key={index} className='image-item'>
                    <span
                      className='del-icon'
                      title={image} onClick={handleDeleteAttachment} ><Trash /></span>
                    {image.includes('.pdf') || image?.startsWith('data:application/pdf') ?
                      <img
                        src={`${publicUrl}assets/img/icons/Icon_pdf_file.png`}
                        alt={`floor-img`}
                        loading='lazy'
                        className="model-img"
                        style={{ objectFit: 'contain' }}
                      />
                      :
                      <img src={image} alt='' />

                    }
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="swiper-scrollbar2"></div>
            </div>
                
          )}
         
        </div>
      </div>
  
      {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

      <div className="text-center pb-5 mb-5">
        <LoadingButton
          style={{ minWidth: "250px", justifyContent: "center" }}
          label={t("Save")}
          classes='primary-btn px-5 mx-auto mb-5'
          handleClick={handleSave}
          loading={loadingButton}
        />
      </div>
    </div>
  );
};

export default EditTask;
