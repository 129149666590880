import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react'
import ImageIcon from '@mui/icons-material/Image';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CollectionsIcon from '@mui/icons-material/Collections';
import BorderHorizontalIcon from '@mui/icons-material/BorderHorizontal';
import { useTranslation } from 'react-i18next';

const AddElementsModal = ({ openAddNewElementModal, setOpenAddNewElementModal }) => {
    const { t } = useTranslation();

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"md"}
            open={openAddNewElementModal.visible}
            className="custom-dialog model"
            style={{ direction: "rtl", textAlignLast: "right" }}
            onClose={() => setOpenAddNewElementModal({ ...openAddNewElementModal, visible: false })}>
            <DialogTitle>
                <div className="dialog-head">
                    <div>
                        <h2>{t("AddANewElement")}</h2>
                    </div>
                    <div className="dialog-actions">
                        <span onClick={() => setOpenAddNewElementModal({ ...openAddNewElementModal, visible: false })} style={{ cursor: 'pointer' }}>
                            <Close />
                        </span>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className='row justify-content-start p-0'>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 15, width: '100%', minHeight: '300px', flexWrap: 'wrap' }}>
                        <div className='element-card' onClick={() => setOpenAddNewElementModal({ visible: false, elements: [...openAddNewElementModal.elements, { id: openAddNewElementModal.elements.length + 1, type: 'text', value: undefined }] })}>
                            <TextSnippetIcon sx={{ color: 'var(--main-color-one)', fontSize: 60 }} />
                            <span>{t("Text")}</span>
                        </div>
                        <div className='element-card' onClick={() => setOpenAddNewElementModal({ visible: false, elements: [...openAddNewElementModal.elements, { id: openAddNewElementModal.elements.length + 1, type: 'image', value: undefined }] })}>
                            <ImageIcon sx={{ color: 'var(--main-color-one)', fontSize: 60 }} />
                            <span>{t("Image1")}</span>
                        </div>
                        <div className='element-card' onClick={() => setOpenAddNewElementModal({ visible: false, elements: [...openAddNewElementModal.elements, { id: openAddNewElementModal.elements.length + 1, type: 'gallery', value: '' }] })}>
                            <CollectionsIcon sx={{ color: 'var(--main-color-one)', fontSize: 60 }} />
                            <span>{t("Gallery")}</span>
                        </div>
                        <div className='element-card' onClick={() => setOpenAddNewElementModal({ visible: false, elements: [...openAddNewElementModal.elements, { id: openAddNewElementModal.elements.length + 1, type: 'line', value: '' }] })}>
                            <BorderHorizontalIcon sx={{ color: 'var(--main-color-one)', fontSize: 60 }} />
                            <span>{t("BreakLine")}</span>
                        </div>
                        
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default AddElementsModal;
