import React from 'react';
import { Link } from "react-router-dom";

const Forbidden = ({ btnLink, btnText, desc, withTitle }) => {
    return (
        <div className='error-page version2'>
            {withTitle && <h2>404</h2>}
            <p>{desc}</p>
            {btnText && <Link
                to={btnLink}
                className='btn primary-btn'
            >
                {btnText}
            </Link>}
            <img src="/assets/img/cloud.svg" alt="cloud" className="cloud cloud-1" />
            <img src="/assets/img/cloud.svg" alt="cloud" className="cloud cloud-2" />
            <img src="/assets/img/cloud.svg" alt="cloud" className="cloud cloud-3" />
            <img src="/assets/img/cloud.svg" alt="cloud" className="cloud cloud-4" />
            <img src="/assets/img/404.svg" alt="404" className="image-404" />
        </div>
    );
}

export default Forbidden