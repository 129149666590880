import React from "react";
import ChangeAgentPhone from "../../amakkn/agents/change-agent-phone";


function WLChangeAgentPhone() {
 
  return (<ChangeAgentPhone />);
}

export default WLChangeAgentPhone;
