import React from "react";
import Navbar from "../../global-components/navbar";

const CRMAllData = () => {
  return (
    <div>
      <Navbar />
      <div className='about-area pd-top-100 pd-bottom-90'>
        <div className='container pd-top-50'>
          <div className='row'>
            <div className='col-lg-6 mb-4 mb-lg-0'>All Data</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CRMAllData;
