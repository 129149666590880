import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getFavouritesForUser } from "../../api/propertyApi";
import LoadingData from "../../components/global-components/loading-data";
import NoData from "../../components/global-components/no-data";
import AppLayout from "../../components/layouts/app-layout";
import PropertyBox from "../../components/section-components/property-box";
import { Check } from "../../constants/icons";
import { useAuth } from "../../helpers/context";

const ComparisonList = () => {
  const { token } = useAuth();
  const [properties, setProperties] = useState([]);
  const [settings, setSettings] = useState({
    userId: token,
    page: "1",
    pageSize: "50",
    language: "0",
    totalCount: "0",
  });

  const [totalCount, setTotalCount] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedProperties, setSelectedProperities] = useState([]);

  function onChange() {
    setSettings({ ...settings, totalCount: "50" });
  }

  useEffect(() => {
    setLoadingData(true);
    getFavouritesForUser(settings)
      .then((property) => {
        if (property.resCode === 0) {
          setProperties(property.response.propertyArray);
          setTotalCount(property.response.totalCount);
        } else {
          toast.error(property.resStr);
        }
        setLoadingData(false);
      })
      .catch((error) => {
        setLoadingData(false);
        throw error;
      });
  }, [settings]);
    

  const addPropertyToComperison = (property) => {
   
      let check = selectedProperties.filter(item => item.propertyId === property.idToShare)?.length > 0;
    if (check) {
      let temp = selectedProperties.filter(item => item.propertyId !== property.idToShare);
      setSelectedProperities(temp);
    } else if (!check && selectedProperties.length < 3) {
      let Matchedtype = selectedProperties.filter(item => item.propertyType === property.propertyType)?.length > 0;
      if (Matchedtype || selectedProperties.length === 0)
        setSelectedProperities([...selectedProperties, { propertyId: property.idToShare, propertyType: property.propertyType }]);
      else
        toast.error('يجب أن تكون الإعلانات العقاريّة من نفس النوع');
    } else {
      toast.error('يمكنك إضافة 3 إعلانات عقاريّة على الأكثر');
    }
    
  };

  return (
    <AppLayout needAuth pageTitle="المقارنة">
          {token && (
                <div className='page-width favorite-area pd-top-90 mg-bottom-100 text-center comparison-list'>
          {loadingData ?
            <div className="pt-5">
              <LoadingData />
            </div>
            :
            <>
              <Box
                sx={{
                  marginBottom: "40px",
                  textAlign: "center",
                  fontSize: "28px",
                  fontWeight: 600,
                  color: "#000",
                }}>
                صفحة المقارنة
                {totalCount > 1 && (
                  <Box
                    sx={{
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#000",
                    }}>
                        
                        يوجد {totalCount} إعلانات عقاريّة يمكنك المقارنة بينها, ماعليك سوى تحديد إعلانين أو ثلاثة إعلانات لبدء عملية المقارنة
                  </Box>
                )}
                     
              </Box>
                      
              <div className='row align-items-center justify-content-end pd-top-30'>
                {properties.length > 0 ? properties.map((item, i) => (
                  <div
                    key={i}
                    // data-aos='flip-left'
                    onClick={() => addPropertyToComperison(item)}
                    className={`selected-property col-xl-3 col-lg-4 col-md-6 col-sm-6 ${selectedProperties.filter(i => i.propertyId === item.idToShare)?.length > 0 ? 'active' : ''}`}
                    style={{ cursor: "pointer" }}>
                    <PropertyBox
                      propertey={item}
                      isEdit={false}
                      userId={token}
                      disableLink
                      onChange={onChange}
                    />
                    <div className="selected">
                      <Check />
                    </div>
                  </div>
                )) : <NoData msg='قم بإضافة إعلانات عقاريّة للمفضلة لتظهر في صفحة المقارنة'  />}
              </div>
                
              {selectedProperties.length >= 2 && (
                <Link to="/comparison"
                  state={{ property1: selectedProperties[0]?.propertyId, property2: selectedProperties[1]?.propertyId, property3: selectedProperties[2]?.propertyId  }}
                  className="primary-btn py-2 px-4" style={{
                  position: 'fixed',
                  bottom: '20px',
                  left: '50%',
                  transform: 'translateX(-50%)'
                }}>
                  بدء المقارنة {selectedProperties.length ===2 ?"للإعلانين":"للإعلانات الثلاث"}
                </Link>
              )}
              
            </>}
              </div>
      )}
    </AppLayout>
  );
};

export default ComparisonList;
