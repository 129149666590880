
import React, { useState } from 'react';
import { Grid, Card, Box, Container, CardHeader, CardContent, Tooltip } from '@mui/material';
import LoadingButton from './global-components/loading-btn';
import LoginPopup from './user-components/login-popup';
import { useAuth } from '../helpers/context';
import VerifyPopup from './user-components/verify-popup';
import { plansAvailabilty } from '../constants';
import { GreenCheck, RedClose } from '../constants/icons';
import ChangeTypePopup from './user-components/change-type-popup';
import { useSearchParams } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';

export default function OnBoardingPlansCards({ normalPlans, data, currentPlan, isUserVerified, onClick }) {
    const { token, setToken, setUserId, AccountType } = useAuth();
    const [openVerifyModal, setOpenVerifyModal] = useState(false);
    const [openChangeTypeModal, setOpenChangeTypeModal] = useState(false);
    const [searchParams] = useSearchParams();

    return data && data.length > 0 ? (
        <section>
            <Container maxWidth="lg">
                <Box py={3} textAlign="center">
                    <Grid container spacing={3} sx={{
                        border: 'none',
                        padding: '50px 4px 20px',
                        width: '100%',
                        borderRadius: '12px',
                        justifyContent: 'center',
                    }}>
                        {data?.filter(d => plansAvailabilty['2'].includes(d.id))?.map(item =>
                            (!token || (token && (([9, 10].includes(+currentPlan?.planId) && +item.id === 6) ||
                                ([11, 12].includes(+currentPlan?.planId) && +item === 8) ||
                                ([1, 2, 3, 4, 5, 6, 7, 8].includes(+currentPlan?.planId)))))
                            && item.duration === '30' && (
                                <Grid item xs={12} md={4} key={item.id} style={{ padding: '0 6px 15px' }} data-aos="fade-up" data-aos-duration="1500">
                                    <Card className='pricing-card'>
                                        <span className='circle circle-1'></span>
                                        <span className='circle circle-2'></span>
                                        <CardHeader className='pricing-card-header' title={item.name}></CardHeader>
                                        <CardContent sx={{ minHeight: 300 }}>
                                            <Box px={1}>
                                                <p className="pricing-card-feature">
                                                    <span className='icon'><GreenCheck green /></span>
                                                    <span> إمكانيّة إضافة حتّى{" " + normalPlans?.[+item.id === 6 ? 8 : 10]?.allowedPublishedProperties + " "}إعلان</span>
                                                    <span>
                                                        <Tooltip title={<div style={{ direction: 'rtl', textAlign: 'right' }}>يمكنك إضافة عدد غير منتهي من الإعلانات العقاريّة ولكن يمكنك نشر {" " + normalPlans?.[+item.id === 6 ? 8 : 10]?.allowedPublishedProperties + " "} إعلان فقط</div>}>
                                                            <InfoIcon sx={{ cursor: 'pointer', position: 'relative', top: '2px', fontSize: '16px', color: 'var(--main-color-one)' }} />
                                                        </Tooltip>
                                                    </span>
                                                </p>
                                                <p className="pricing-card-feature">
                                                    <span className='icon'>{normalPlans?.[+item.id === 6 ? 8 : 10]?.allowedAgents !== '0' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>{normalPlans?.[+item.id === 6 ? 8 : 10]?.allowedAgents !== '0' ? `إمكانيّة إضافة حتّى ${normalPlans?.[+item.id === 6 ? 8 : 10]?.allowedAgents} حسابات فرعيّة ` : 'إمكانيّة إضافة حسابات فرعيّة'} </span>
                                                </p>
                                                <p className="pricing-card-feature">
                                                    <span className='icon'>{normalPlans?.[+item.id === 6 ? 8 : 10]?.hasSupportPriority === '1' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>اولويّة دعم فنّي</span>
                                                </p>
                                                <p className="pricing-card-feature">
                                                    <span className='icon'>{normalPlans?.[+item.id === 6 ? 8 : 10]?.canAddLogo === '1' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>إمكانيّة إضافة شعار المكتب على صور الإعلانات</span>
                                                </p>
                                                <p className="pricing-card-feature">
                                                    <span className='icon'>{normalPlans?.[+item.id === 6 ? 8 : 10]?.canAddNumber === '1' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span> إمكانيّة إضافة رقم الاتصال الموحد 9200 أو 8200</span>
                                                </p>
                                                <p className="pricing-card-feature">
                                                    <span className='icon'>{normalPlans?.[+item.id === 6 ? 8 : 10]?.canAddOnMap === '1' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>إمكانيّة إضافة الموقع على الخارطة في صفحة الشركة</span>
                                                </p>
                                                <p className="pricing-card-feature">
                                                    <span className='icon'>{normalPlans?.[+item.id === 6 ? 8 : 10]?.canAddVideo === '1' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>إمكانية إضافة فيديوهات للإعلان العقاري</span>
                                                </p>
                                                <p className="pricing-card-feature">
                                                    <span className='icon'>{normalPlans?.[+item.id === 6 ? 8 : 10]?.canAddExtraAds === '1' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>إمكانية إضافة إعلانات إضافية مقابل 20 ريال للإعلان</span>
                                                </p>
                                                <p className="pricing-card-feature">
                                                    <span className='icon'>{normalPlans?.[+item.id === 6 ? 8 : 10]?.canExportAdPhoto === '1' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>صورة قابلة للطباعة والمشاركة لتفاصيل الإعلان العقاري</span>
                                                </p>
                                                <p className="pricing-card-feature">
                                                    <span className='icon'>{normalPlans?.[+item.id === 6 ? 8 : 10]?.permanentUpgradeToNumberOfAds > '0' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>
                                                        {+normalPlans?.[+item.id === 6 ? 8 : 10]?.permanentUpgradeToNumberOfAds > 0 ? (
                                                            `إمكانية ترقية ${+normalPlans?.[+item.id === 6 ? 8 : 10]?.permanentUpgradeToNumberOfAds > 2 ? `${normalPlans?.[+item.id === 6 ? 8 : 10]?.permanentUpgradeToNumberOfAds} إعلانات عقاريّة` : "إعلان عقاري واحد فقط"}`
                                                        ) : "إمكانية ترقية الإعلانات العقاريّة"
                                                        }
                                                    </span>
                                                </p>
                                                <hr />
                                                <p>خدمة SaaS</p>
               
                                                {item?.features?.map((item, i) => <p key={i} className="pricing-card-feature">
                                                    <span className='icon'>{item.available === '1' ? <GreenCheck green /> : <RedClose />}</span>
                                                    <span>{item.name}</span>
                                                </p>)}
                                            </Box>
                                        </CardContent>
                                        <Box px={1}>
                                            {token ? isUserVerified !== '3' ? (
                                                <VerifyPopup
                                                    openVerifyModal={openVerifyModal}
                                                    setOpenVerifyModal={setOpenVerifyModal}
                                                    desc={'يجب عليك توثيق حسابك لكي تتمكن من المتابعة '}
                                                    trigger={
                                                        <LoadingButton
                                                            loading={false}
                                                            classes="mb-4 py-2 primary-btn w-100 d-flex justify-content-center small-raduis"
                                                            handleClick={() => setOpenVerifyModal(true)}
                                                            label={[9, 10, 11, 12].includes(+currentPlan?.planId) ? 'ترقية الخطة' : 'جربه مجانأ'}
                                                        />
                                                    }
                                                />
                                            ) : AccountType === '1' ? (
                                                <ChangeTypePopup
                                                    openChangeTypeModal={openChangeTypeModal}
                                                    setOpenChangeTypeModal={setOpenChangeTypeModal}
                                                    desc={'يجب عليك تغيير نوع حسابك إلى حساب أعمال لكي تتمكن من المتابعة '}
                                                    trigger={
                                                        <LoadingButton
                                                            loading={false}
                                                            classes="mb-4 py-2 primary-btn w-100 d-flex justify-content-center"
                                                            handleClick={() => setOpenChangeTypeModal(true)}
                                                            label={[9, 10, 11, 12].includes(+currentPlan?.planId) ? 'ترقية الخطة' : 'جربه مجانأ'}
                                                        />
                                                    }
                                                />
                                            ) : (<LoadingButton
                                                loading={false}
                                                classes="mb-4 py-2 primary-btn w-100 d-flex justify-content-center small-raduis"
                                                handleClick={() => isUserVerified !== '3' ? null : onClick()}
                                                label={[9, 10, 11, 12].includes(+currentPlan?.planId) ? 'ترقية الخطة' : 'جربه مجانأ'}
                                            />) : (<LoginPopup
                                                setToken={setToken}
                                                setUserId={setUserId}
                                                children={<button className="mb-4 py-2 primary-btn w-100 d-flex justify-content-center small-raduis">جربه مجانأ</button>}
                                                path={(window.location.href.includes('amakkn-pro') ? '/amakkn-pro?loggedIn' : window.location.href.includes('pro') ? '/pro?loggedIn' : `/minasati?loggedIn`) + searchParams.get('dark') === "true" ? '&dark=true' : ""}

                                            />)}
                                        </Box>
                                    </Card>
                                </Grid>
                            ))}
                    </Grid>
                </Box>
            </Container>
        </section>
    ) : <></>;
}
