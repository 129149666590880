import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { getPropertiesOfOwnerNew } from "../../api/propertyApi";
import PropertyBox from "./property-box";
import { Avatar, Box, Grid } from "@mui/material";
import AppLayout from "../layouts/app-layout";
import { Mail, Tel, Twitter } from "../../constants/icons";
import { Facebook, Instagram, Summarize } from "@mui/icons-material";
import NoData from "../global-components/no-data";
import { useAuth } from "../../helpers/context";
import { CORPORATE_LICENSE_URI, INDIVIDUAL_LICENSE_URI } from "../../constants";
import { getBrokerRegaInfo } from "../../api/userApi";
import { resolveUserTypeName } from "../../helpers";
import { useTranslation } from "react-i18next";
import PropertyCardForAdmin from "../../scenes/shared/properties/components/property-card-for-admin";

let publicUrl = process.env.REACT_APP_URL + "/";

const BrokerProfile = (props) => {
  const [Properties, setProperties] = useState(props.properties);
  const { vendor } = useAuth();
  // let pageSize = 6;
  const [Settings] = useState({
    ownerId: props.user.id,
    page: "1",
    sortBy: "updatedAt",
    sortOrder: "desc",
    pageSize: '99999',
    userId: props.token,
    language: props.user.language,
  });
  const { t } = useTranslation();
  let userType = resolveUserTypeName(props.user.userType);
  const [licenseId, setLicenseId] = useState();
  const [licenseNumber, setLicenseNumber] = useState();
    
  useEffect(() => {
    if (props.user && props.user.advertiserNumber) {
      getBrokerRegaInfo({
        licenseNumber: props.user.advertiserNumber,
        // licenseType: "Bml"
      }).then(res => {
        if (res.resCode === 0) {
          setLicenseId(res.response.id);
          setLicenseNumber(res.response.licenseNumber);
        } else {
          setLicenseId(undefined);
          setLicenseNumber(undefined);
        }
      }).catch(err => {
        setLicenseId(undefined);
        setLicenseNumber(undefined);
      });
    }
    
  }, [props.user]);
  
  // function handlePageChanged(e,newPage) {
  //   setSettings({
  //     ...Settings,
  //     page: newPage.toString(),
  //   });
  // }

  useEffect(() => {
    getPropertiesOfOwnerNew(Settings)
      .then((property) => {
        if (property.resCode === 0) {
          setProperties(property.response.propertyArray);
          window.scroll({ top: 0, left: 0, behavior: "smooth" });
        } else {
          toast.error(property.resStr);
        }
      })
      .catch((error) => {
        throw error;
      });
  }, [Settings]);

  return (
    <AppLayout pageTitle={vendor !== "amakkn" ? "معلومات الحساب الفرعي" : "تفاصيل المعلن"} withoutNav={vendor !== 'amakkn'}>
     
      <div className={`${vendor !== 'amakkn' ? 'admin' : ''} page-wrapper page-width company-profile`}>
        <div className="row page-wrapper-without-top">
          <div className="col-12 col-lg-4 bg-cover py-5 py-lg-0" style={{ background: `url(${publicUrl}assets/img/pattern.png) repeat` }}>
            <div className="text-center w-100">
              <Avatar
                alt={props.user.name}
                className='company-img'
                sx={{ bgcolor: '#fff' }}
                src={
                  props.user.avatar
                    ? props.user.avatar
                    : publicUrl + "assets/img/defimgs/1_account.svg"
                }
              />
              <h3 className='pxp-page-header mb-2'> {props.user.name} </h3>
              <div className='mb-2'>
                <span style={{ fontSize: "16px" }} className='designation'>
                  {userType}
                </span>
                {props.user.isUserVerified >= "2" && (
                  <img
                    src={publicUrl + "assets/img/icons/verfiy.svg"}
                    alt='image2'
                  />
                )}
              </div>
                
              <div className='pt-4 widget-owner-info' style={{ borderTop: '1px solid #ccc' }}>
                  
                <div className='contact-info'>
                  {props.user.advertiserNumber && (
                    <div className='media'>
                      <div className='media-left'>
                        <Summarize />
                      </div>
                      <div className='media-body'>
                        <p>رخصة فال للوساطة والتسويق</p>
                        {licenseId ? (
                          <a target="_blank"
                            style={{ color: 'var(--main-color-one)' }}
                            rel="noreferrer"
                            href={props.user.accountType === "1" ? INDIVIDUAL_LICENSE_URI + licenseId : CORPORATE_LICENSE_URI + licenseId}>
                            {licenseNumber}
  
                          </a>
                        ) : <span>
                          {props.user.advertiserNumber}
                        </span>}
                          
                      </div>
                    </div>
                  )}
                  {props.user.email && (
                    <div className='media'>
                      <div className='media-left'>
                        <Mail />
                      </div>
                      <div className='media-body'>
                        <p>{t("Email")}</p>
                        <a
                          href={
                            "mailto:" + props.user.email
                          }>
                          {props.user.email}
                        </a>
                      </div>
                    </div>
                  )}
                  <div className='media'>
                    <div className='media-left'>
                      <Tel />
                    </div>
                    <div className='media-body'>
                      <p>{t("PhoneNumber")}</p>
                      <a
                        href={
                          "tel:" +
                          props.user.countryCode +
                          props.user.phone
                        }>
                        0{props.user.phone}
                      </a>
                    </div>
                  </div>
                  {props.user.unifiedNumber && (
                    <div className='media'>
                      <div className='media-left'>
                        <Tel />
                      </div>
                      <div className='media-body'>
                        <p>رقم الاتصال الموّحد</p>
                        <span>{props.user.unifiedNumber}</span>
                      </div>
                    </div>
                  )}
  
                  {/* {props.user.advertiserNumber && (
                      <div className='media'>
                        <div className='media-left'>
                          <ID />
                        </div>
                        <div className='media-body'>
                          <p>رخصة فال للوساطة والتسويق</p>
                          <span>
                            {props.user.advertiserNumber}
                          </span>
                        </div>
                      </div>
                    )} */}
                  {/* {props.user.licenseNumber && (
                      <div className='media'>
                        <div className='media-left'>
                          <Summarize />
                        </div>
                        <div className='media-body'>
                          <p>{props.user.licenseTypeName}</p>
                          <span>
                            {props.user.advertiserNumber}
                          </span>
                        </div>
                      </div>
                    )} */}
              
                  {props.user.pushToken && (
                    <div className='pxp-agent-section mt-4 mt-md-5'>
                      <div className='mt-3 mt-md-4'>
                        <p>{props.user.pushToken}</p>
                      </div>
                    </div>
                  )}
                  {props.user.fb && (
                    <div className='media'>
                      <div className='media-left'>
                        <Facebook />
                      </div>
                      <div className='media-body'>
                        <p>حساب فيسبوك</p>
                        <span>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={"https://www.facebook.com/" + props.user.fb}>
                            {props.user.fb}
                          </a>
                        </span>
                      </div>
                    </div>
                  )}
                  {props.user.twitter && (
                    <div className='media'>
                      <div className='media-left'>
                        <Twitter small />
                      </div>
                      <div className='media-body'>
                        <p>حساب X</p>
                        <span>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={"https://twitter.com/" + props.user.twitter}>
                            {props.user.twitter}
                          </a>
                        </span>
                      </div>
                    </div>
                  )}
                  {props.user.insta && (
                    <div className='media'>
                      <div className='media-left'>
                        <Instagram />
                      </div>
                      <div className='media-body'>
                        <p>حساب إنستقرام</p>
                        <span>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={"https://instagram.com/" + props.user.insta}>
                            {props.user.insta}
                          </a>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
  
          <div className="col-12 col-lg-8 details-wrapper scrolled" style={{maxHeight:'90vh',overflowY:'auto'}}>
            <div className="px-5 pt-5 pt-lg-0" style={{ paddingBottom: '100px' }}>
              <Box
                sx={{
                  marginBottom: "40px",
                  textAlign: "center",
                  fontSize: "28px",
                  fontWeight: 600,
                  color: "#000",
                }}>
                {vendor === 'amakkn' ? "عروض المعلن" : "عروض الحساب الفرعي"}
                  
                {props.totalCount > 0 && (
                  <Box
                    sx={{
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#000",
                    }}>
                    يوجد {props.totalCount} عروض
                  </Box>
                )}
              </Box>
              <div className='pd-top-30' >
                {Properties.length > 0 ?
                  <Grid container spacing={2}>
                    {Properties.map((item, i) => (
                      <Grid item xs={12} md={6} lg={4} xl={vendor === 'amakkn' ?3:4}
                        key={i}
                        // className="custom-grid-item"
                        style={{ cursor: "pointer" }}>
                        {vendor === 'amakkn' ? (
                         <PropertyBox
                         propertey={item}
                         key={i}
                         isEdit={false}
                         isFav={true}
                         userId={props.user.id}
                       />
                        ) : (
                            <PropertyCardForAdmin
                              hideActions
                              item={item}
                            />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                  : <NoData msg={vendor === 'amakkn' ? "لا يوجد عروض تابعة لهذا المعلن " : "لا يوجد عروض تابعة لهذا الحساب الفرعي "} />
                }
                  
                {/* {Number(props.totalCount) > pageSize && (
                    <div className="col-12 justify-content-center d-flex" dir="ltr">
                        <Pagination sx={{ '& .MuiPagination-ul': { flexDirection: 'row-reverse' } }}  count={parseInt(Math.ceil(Number(props.totalCount) / pageSize))}
                          page={Number(Settings.page)}
                          onChange={handlePageChanged}
                          color="primary" />
                    </div>
                  )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default BrokerProfile;
