import React from "react";
import Navbar from "../../global-components/navbar";
import { Link } from "react-router-dom";

const CRM = () => {
  return (
    <div>
      <Navbar />
      <div className='about-area pd-top-100 pd-bottom-90'>
        <div className='container pd-top-50'>
          <div className='row justify-content-md-center'>
            <div className='col-12 col-lg-4 mb-4 mb-lg-0'>
              <ul className='agent-ul' style={{ width: "100%" }}>
                <Link className='text-reset' to='/crm/properties'>
                  <li>
                    <div className='agent-item'>
                      <span>
                        <strong>الإعلانات العقاريّة</strong>
                      </span>
                    </div>
                  </li>
                </Link>
                <Link className='text-reset' to='/crm/people'>
                  <li>
                    <div className='agent-item'>
                      <span>
                        <strong>الأشخاص</strong>
                      </span>
                    </div>
                  </li>
                </Link>
                <Link className='text-reset' to='/crm/all-data'>
                  <li>
                    <div className='agent-item'>
                      <span>
                        <strong>جميع البيانات</strong>
                      </span>
                    </div>
                  </li>
                </Link>
                <Link className='text-reset' to='/crm/statistics'>
                  <li>
                    <div className='agent-item'>
                      <span>
                        <strong>الإحصائيات</strong>
                      </span>
                    </div>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CRM;
