import React, { useEffect, useState } from 'react'
import AppLayout from '../../../components/layouts/app-layout';
import { useAuth } from '../../../helpers/context';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getNormalPlans, getUserPlan, getUserProile, getWLPlans, registerUserForWL, setUserPlan } from '../../../api/userApi';
import AmakknPlansCards from './components/amakkn-plans-cards';
import { toast } from 'react-toastify';
import { checkPromo } from '../../../api/propertyApi';
import { ThemeProvider } from '@emotion/react';
import { Alert, Box, CircularProgress, Dialog, DialogContent, DialogTitle, Drawer, Hidden, IconButton, Skeleton, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import LoadingButton from '../../../components/global-components/loading-btn';
import { openDeleteModal } from '../../../helpers';
import Swal from "sweetalert2/dist/sweetalert2.js";
import CloseIcon from "@mui/icons-material/Close";
import { changeOrderStatus, checkPayment, saveOrder } from '../../../api/generalAPI';
import moment from 'moment';
import { plansFeatures } from '../../../constants';
import DomainChooser from '../../../components/domain-chooser';
import ChangeTypePopup from '../../../components/user-components/change-type-popup';
import ContactusPopover from '../../../components/global-components/contact-us-popover';
import { useTranslation } from 'react-i18next';

let publicUrl = process.env.REACT_APP_URL + "/";

export default function PlanRegistrationPage() {
  const { token, UserId, setToken, setUserId, setUserType, UserType, setAccountType, paymentData, setPaymentData } = useAuth();
  let { id } = useParams();
  const [plans, setPlans] = useState([]);
  const [loadingPlans, setLoadingPlans] = useState(true);
  const [loadingWLPlans, setLoadingWLPlans] = useState(true);
  const [loadingUserPlan, setLoadingUserPlan] = useState(true);
  const [isUserVerified, setIsUserVerified] = useState(0);
  const [currentPlan, setCurrentPlan] = useState();
  const [openChangeTypeModal, setOpenChangeTypeModal] = useState(false);
  const [coupon, setCoupon] = useState({
    value: "",
    isValid: "",
    discount: "",
  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loadingCouponButton, setLoadingCouponButton] = useState(false);
  const [openMoyasarForm, setOpenMoyasarForm] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(undefined);
  const [selectedWLPlan, setSelectedWLPlan] = useState();
  const history = useNavigate();
  const [AccountType, setAccountType2] = useState();
  // const [advertiserNumber, setAdvertiserNumber] = useState();
  const [waitingResponse, setWaitingResponse] = useState(false);
  // const [isSubmittingButton, setIsSubmittingButton] = useState(false);
  const isFromWebview = !window.location.href.includes("prices");
  const [WLPlans, setWLPlans] = useState([]);
  const [step, setStep] = useState(1);
  const [searchParams] = useSearchParams();
  const [isTrial, setIsTrial] = useState(searchParams.get('isTrial') === 'true' ? true : false);
  const completedSteps = [];
  const [user, setUser] = useState();
  const { t } = useTranslation();

  function registerDomain(Domain, planId, domainNameWithExt) {
    registerUserForWL({
      language: "0",
      userName: Domain,
      domainNameWithExtension: domainNameWithExt,
      userId: token,
      planId: planId,
      isTrialVersion: isTrial ? '1' : '0'
    }).then((_items) => {
      if (_items.resCode === 0) {
        let data = _items?.response?.wlUser;
        let path = "https://" + data?.userName + ".amakkn.com/minasati-step2/" + token + '?successModal';
        window.location.replace(path);
      } else {
        toast.error(_items.resStr);
      }
      setWaitingResponse(false);
    });
  }

  function registerAmakknDomain(Domain, planId) {
    registerUserForWL({
      language: "0",
      userName: Domain,
      domainNameWithExtension: "",
      userId: token,
      planId: planId,
      isTrialVersion: isTrial ? '1' : '0'
    }).then((_items) => {
      if (_items.resCode === 0) {
        let data = _items?.response?.wlUser;
        let path = "https://" + data?.userName + ".amakkn.com/minasati-step2/" + token;
        window.location.replace(path);
      } else {
        toast.error(_items.resStr);
      }
      setWaitingResponse(false);
    });
  }


  const getUserData = (userId) => {
    getUserProile({
      userId: userId,
      language: "0",
    }).then((_userInfo) => {
      if (_userInfo.resCode === 0) {
        setUser(_userInfo.response.userProfile);
        setAccountType(_userInfo.response.userProfile.accountType);
        setAccountType2(_userInfo.response.userProfile.accountType);
        setUserId(_userInfo.response.userProfile.userId);
        setUserType(_userInfo.response.userProfile.userType);
        setIsUserVerified(_userInfo.response.userProfile.isUserVerified);
        // setAdvertiserNumber(_userInfo.response.userProfile.advertiserNumber);
        // setIsWLUser(_userInfo.response.userProfile.isWLUser ? true : false);
        // if (_userInfo.response.userProfile.isWLUser)
        //   getWLProfile({
        //     userName: _userInfo.response.userProfile.isWLUser,
        //     language: "0",
        //   }).then((result) => {
        //     if (result.resCode === 0) {
        //       if (result?.response?.wlUser?.planId) {
        //         setCurrentWLPlan(result.response.wlUser.planId);
        //       }
        //     }
        //   });
      }
    });
  };
    
  useEffect(() => {
    if (isFromWebview) {
      if (id) {
        getUserData(id);
      } else {
        setToken(undefined);
        setUserId(undefined);
        setUserType(undefined);
        setAccountType(undefined);
        setPaymentData(undefined);
        document?.cookie?.split(";")?.forEach(function (c) {
          if (!c.includes('AmmaknConsent'))
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        sessionStorage?.clear?.();
        localStorage?.clear?.();
      }
    } else {
      if (id) {
        getUserData(id);
      } else if (token) {
        getUserData(token);
      }
    }
   
  },// eslint-disable-next-line
    [token, id, isFromWebview]);

  useEffect(() => {
    if (id) {
      setToken(id);
    }
  },// eslint-disable-next-line
    [id]);
  
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }, [step]);
    
  useEffect(() => {
    if (token) {
      getUserPlan({ userId: token, language: '0' }).then((result) => {
        if (result.resCode === 0) {
          setCurrentPlan(result.response);
        } else {
          toast.error(result.resStr)
        }
      }).finally(() => {
        setLoadingUserPlan(false);
      });
    } else {
      setLoadingUserPlan(false);
    }
    
    getNormalPlans({ language: '0', userId: token }).then(res => {
      setPlans(res.response);
      setLoadingPlans(false);
    });

    getWLPlans({ language: '0' }).then(res => {
      const data = res.response.plans;
      let plansTemp = [];
      data.map((plan) => plansTemp.push({ ...plan, features: plansFeatures[plan.id] }));
      setWLPlans(plansTemp);
      setLoadingWLPlans(false);
    });
    
    window?.scroll({ top: 0, left: 0, behavior: "smooth" });
  },// eslint-disable-next-line
    [token]);
  
  useEffect(
    () => {
      if (searchParams.get('id') && searchParams.get('planId') && searchParams.get('planName')) {
        setWaitingResponse(true);

        let planId = searchParams.get('planId');
        // let wlPlanId = searchParams.get('wlPlanId');
        let paymentId = searchParams.get('id');
        if (paymentData?.selectedNormalPlan) {
          setOpenDrawer(true);
          setSelectedPlan(paymentData?.selectedNormalPlan);
          if (paymentData?.coupon) {
            setCoupon(paymentData?.coupon);
          }
        }
        setTimeout(() => {
          setPaymentData(undefined);
        }, 3000);
        checkPayment({ paymentId }).then((res) => {
          let data = res.response;
          if (res.resCode === 0) {
            changeOrderStatus({
              language: "1",
              paymentId: paymentId,
              paymentStatus: data.status,
              userId: token,
              status: data.status === "paid" ? "done" : "failed",
            });
            if (data.status === "paid") {
              setUserPlan({
                userId: token,
                planId: planId,
                isTrialVersion: isTrial ? '1' : '0'
              }).then((res) => {
                if (res.resCode === 0) {
                  setWaitingResponse(false);
                  Swal.fire({
                    title: "تم الاشتراك بنجاح",
                    text: `تم الاشتراك في الخطة بنجاح`,
                    icon: "success",
                    confirmButtonText: t("Close"),
                  });
                  history(`/my-subscription${isFromWebview ? `/${id}` : ''}`);
                  // if (searchParams.get('wlPlanId')) {
                  //   subscribe({
                  //     userId: token,
                  //     planId: wlPlanId,
                  //     startDate: moment().locale("en").format("YYYY-MM-D"),
                  //     endDate: moment()
                  //       .locale("en")
                  //       .add(isTrial ?
                  //         (WLPlans.filter(i => +i.id === +wlPlanId)?.[0]?.trialDuration ? +WLPlans.filter(i => +i.id === +wlPlanId)?.[0]?.trialDuration :
                  //           +WLPlans.filter(i => +i.id === +wlPlanId)?.[0]?.duration) :
                  //         +WLPlans.filter(i => +i.id === +wlPlanId)?.[0]?.duration, "day")
                  //       .format("YYYY-MM-D"),
                  //     isTrialVersion: isTrial ? '1' : '0'
                  //   }).then((result) => {
                  //     if (result.resCode === 0) {
                  //       setWaitingResponse(false);
                  //       // history(`/success-registration/${planName}/${wlPlanName}`);
                  //       history('/my-subscription');
                  //     } else {
                  //       setWaitingResponse(false);
                  //       toast.error(result.resStr);
                  //     }
                  //   }).catch(e => { setWaitingResponse(false); });
                  // } else {
                  //   // history(`/success-registration/${planName}`);
                  //   setWaitingResponse(false);
                  //   Swal.fire({
                  //     title: "تم الاشتراك بنجاح",
                  //     text: `تم الاشتراك في الخطة بنجاح`,
                  //     icon: "success",
                  //     confirmButtonText: t("Close")
                  //   });
                  //   history('/my-subscription');
                  // }
                } else {
                  toast.error(res.resStr);
                  setWaitingResponse(false);
                }
              });
            } else {
              setWaitingResponse(false);
              Swal.fire({
                title: "لم يتم الاشتراك",
                text: data.source.message,
                icon: "error",
                confirmButtonText: t("Close"),
              });
            }
          } else {
            setWaitingResponse(false);
            toast.error(res.resStr);
          }
        });
      } else if (
        searchParams.get('Domain') && searchParams.get('domainNameWithExt')
      ) {
        setWaitingResponse(true);
        let Domain = searchParams.get('Domain');
        let wlPlanId = searchParams.get('wlPlanId');
        let domainNameWithExt = searchParams.get('domainNameWithExt');
        let planId = searchParams.get('planId');
        let paymentId = searchParams.get('id');
        if (paymentData?.selectedPlan) {
          setStep(2);
          setSelectedWLPlan(paymentData?.selectedPlan);
          if (paymentData?.coupon) {
            setCoupon(paymentData?.coupon);
          }
          if (paymentData?.selectedNormalPlan) {
            setSelectedPlan(paymentData?.selectedNormalPlan);
          }
        }
        checkPayment({ paymentId }).then(res => {
          let data = res.response;
          if (res.resCode === 0) {
            if (data?.http_code && data?.http_code === 404) {
              toast.error('عذراً, لم تُحفظ الدفعة, يرجى إعادة عملية الدفع');
              setWaitingResponse(false);
            } else {
              changeOrderStatus(
                {
                  "language": "1",
                  "paymentId": paymentId,
                  "paymentStatus": data.status,
                  "userId": token,
                  "status": data.status === "paid" ? "done" : "failed"
                }).then((result) => {
                  if (result.resCode === 0) {
                    if (data.status === "paid") {
                      if (domainNameWithExt?.includes('.amakkn.com'))
                        registerAmakknDomain(Domain, wlPlanId);
                      else
                        registerDomain(Domain, wlPlanId, domainNameWithExt);
                      
                      if (searchParams.get('planId')) {
                        setUserPlan({
                          userId: token,
                          planId: planId,
                          isTrialVersion: isTrial ? '1' : '0'
                        }).then((res) => {
                          if (res.resCode === 0) {
                            setWaitingResponse(false);
                            // subscribe({
                            //   userId: token,
                            //   planId: selectedWLPlan?.id,
                            //   startDate: moment().locale("en").format("YYYY-MM-D"),
                            //   endDate: moment()
                            //     .locale("en")
                            //     .add(isTrial ?
                            //       (selectedWLPlan?.trialDuration ? +selectedWLPlan?.trialDuration : +selectedWLPlan?.duration) :
                            //       +selectedWLPlan?.duration, "day")
                            //     .format("YYYY-MM-D"),
                            //   isTrialVersion: isTrial ? '1' : "0",
                            // }).finally(() => setWaitingResponse(false));
                          }
                        }).catch(() => setWaitingResponse(false));
                      }
                    } else {
                      setWaitingResponse(false);
                      Swal.fire({
                        title: 'لم يتم الاشتراك',
                        text: data.source.message,
                        icon: 'error',
                        confirmButtonText: t("Close"),
                      });

                    }
                  } else {
                    setWaitingResponse(false);
                    toast.error(result.resStr);
                  }
                }).catch(() => { setWaitingResponse(false); });
            }
          } else {
            toast.error(res.resStr);
            setWaitingResponse(false);
          }
        }).catch(() => { setWaitingResponse(false); });

      }
    },
    // eslint-disable-next-line
    [searchParams]
  );

  useEffect(
    () => {
      if (openMoyasarForm)
        setTimeout(() => {
          window?.Moyasar?.init({
            element: ".mysr-form",
            language: "ar",
            amount: coupon.isValid
              ? (
                ((+selectedPlan?.price || 0) - ((+selectedPlan?.price || 0) * parseFloat(coupon.discount)) / 100) * 100
              ).toFixed(2)
              : parseFloat((+selectedPlan?.price || 0) * 100).toFixed(2),
            currency: "SAR",
            description: `${selectedPlan?.name ? `اشتراك بخطّة ${selectedPlan?.name}` : ''} | ${user?.name} | ${user?.phone} | ${user?.userId} | ${coupon?.value}`,
            publishable_api_key: process.env.REACT_APP_MOYASAR_KEY,
            // "pk_live_YyU1CG3AbLfLgAeMb4NetZzyFhYCFFPqVVBVe2wJ", //'pk_test_JiU5dzWs6Y2J1dz2BbLZdeEDVjDTws7tdh9vjxVZ',
            callback_url: selectedWLPlan ? `${publicUrl}${isFromWebview ? 'plan-registration' : 'plans-and-prices'}?planId=${selectedPlan?.id || 0}&planName=${selectedPlan?.name || ''}&wlPlanId=${selectedWLPlan?.id || 0}&isTrial=${isTrial}${searchParams.get('dark') === "true" ? '?dark=true' : ""}` :
              `${publicUrl}${isFromWebview ? 'plan-registration' : 'plans-and-prices'}?planId=${selectedPlan?.id || 0}&planName=${selectedPlan?.name || ''}&isTrial=${isTrial}${searchParams.get('dark') === "true" ? '?dark=true' : ""}`,
            methods: ["creditcard"], // 'applepay'],
            // apple_pay: {
            //   country: 'SA',
            //   label: `شراء الخطّة (${selectedPlan?.name})`,
            //   validate_merchant_url: 'https://api.moyasar.com/v1/applepay/initiate',
            // },
            credit_card: {
              save_card: true,
            },
            on_completed: function (payment) {
              return new Promise(function (resolve, reject) {
                saveOrder({
                  // oldWlPlan: +currentPlan?.planId === 9 ? 7 : +currentPlan?.planId === 10 ? 6 : +currentPlan?.planId === 11 ? 9 : +currentPlan?.planId === 12 ? 8 : '',
                  // oldNormalPlan: currentPlan?.planId || '',
                  // oldWlDate: [9, 10, 11, 12].includes(+currentPlan?.planId) ? currentPlan?.endDate : '',
                  // oldNormalDate: currentPlan?.endDate || '',
                  // paymentAmoutWl: selectedWLPlan ? (coupon.isValid
                  //   ? ((+selectedWLPlan?.price - ((+selectedWLPlan?.price) * parseFloat(coupon.discount)) / 100) * 100).toFixed(2)
                  //   : parseFloat(+selectedWLPlan?.price * 100).toFixed(2)) : '',
                  // paymentAmountBeforeDiscountWl: +selectedWLPlan?.price || '',
                  // discountAmountWl: coupon.isValid && selectedWLPlan ? ((+selectedWLPlan?.price * parseFloat(coupon.discount)) / 100).toFixed(2) : '',
                  // paymentAmoutNormal: selectedPlan ? (coupon.isValid
                  //   ? ((+selectedPlan?.price - ((+selectedPlan?.price) * parseFloat(coupon.discount)) / 100) * 100).toFixed(2)
                  //   : parseFloat(+selectedPlan?.price * 100).toFixed(2)) : '',
                  // paymentAmountBeforeDiscountNormal: +selectedPlan?.price ? +selectedPlan?.price * 100 : '',
                  // discountAmountNormal: coupon.isValid && selectedPlan ? (((+selectedPlan?.price * parseFloat(coupon.discount)) / 100) * 100).toFixed(2) : '',
                  "paymentId": payment?.id,
                  "paymentStatus": payment?.status,
                  "paymentAmount": payment?.amount,
                  // "wlPlan": selectedWLPlan?.id || undefined,
                  "paymentAmountBeforeDiscount": +selectedPlan.price ? +selectedPlan.price * 100 : 0,
                  "discountAmount": coupon.isValid ? ((((+selectedPlan.price || 0) * parseFloat(coupon.discount)) / 100) * 100).toFixed(2) : "-1",
                  "promoCodeId": coupon.isValid ? coupon.value : "-1",
                  "paymentMessage": " ",
                  "paymentToken": payment?.source?.token || "-1",
                  "userId": token,
                  "domain": "-1",
                  "status": "InProgress",
                  "language": "0",
                  "isTrialVersion": isTrial ? '1' : '0',
                  "userPlanId": selectedPlan?.id || undefined,
                })
                  .then((res) => {
                    resolve({ payment });
                  })
                  .catch((e) => {
                    toast.error("لم يتم الدفع, الرجاء المحاولة لاحقاً");
                    reject();
                  });
              });
            },
          });
          setPaymentData({
            selectedNormalPlan: selectedPlan,
            selectedPlan: selectedWLPlan,
            coupon: coupon
          });
        }, 200);
    }, // eslint-disable-next-line
    [openMoyasarForm]
  );

  const applyCouponCode = () => {
    if (coupon && coupon.value) {
      setLoadingCouponButton(true);
      checkPromo({
        userId: UserId,
        code: coupon.value,
      })
        .then((res) => {
          if (res.resCode === 0) {
            setCoupon({
              ...coupon,
              discount: res.response.discount,
              isValid: res.response.isValid,
            });
            if (res.response.isValid === "0")
              toast.error("كود الخصم المُدخل مُنتهي الصلاحيّة");
          } else {
            toast.error(res.resStr);
          }
          setLoadingCouponButton(false);
        })
        .catch((e) => setLoadingCouponButton(false));
    } else {
      toast.error("قم بادخال كود الخصم");
    }
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setCoupon({
      value: "",
      isValid: "",
      discount: "",
    });
  };

  const openConfirmPopup = () => {
    openDeleteModal(
      "تأكيد الاشتراك",
      "هل أنت متأكد من الاشتراك في هذه الخطة؟",
      () => {
        if ((+selectedPlan?.price || 0) + (+selectedWLPlan?.price || 0) === 0 ||
          (coupon.isValid &&
            (
              (+selectedPlan?.price || 0) -
              ((+selectedPlan?.price || 0) * parseFloat(coupon.discount)) / 100).toFixed(1) === "0.0")
        ) {
          if (selectedPlan && !selectedWLPlan) {
            setUserPlan({
              userId: token,
              planId: selectedPlan.id,
              isTrialVersion: '0'
            }).then((res) => {
              if (res.resCode === 0) {
                Swal.fire({
                  title: "تم الاشتراك بنجاح",
                  text: `تم الاشتراك في الخطة (${selectedPlan?.name}) بنجاح`,
                  icon: "success",
                  confirmButtonText: t("Close"),
                });
                handleCloseDrawer();
                setTimeout(() => {
                  history(`/my-subscription${isFromWebview ? `/${id}` : ''}`);
                }, 1500);
              } else {
                toast.error(res.resStr);
              }
            });
          } else if (selectedPlan && selectedWLPlan) {
            setUserPlan({
              userId: token,
              planId: selectedPlan.id,
              isTrialVersion: isTrial ? '1' : '0'
            }).then((res) => {
              if (res.resCode === 0) {
         
                // subscribe({
                //   userId: token,
                //   planId: selectedWLPlan?.id,
                //   startDate: moment().locale("en").format("YYYY-MM-D"),
                //   endDate: moment()
                //     .locale("en")
                //     .add(isTrial ?
                //       (selectedWLPlan?.trialDuration ? +selectedWLPlan?.trialDuration : +selectedWLPlan?.duration) :
                //       +selectedWLPlan?.duration, "day")
                //     .format("YYYY-MM-D"),
                //   isTrialVersion: isTrial ? '1' : "0",
                // }).then((result) => {
               
                //   if (result.resCode === 0) {
                //     Swal.fire({
                //       title: "تم الاشتراك بنجاح",
                //       text: `تم الاشتراك في الخطة (${selectedPlan?.name}) بنجاح`,
                //       icon: "success",
                //       confirmButtonText: t("Close"),
                //     });
                //     handleCloseDrawer();
                //     setTimeout(() => {
                //       history('/my-subscription');
                //     }, 1500);
                //   } else {
                //     toast.error(result.resStr);
                //   }
                // }).catch(e => { });
                Swal.fire({
                  title: "تم الاشتراك بنجاح",
                  text: `تم الاشتراك في الخطة (${selectedPlan?.name}) بنجاح`,
                  icon: "success",
                  confirmButtonText: t("Close"),
                });
                handleCloseDrawer();
                setTimeout(() => {
                  history(`/my-subscription${isFromWebview ? `/${id}` : ''}`);
                }, 1500);
              } else {
                toast.error(res.resStr);
              }
            });
          }
        } else {
          setOpenMoyasarForm(true);
        }
      },
      "اشتراك",
      searchParams.get('dark') === "true"
    );
  }
  
  const renderUpgradeNormalPlanSection = () => {
    return ((token && currentPlan)//![3, 6].includes(+currentPlan?.planId)
      || !token) && (
        <AmakknPlansCards
          data={plans}
          WLPlans={WLPlans}
          isFromWebview={isFromWebview}
          currentPlan={currentPlan}
          AccountType={AccountType}
          isWLUser={user?.isWLUser}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          currentPlanID={token ? +currentPlan?.planId : 0}
          isUserVerified={isUserVerified}
          setOpenDrawer={setOpenDrawer}
          setStep={setStep}
          setIsTrial={setIsTrial}
          setSelectedWLPlan={setSelectedWLPlan}
        />
      );
  }

  const steps = [
    "الدفع واختيار النطاق",
    "معلومات التواصل",
    "اختيار الشعار",
    "من نحن",
    "الألوان والخطوط",
    "الخدمات",
    "الإنتهاء",
  ];

  return (
    <AppLayout pageTitle="الاشتراكات" withoutNav={isFromWebview} withFooter={!isFromWebview} unsetOverflow>
      {UserType !== '5' && (
        <>
          <div className={`${searchParams.get('dark') === "true" ? 'dark' : ""} page-width ${isFromWebview ? 'full-height' : ''}`} style={{ top: isFromWebview ? 0 : '' }}>
            <div className={`${isFromWebview ? 'pt-2 full-height' : 'pt-5'}`}>
              {step === 1 ?
                <>
                  <section className="home-section">
                    <div className="bg-shape-5 d-none d-lg-block"></div>
                    <div className="bg-shape-6"></div>
                    <div className={`container position-relative d-flex pb-100 ${isFromWebview ? '' : 'pt-100 align-items-center min-height-100vh'} mx-auto`}
                      style={isFromWebview ? { paddingTop: '50px' } : {}}>
                      <div className="home-content text-start">
                        <div className="row mx-0">
                          <div className="col-lg-6 d-flex align-items-center mb-md-60 mb-sm-40">
                            <div className="w-100 text-center text-lg-right">
                              <h1 className="section-title mb-3 text-center text-lg-right">
                                خيارات مناسبة لأعمالك
                              </h1>
                              <p className="section-descr mb-5">
                                قم باختيار الخطّة المناسبة لك و تمتّع بتجربة مميّزة مع أماكن
                              </p>
                              {!loadingPlans && !loadingUserPlan ? (
                                <>
                                  <div className="d-flex align-items-center align-items-lg-start flex-column" style={{ gap: '10px' }}>
                                    {currentPlan?.name ? (
                                      <>
                                        <p style={{ fontSize: 17 }}>
                                          خطتك الحاليّة: &nbsp;
                                          {currentPlan?.name + (![1, 4].includes(+currentPlan?.planId) ? +currentPlan?.duration === 365 ? ' السنويّة' : ' الشهريّة' : '') + (currentPlan?.isTrialVersion === "1" ? ' (وضع تجريبي)' : '')}
                                        </p>
                                        {![11, 12].includes(+currentPlan?.planId) && <a
                                          href={'#plans'}
                                          className="btn primary-btn same-width small-raduis"
                                        >
                                          قم بالترقية الآن
                                        </a>}
                                      </>
                                    ) : (
                                      <a
                                        href={'#plans'}
                                        className="btn primary-btn same-width small-raduis"
                                      >
                                        إبدأ الآن
                                      </a>
                                    )}
                                  </div>
                                  {/* {!currentPlan?.name && <span className="mt-3 colored-bg mx-auto mx-lg-0 mb-5 mb-lg-0">30 يوماً تجربة مجانية</span>} */}
                                </>
                              ) :
                                <Skeleton variant='rectangular' width={300} height={70} sx={{ borderRadius: "5px", margin: 'auto' }} />}
                            </div>
                          </div>
                          <div className="col-lg-6 d-flex align-items-center">
                            <div className="w-100 ps-xl-3">
                              <div className="composition">
                                <div className="composition-image">
                                  <img src={publicUrl + `assets/img/plans-bg.svg`} alt={'Plans'} />
                                </div>
                              </div>
                            </div>
                          </div>
            
                        </div>
                      </div>
                    </div>
                  </section>
                  <div className='col-12 p-0' id="plans">
                    {loadingPlans || loadingUserPlan || loadingWLPlans ? <></> : (
                      <>
                        {renderUpgradeNormalPlanSection()}
                        {token && AccountType === "1" && !isFromWebview && (
                          <div className="banner-wrapper blue-background" style={token && AccountType === "1" && [2, 3].includes(+currentPlan?.planId) ? { marginTop: 0 } : {}}>
                            <div className="add-ad-banner" dir="rtl">
                              <div className="right-section">
                                <h3 style={{ color: '#333' }}>تريد المزيد من الخطط والميزات؟</h3>
                                <p style={{ color: '#444' }}>لا تتردد في تغيير نوع حسابك إلى أعمال, والتمتع بالكثير من المزايا والخدمات وإنشاء منصّة خاصة بك</p>
                                <ChangeTypePopup
                                  openChangeTypeModal={openChangeTypeModal}
                                  setOpenChangeTypeModal={setOpenChangeTypeModal}
                                  desc={'في حال قيامك بالمتابعة, سيتم تغيير نوع حسابك إلى حساب أعمال وستتمكن من الاستفادة من الميزات والخدمات التي نقدمها للمنشأت العقاريّة '}
                                  trigger={
                                    <LoadingButton
                                      loading={false}
                                      classes="btn primary-btn same-width small-raduis"
                                      handleClick={() => setOpenChangeTypeModal(true)}
                                      label="تغيير الآن"
                                    />
                                  }
                                />
                              </div>
                              <div className="left-section">
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="-5.0 -10.0 110.0 135.0" width={300}>
                                  <g>
                                    <path fill="#333" d="m72.656 3.125c-9.625 0-18.34 5.6992-22.195 14.516-0.34766 0.78906 0.015624 1.7109 0.80469 2.0586 0.78906 0.35156 1.7109-0.015625 2.0586-0.80469 3.3594-7.6836 10.949-12.645 19.332-12.645 11.633 0 21.094 9.4609 21.094 21.094s-9.4609 21.094-21.094 21.094c-2.8789 0-5.6719-0.57031-8.3047-1.7031-1.8945-0.78906-3.1055 2.0391-1.2305 2.8711 15.473 6.9805 34.105-5.2422 33.758-22.266-0.003906-13.352-10.867-24.215-24.223-24.215z" />
                                    <path fill="#333" d="m66.516 53.285c-0.31641-0.25391-0.68359-0.46094-1.1055-0.45703h-6.3633v-11.59l1.3828 1.207c0.57812 0.5 1.4336 0.51172 2.0234 0.023438l3.7344-3.0938c0.73047-0.57812 0.75-1.7812 0.03125-2.3789l-22.215-19.422c-0.58984-0.51562-1.4688-0.51562-2.0586 0l-22.223 19.422c-0.71484 0.59766-0.70312 1.8008 0.03125 2.3789l3.7344 3.0938c1.293 1.0742 2.4805-0.46484 3.4023-1.2305v11.59h-6.3633c-0.41406 0-0.8125 0.16406-1.1055 0.45703l-15.84 15.84c-0.98438 0.92188-0.24609 2.7148 1.1055 2.668h3.7188v23.52c0 0.86328 0.69922 1.5625 1.5625 1.5625h66c0.86328 0 1.5625-0.69922 1.5625-1.5625v-23.52h3.7188c1.3477 0.046875 2.0938-1.7461 1.1055-2.668l-15.84-15.84zm-42.055-14.07-1.3008-1.0781 19.809-17.312 19.809 17.312-1.2969 1.0781-17.48-15.281c-0.58984-0.51562-1.4688-0.51562-2.0586 0zm31.461-0.69531v14.305l-25.906 0.003906v-14.305l12.953-11.336zm-47.461 30.148 12.715-12.715h40.465c-1.3008 1.3008-11.73 11.727-12.715 12.715zm44.824 25.082h-41.754v-21.957h41.758zm14.523 0h-4.7969v-7.6797c0-1.3203 1.0742-2.3984 2.3984-2.3984 1.3203 0 2.3984 1.0742 2.3984 2.3984zm6.5977 0h-3.4766v-7.6797c0-3.0469-2.4766-5.5234-5.5234-5.5234s-5.5234 2.4766-5.5234 5.5234l0.003907 7.6797h-3.4766v-21.957h17.996zm1.5625-25.082h-22.629c2.4688-2.4688 9.7188-9.7188 12.07-12.066l12.066 12.066z" />
                                    <path fill="#333" d="m83.594 35.938c0.86328 0 1.5625-0.69922 1.5625-1.5625v-10.289l0.45703 0.45703c1.4492 1.4375 3.6484-0.75781 2.2109-2.2109l-3.125-3.125c-0.58203-0.60156-1.6328-0.60547-2.2109 0l-3.125 3.125c-1.4375 1.4492 0.75781 3.6484 2.2109 2.2109l0.45703-0.45703v10.289c0 0.86328 0.69922 1.5625 1.5625 1.5625z" />
                                    <path fill="#333" d="m72.656 35.938c0.86328 0 1.5625-0.69922 1.5625-1.5625v-10.289l0.45703 0.45703c1.4492 1.4375 3.6484-0.75781 2.2109-2.2109l-3.125-3.125c-0.58203-0.60156-1.6328-0.60547-2.2109 0l-3.125 3.125c-1.4375 1.4492 0.75781 3.6484 2.2109 2.2109l0.45703-0.45703v10.289c0 0.86328 0.69922 1.5625 1.5625 1.5625z" />
                                    <path fill="#333" d="m61.719 28.406c0.86328 0 1.5625-0.69922 1.5625-1.5625v-2.7578l0.45703 0.45703c1.4492 1.4375 3.6484-0.75781 2.2109-2.2109l-3.125-3.125c-0.58203-0.60156-1.6328-0.60547-2.2109 0l-3.125 3.125c-1.4375 1.4492 0.75781 3.6484 2.2109 2.2109l0.45703-0.45703v2.7578c0 0.86328 0.69922 1.5625 1.5625 1.5625z" />
                                  </g>
                                </svg>
                              </div>
                            </div>
                          </div>
                        )}
                        {!token && isFromWebview && <h5 style={{ color: '#d00404' }}>يجب عليك تسجيل الدخول للمتابعة</h5>}
                        {token && isUserVerified !== '3' && isFromWebview && <h5 style={{ color: '#d00404' }}>يجب عليك توثيق الحساب للمتابعة</h5>}
                        
                      </>
                    )}
                  </div>
                </>
                :
                (
                  <div className={`row page-wrapper-without-top page-wrapper-column ${isFromWebview ? 'pt-0' : 'pt-5'} pb-5 px-3`}>
                    <div className='col-12'>
                      <div className='container'>
                        <Box sx={{ width: "100%", margin: "40px auto 32px", padding: 0 }}>
                          <h2 className='page-title'>إعداد أماكن برو</h2>
                          <Stepper
                            className='onboarding-steps'
                            activeStep={0}
                            nonLinear
                            sx={{ width: "100%" }}>
                            {steps.map((label, index) => (
                              <Step
                                key={label}
                                completed={
                                  completedSteps[index] !== undefined ? true : false
                                }>
                                <StepLabel>{label}</StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                          <div className='onboarding-mobile-steps'>
                            <Box sx={{ position: "relative", display: "inline-flex" }}>
                              <CircularProgress
                                size={60}
                                thickness={6}
                                variant='determinate'
                                value={(100 / steps.length)}
                              />
                              <Box
                                sx={{
                                  top: 0,
                                  left: 0,
                                  bottom: 0,
                                  right: '-3px',
                                  position: "absolute",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}>
                                <Typography
                                  variant='caption'
                                  component='div'
                                  color='text.secondary'>
                                  خطوة
                                  <br />
                                  {`1/${steps.length}`}
                                </Typography>
                              </Box>
                            </Box>
                            <p style={{ margin: 0 }}>{steps[0]}</p>
                          </div>
                        </Box>
                      </div>
                    </div>
                    <div className='col-12 text-center'>
                      <h3 className='modal-heading'>الدفع واختيار النطاق</h3>
                      <p className='modal-desc'>
                        يتوجب عليك اختيار اسم النطاق الخاص بمنصتك الإلكترونيّة ومتابعة عمليّة الدفع, لتبدأ تجربة
                        فريدة مع أماكن
                      </p>
                    </div>
                    <div className='container'>
                      <div className="col-12 px-0" dir="ltr">
                        <DomainChooser currentPlan={currentPlan} isTrial={isTrial} isFromWebview={isFromWebview} from="onboarding" selectedNormalPlan={selectedPlan} selectedPlan={selectedWLPlan} setStep={setStep} withBack />
                      </div>
                    </div>
                  </div>
                )
              }
            </div>

          </div>
        
          <Hidden mdUp >
            <ThemeProvider
              theme={(outerTheme) => ({
                ...outerTheme,
                direction: "ltr"
              })}>
              <Drawer
                anchor={"left"}
                sx={{ zIndex: 999999 }}
                open={openDrawer}
                className={`custom-drawer ${searchParams.get('dark') === "true" ? 'dark' : ""}`}
                onClose={handleCloseDrawer}>
                <CloseIcon
                  onClick={() => handleCloseDrawer()}
                  sx={{
                    zIndex: 99,
                    top: "26px",
                    position: "absolute",
                    left: "5px",
                    cursor: "pointer",
                  }}
                />
                {selectedPlan &&
                  <>
                    <div
                      className={`custom-drawer ${searchParams.get('dark') === "true" ? 'dark' : ""}`}
                      style={{
                        padding: "14px",
                        height: 'auto'
                      }}>
                      <div className='coupon-box row px-2' dir='rtl'>
                        <h5 className='col-12 text-right mb-2 pb-3' style={{ borderBottom: searchParams.get('dark') === "true" ? '2px solid #aaa' : "2px solid #eee" }}>ملخص الطلب</h5>
                    
                        <p className='coupon-details-row'>
                          <span>
                            {`الاشتراك بخطّة ${selectedPlan?.name} / ${+selectedPlan?.duration === 365 ? 'سنة' : 'شهر'}`}
                          </span>
                          {/* <span style={{ fontSize: '12px', fontWeight: 400 }} className="badge success-outlined">
                          {`0-أيام فترة تجريبيّة`}
                        </span> */}
                        </p>
                        <p className='coupon-details-row'>
                          <span>سعر الاشتراك (شامل ضريبة القيمة المضافة)</span>
                          <span
                            style={{ fontSize: 15, fontWeight: 700, textDecoration: coupon?.isValid ? 'line-through #d50000' : "none" }}>
                            {`${(+selectedPlan?.price || 0).toFixed(2)} ر.س/${+selectedPlan?.duration === 365 ? 'سنة' : 'شهر'}`}
                          </span>
                        </p>
                        <p
                          className='my-0 px-2'
                          style={{
                            color: 'var(--main-color-one)',
                            fontWeight: 600,
                            fontSize: 16,
                            textAlign: 'right',
                            userSelect: 'none'
                          }}
                        >
                          ادخل كود خصم
                        </p>
                        <div className='col-12 coupon-form p-0'>
                          <TextField
                            type='search'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{ flex: 1 }}
                            size='small'
                            label='كود الخصم'
                            onChange={(e) =>
                              setCoupon({ value: e.currentTarget.value })
                            }
                            value={coupon.value}
                          />
                          <LoadingButton
                            classes='btn primary-btn'
                            style={{ height: "40px" }}
                            handleClick={applyCouponCode}
                            label='تطبيق'
                            loading={loadingCouponButton}
                          />
                        </div>
                        {coupon?.isValid && (
                          <Alert
                            variant='standard'
                            icon={false}
                            sx={{
                              width: "100%",
                              border: "1px solid #21B000",
                              '& .MuiAlert-message': {
                                width: '100%'
                              }
                            }}
                            color='success'>
                            <div className="d-flex flex-column text-right w-100" style={{ gap: '10px', direction: "rtl" }}>
                              <span style={{ fontSize: '16px', fontWeight: 600 }}>الخصم</span>
                              <div className="d-flex justify-content-between w-100">
                                <span style={{ fontSize: '13px' }}>{`%${(+coupon.discount).toFixed(0)} ${+selectedPlan?.duration === 365 ? 'لأول سنة' : 'لأول شهر'}`}</span>
                                <span style={{ fontSize: '13px' }}>{`- ${(((+selectedPlan?.price || 0) * parseFloat(coupon.discount)) / 100).toFixed(2)} ر.س/${+selectedPlan?.duration === 365 ? 'سنة' : 'شهر'}`}</span>
                              </div>
                            </div>
                          </Alert>
                        )}

                        <div className='col-12 mt-2 coupon-details'>
                          {selectedPlan && (
                            <p className='coupon-details-row'>
                              <span>المجموع الفرعي</span>
                              <span
                                style={{ fontSize: 13, fontWeight: 700 }}>
                                {`${((((+selectedPlan?.price || 0) * (1 - (coupon.isValid ? parseFloat(coupon.discount) / 100 : 0))) / 115 * 100)).toFixed(2)} ر.س/${+selectedPlan?.duration === 365 ? 'سنة' : 'شهر'}`}
                              </span>
                            </p>
                          )}
                          <p className='coupon-details-row'>
                            <span style={{ fontWeight: 500 }}>ضريبة القيمة المضافة</span>
                            <span
                              style={{ fontSize: 13, fontWeight: 700 }}>
                              {`${(((+selectedPlan?.price || 0) * (1 - (coupon.isValid ? parseFloat(coupon.discount) / 100 : 0))) / 115 * 15).toFixed(2)} ر.س/${+selectedPlan?.duration === 365 ? 'سنة' : 'شهر'}`}
                            </span>
                          </p>
                          <p className='coupon-details-row'>
                            <span style={{ fontSize: 14, fontWeight: 700 }}>المستحق الآن</span>
                            <span
                              style={{ fontSize: 14, fontWeight: 700 }}>
                              {coupon.isValid
                                ? `${(
                                  (+selectedPlan?.price || 0) * (1 - (parseFloat(coupon.discount) / 100))
                                ).toFixed(2)} ر.س`
                                : `${(+selectedPlan?.price || 0).toFixed(2)} ر.س`}</span>
                          </p>
                          {coupon?.isValid && (
                            <p className='coupon-details-row mt-2'>
                              <span>المستحق بعد انتهاء صلاحية الخصم, في {moment().add(+selectedPlan?.duration === 365 ? 12 : 1, 'months').format('Do MMMM YYYY')}</span>
                              <span
                                style={{ fontSize: 13, fontWeight: 700 }}>
                                {`${(+selectedPlan?.price || 0).toFixed(2)} ر.س/${+selectedPlan?.duration === 365 ? 'سنة' : 'شهر'}`}
                              </span>
                            </p>
                          )}
                        </div>
                        {/* <div style={{ borderTop: "2px solid #ddd" }} />
                      <p
                        className='coupon-details-row px-2 text-right'
                        style={{ gap: "5px" }}>
                        <span style={{ fontSize: 19, fontWeight: 500 }}>
                          الإجمالي&nbsp;
                          <span style={{ fontSize: 12 }}>
                            (شامل ضريبة القيمة المضافة)
                          </span>
                        </span>
                        <span
                          style={{
                            fontSize: 19,
                            fontWeight: 700,
                            whiteSpace: "nowrap",
                          }}>
                          {coupon.isValid
                            ? `${(
                              (+selectedPlan?.price || 0) * (1 - (parseFloat(coupon.discount) / 100))
                            ).toFixed(2)} ر.س`
                            : `${(+selectedPlan?.price || 0).toFixed(2)} ر.س`}
                        </span>
                      </p> */}
                    
                      </div>
                    </div>
                    <LoadingButton
                      classes='btn primary-btn py-2 text-center pay-button'
                      style={{ borderRadius: "0" }}
                      loading={false}
                      handleClick={() => {
                        openConfirmPopup();
                        // setIsSubmittingButton(true);
                        // getBrokerRegaInfo({ licenseNumber: advertiserNumber }).then((res) => {
                        //   if (res.resCode === 0) {
                        //     if (res.response.isActive === '1') {
                        //       openConfirmPopup();
                        //     } else {
                        //       Swal.fire({
                        //         title: "لا يمكن إتمام العمليّة",
                        //         text: 'رخصة فال خاصتّك منتهيّة الصلاحيّة',
                        //         icon: "error",
                        //         confirmButtonText: t("Close"),
                        //       });
                        //     }
                        //   }
                        // }).catch(() => openConfirmPopup())
                        //   .finally(() => setIsSubmittingButton(false));
                      }}
                      label={(+selectedPlan?.price || 0) === 0 ||
                        (coupon.isValid &&
                          (
                            (+selectedPlan?.price || 0) -
                            ((+selectedPlan?.price || 0) * parseFloat(coupon.discount)) / 100
                          ).toFixed(1) === "0.0")
                        ? t("Next")
                        : "الدفع"} />
                  </>
                }
              </Drawer>
            </ThemeProvider>
          </Hidden>
          <Hidden mdDown>
            <Dialog
              onClose={handleCloseDrawer}
              maxWidth={"xs"}
              className='custom-dialog'
              style={{ direction: "rtl", textAlignLast: "right", zIndex: 9999999 }}
              sx={{
                "& .MuiPaper-root": {
                  margin: 0,
                  width: "100%",
                  zIndex: 9999999,
                },
              }}
              open={openDrawer}>
              <DialogTitle>
                <div className='dialog-head'>
                  <div>
                    <h2 className='mb-0'>ملخص الطلب</h2>
                  </div>
                  <div className="dialog-actions close-only">
                    <IconButton
                      aria-label='close'
                      onClick={handleCloseDrawer}
                      sx={{
                        color: (theme) => theme.palette.grey[500],
                      }}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              </DialogTitle>
              <DialogContent>
                {selectedPlan &&
                  <>
                    <div
                      className={`custom-drawer mx-auto ${searchParams.get('dark') === "true" ? 'dark' : ""}`}
                      style={{
                        padding: "14px 0px",
                        height: 'auto'
                      }}>
                      <div className='coupon-box row px-2' dir='rtl'>
                        <p className='coupon-details-row'>
                          <span>
                            {`الاشتراك بخطّة ${selectedPlan?.name} / ${+selectedPlan?.duration === 365 ? 'سنة' : 'شهر'}`}
                          </span>
                        </p>
                        <p className='coupon-details-row'>
                          <span>سعر الاشتراك (شامل ضريبة القيمة المضافة)</span>
                          <span
                            style={{ fontSize: 15, fontWeight: 700, textDecoration: coupon?.isValid ? 'line-through #d50000' : "none" }}>
                            {`${(+selectedPlan?.price || 0).toFixed(2)} ر.س/${+selectedPlan?.duration === 365 ? 'سنة' : 'شهر'}`}
                          </span>
                        </p>
                        <p
                          className='my-0 px-2'
                          style={{
                            color: 'var(--main-color-one)',
                            fontWeight: 600,
                            fontSize: 16,
                            textAlign: 'right',
                            userSelect: 'none'
                          }}
                        >
                          ادخل كود خصم
                        </p>
                        <div className='col-12 coupon-form p-0'>
                          <TextField
                            type='search'
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{ flex: 1 }}
                            size='small'
                            label='كود الخصم'
                            onChange={(e) =>
                              setCoupon({ value: e.currentTarget.value })
                            }
                            value={coupon.value}
                          />
                          <LoadingButton
                            classes='btn primary-btn'
                            style={{ height: "40px" }}
                            handleClick={applyCouponCode}
                            label='تطبيق'
                            loading={loadingCouponButton}
                          />
                        </div>
                        {coupon?.isValid && (
                          <Alert
                            variant='standard'
                            icon={false}
                            sx={{
                              width: "100%",
                              border: "1px solid #21B000",
                              '& .MuiAlert-message': {
                                width: '100%'
                              }
                            }}
                            color='success'>
                            <div className="d-flex flex-column text-right w-100" style={{ gap: '10px', direction: "rtl" }}>
                              <span style={{ fontSize: '16px', fontWeight: 600 }}>الخصم</span>
                              <div className="d-flex justify-content-between w-100">
                                <span style={{ fontSize: '13px' }}>{`%${(+coupon.discount).toFixed(0)} ${+selectedPlan?.duration === 365 ? 'لأول سنة' : 'لأول شهر'}`}</span>
                                <span style={{ fontSize: '13px' }}>{`- ${(((+selectedPlan?.price || 0) * parseFloat(coupon.discount)) / 100).toFixed(2)} ر.س/${+selectedPlan?.duration === 365 ? 'سنة' : 'شهر'}`}</span>
                              </div>
                            </div>
                          </Alert>
                        )}

                        <div className='col-12 mt-2 coupon-details'>
                          {selectedPlan && (
                            <p className='coupon-details-row'>
                              <span>المجموع الفرعي</span>
                              <span
                                style={{ fontSize: 13, fontWeight: 700 }}>
                                {`${((((+selectedPlan?.price || 0) * (1 - (coupon.isValid ? parseFloat(coupon.discount) / 100 : 0))) / 115 * 100)).toFixed(2)} ر.س/${+selectedPlan?.duration === 365 ? 'سنة' : 'شهر'}`}
                              </span>
                            </p>
                          )}
                          <p className='coupon-details-row'>
                            <span style={{ fontWeight: 500 }}>ضريبة القيمة المضافة</span>
                            <span
                              style={{ fontSize: 13, fontWeight: 700 }}>
                              {`${(((+selectedPlan?.price || 0) * (1 - (coupon.isValid ? parseFloat(coupon.discount) / 100 : 0))) / 115 * 15).toFixed(2)} ر.س/${+selectedPlan?.duration === 365 ? 'سنة' : 'شهر'}`}
                            </span>
                          </p>
                          <p className='coupon-details-row'>
                            <span style={{ fontSize: 14, fontWeight: 700 }}>المستحق الآن</span>
                            <span
                              style={{ fontSize: 14, fontWeight: 700 }}>
                              {coupon.isValid
                                ? `${(
                                  (+selectedPlan?.price || 0) * (1 - (parseFloat(coupon.discount) / 100))
                                ).toFixed(2)} ر.س`
                                : `${(+selectedPlan?.price || 0).toFixed(2)} ر.س`}</span>
                          </p>
                          {coupon?.isValid && (
                            <p className='coupon-details-row mt-2'>
                              <span>المستحق بعد انتهاء صلاحية الخصم, في {moment().add(+selectedPlan?.duration === 365 ? 12 : 1, 'months').format('Do MMMM YYYY')}</span>
                              <span
                                style={{ fontSize: 13, fontWeight: 700 }}>
                                {`${(+selectedPlan?.price || 0).toFixed(2)} ر.س/${+selectedPlan?.duration === 365 ? 'سنة' : 'شهر'}`}
                              </span>
                            </p>
                          )}
                        </div>
                        {/* <div style={{ borderTop: "2px solid #ddd" }} />
                      <p
                        className='coupon-details-row px-2 text-right'
                        style={{ gap: "5px" }}>
                        <span style={{ fontSize: 19, fontWeight: 500 }}>
                          الإجمالي&nbsp;
                          <span style={{ fontSize: 12 }}>
                            (شامل ضريبة القيمة المضافة)
                          </span>
                        </span>
                        <span
                          style={{
                            fontSize: 19,
                            fontWeight: 700,
                            whiteSpace: "nowrap",
                          }}>
                          {coupon.isValid
                            ? `${(
                              (+selectedPlan?.price || 0) * (1 - (parseFloat(coupon.discount) / 100))
                            ).toFixed(2)} ر.س`
                            : `${(+selectedPlan?.price || 0).toFixed(2)} ر.س`}
                        </span>
                      </p> */}
                    
                      </div>
                    </div>
                    <LoadingButton
                      classes='btn primary-btn py-2 text-center w-100 d-flex justify-content-center'
                      loading={false}
                      handleClick={() => {
                        openConfirmPopup();
                        // setIsSubmittingButton(true);
                        // getBrokerRegaInfo({ licenseNumber: advertiserNumber }).then((res) => {
                        //   if (res.resCode === 0) {
                        //     if (res.response.isActive === '1') {
                        //       openConfirmPopup();
                        //     } else {
                        //       Swal.fire({
                        //         title: "لا يمكن إتمام العمليّة",
                        //         text: 'رخصة فال خاصتّك منتهيّة الصلاحيّة',
                        //         icon: "error",
                        //         confirmButtonText: t("Close"),
                        //       });
                        //     }
                        //   }
                        // }).catch(() => openConfirmPopup())
                        //   .finally(() => setIsSubmittingButton(false));
                      }}
                      label={(+selectedPlan?.price || 0) === 0 ||
                        (coupon.isValid &&
                          (
                            (+selectedPlan?.price || 0) -
                            ((+selectedPlan?.price || 0) * parseFloat(coupon.discount)) / 100
                          ).toFixed(1) === "0.0")
                        ? t("Next")
                        : "الدفع"} />
                  </>
                }
              </DialogContent>
            </Dialog>
          </Hidden>
          <Dialog
            onClose={() => {
              setOpenMoyasarForm(false);
            }}
            maxWidth={"sm"}
            className='custom-dialog'
            style={{ direction: "rtl", textAlignLast: "right", zIndex: 9999999 }}
            sx={{
              "& .MuiPaper-root": {
                margin: 0,
                width: "calc(100% - 20px)",
                zIndex: 9999999,
              },
            }}
            open={openMoyasarForm}>
            <DialogTitle>
              <div className='dialog-head mb-4'>
                <div className='dialog-actions close-only'>
                  <IconButton
                    aria-label='close'
                    onClick={() => {
                      setOpenMoyasarForm(false);
                    }}
                    sx={{
                      color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className='mysr-form'></div>
            </DialogContent>
          </Dialog>
        </>
      )}
      {token && !loadingPlans && !loadingUserPlan && <ContactusPopover />}
      {(waitingResponse) && (
        <div className='progress-area'>
          <h3>{t("PleaseWait")}</h3>
          <CircularProgress size={100} sx={{ color: '#fff' }} />
        </div>
      )}
    </AppLayout>
  );
}
