import { Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Close } from '@mui/icons-material';
import LoadingButton from './global-components/loading-btn';
import { connectWithWhatsApp, getWLProfile, sendWhatsAppMessage } from '../api/userApi';
import { useAuth } from '../helpers/context';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { resolvePhonePrefix, trimPhone } from '../helpers';

export default function SendWhatsAppMsg({ dialog, setDialog }) {
    const [isSendingWhatsAppMsg, setIsSendingWhatsAppMsg] = useState(false);
    const [errors, setErrors] = useState(false);
    const [selectedNumbers, setSelectedNumbers] = useState([]);
    const { token, vendor } = useAuth();
    const [number, setNumber] = useState('');
    const [checkingWhatsAppLink, setCheckingWhatsAppLink] = useState(0);
    const history = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (dialog.visible) {
            getWLProfile({ userName: vendor, language: "0" }).then((_userInfo) => {
                if (_userInfo.resCode === 0) {
                    setNumber(_userInfo.response.wlUser.whatsapp);
                    connectWithWhatsApp({ userId: token, language: "0" }).then(res => {
                        if (res.resCode === 0) {
                            if (res.response.message === 'Already logged in')
                                setCheckingWhatsAppLink(1);
                            else
                                setCheckingWhatsAppLink(2);
                        } else {
                            // toast.error(res.resStr);
                            setCheckingWhatsAppLink(3);
                        }
                    });
                }
            });
            setSelectedNumbers(dialog.numbers);
        }
    }, // eslint-disable-next-line
        [dialog.visible]);
    
    const sendMessage = () => {
        setIsSendingWhatsAppMsg(true);
        sendWhatsAppMessage({
            userId: token,
            number: resolvePhonePrefix(number),
            to: dialog.numbers.map(item => resolvePhonePrefix(item)),
            message: dialog.message
        }).then(res => {
            if (res.resCode === 0) {
                toast.success(t("MessageSentSuccessfully"));
                setDialog({ visible: false, numbers: [], message: '', customers: [] })
            }
            else
                toast.error(res.resStr);
            
        }).finally(() => setIsSendingWhatsAppMsg(false));
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={dialog.multiple ? "md" : 'xs'}
            open={dialog.visible}
            className="custom-dialog model "
            style={{ direction: "rtl", textAlignLast: "right" }}
            onClose={() => {
                setDialog({ visible: false, numbers: [], message: '', customers: [] });
                setCheckingWhatsAppLink(0);
            }}>
            <DialogTitle>
                <div className="dialog-head">
                    <div>
                        <h2> {dialog.multiple ? 'التواصل مع العملاء المهتمين في هذا العقار عبر الواتساب' : 'التواصل مع العميل عبر الواتساب'}</h2>
                    </div>
                    <div className="dialog-actions">
                        <span onClick={() => {
                            setDialog({ visible: false, numbers: [], message: '', customers: [] });
                            setCheckingWhatsAppLink(0);
                        }}
                            style={{ cursor: 'pointer' }}>
                            <Close />
                        </span>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    {dialog.multiple && (
                        <Grid item xs={12} lg={7}>
                            <Divider textAlign="left" className="mt-2 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
                                ارقام العملاء
                            </Divider>
                          
                            <FormGroup>
                                {dialog.customers.map((item, index) => (
                                    <FormControlLabel key={index} control={<Checkbox checked={selectedNumbers.includes(item.phone)} onChange={(event) => {
                                        setSelectedNumbers((old) => event.target.checked ? [...old, item.phone] : old.filter(i => i !== item.phone));
                                    }} />} label={item.name + ', ' + trimPhone(item.phone)} />
                                ))}
                            </FormGroup>
                            <FormHelperText dir="rtl">
                                {errors && dialog.numbers.length === 0 ?
                                    <Alert severity="error" style={{ width: 'fit-content' }} className="custom-alert">
                                        الرجاء تحديد رقم واحد على الأقل
                                    </Alert>
                                    : ''
                                }
                            </FormHelperText>
                        </Grid>
                    )}
                 
                    <Grid item xs={12} lg={dialog.multiple ? 5 : 12}>
                        {dialog.multiple && <Divider textAlign="left" className="mt-2 mb-3 p-0" style={{ color: 'var(--main-color-one)' }}  >
                            {t("Message")}
                        </Divider>}
                        <FormControl className="my-2 w-100">
                            <TextField
                                label={t("Message")}
                                required
                                sx={{ width: "100%", direction: 'rtl' }}
                                className="text-right"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={dialog.multiple ? 'يرجى كتابة الرسالة المراد إرسالها للعملاء' : 'يرجى كتابة الرسالة المراد إرسالها للعميل'}
                                value={dialog.message}
                                size='small'
                                multiline
                                rows={11}
                                onChange={(e) =>
                                    setDialog({
                                        ...dialog,
                                        message: e.currentTarget.value,
                                    })
                                }
                            />
                            <FormHelperText dir="rtl">
                                {errors && !dialog.message ?
                                    <Alert severity="error" style={{ width: 'fit-content' }} className="custom-alert">
                                        {t("ThisFieldIsMandatory")}
                                    </Alert>
                                    : ''
                                }
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                     
                        
                </Grid>
            </DialogContent>
            <DialogActions>
                <div className='w-100 d-flex align-items-center justify-content-between flex-column flex-sm-row' style={{gap:'10px',paddingInline:'14px'}}>
                    {checkingWhatsAppLink === 0 ? <p>جاري التحقق من الحدمة...</p> : checkingWhatsAppLink === 2 ? (
                         <Alert
                         sx={{ "& .MuiAlert-message": { width: "100%" } }}
                         severity='error'
                         className='custom-alert align-items-center'
                         dir='rtl'>
                         <div className='d-flex justify-content-between align-items-sm-center flex-column flex-sm-row'>
                           <span>
                            لم يتم الربط مع واتساب
                           </span>
                              <Button
                              style={{
                                width: "fit-content",
                                borderColor: "#222",
                                color: "#222",
                              }}
                              className='mt-2 mt-md-0 mr-3'
                              variant='outlined'
                              onClick={()=>history('/admin/link-with-whatsapp')}>
                              الربط الآن
                            </Button>
                         </div>
                             </Alert>
            ):checkingWhatsAppLink === 3 && <p>الخدمة غير متوفرة</p>}
               
                    <LoadingButton
                    classes='btn primary-btn px-4'
                    OKdisabled={checkingWhatsAppLink !== 1}
                    loading={isSendingWhatsAppMsg}
                    handleClick={(e) => {
                        if (dialog.message === "" || dialog.numbers.length === 0) {
                            setErrors(true);
                        }
                        else
                            sendMessage();
                    }}
                    label={t("Send")}
                />
                </div>
              
            </DialogActions>
        </Dialog>
    );
}