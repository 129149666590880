import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addOrEditProjectDetails, getProjects } from "../../../api/userApi";
import { toast } from "react-toastify";
import { Plus } from "../../../constants/icons";
import { getDemoSnakbar, openDeleteModal } from "../../../helpers";
import LoadingData from "../../../components/global-components/loading-data";
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import InterestsModal from "./components/interests-modal";
import AccessDenied from "../../shared/access-denied";
import { DEMO_VENDORS } from "../../../constants";
import { Fab, Grid } from "@mui/material";
import NoData from "../../../components/global-components/no-data";
import ProjectCardForAdmin from "./components/project-card-for-admin";
import ActionsPopper from "../../../components/global-components/actions-popper";
import { useTranslation } from "react-i18next";

export default function Projects() {
  const { token, vendor, currentPlan } = useAuth();
  const [ProjectItems, setProjectItems] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [openInterestsModal, setOpenInterestsModal] = useState({ visible: false, data: [], projectName: '' });
  const history = useNavigate();
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [openPopper, setOpenPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedProject, setSelectedProject] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (vendor && currentPlan?.plan?.id && +currentPlan?.plan?.id === 9) {
      setLoadingData(true);
      getProjects({
        userName: vendor,
        userId: token,
        language: "0",
      }).then((_response) => {
        if (_response.resCode === 0)
          setProjectItems(_response.response);
        setLoadingData(false);
      });
    }
  }, [vendor, token, currentPlan]);

  const handleDelete = (projectId) => {
    addOrEditProjectDetails({
      projectId: projectId,
      userId: token,
      language: "0",
      isDelete: "1",
    }).then((property) => {
      if (property.resCode === 0) {
        toast.success(t("TheProjectHasBeenDeleted"));
        getProjects({
          userName: vendor,
          language: "0",
          userId: token
        }).then((_response) => {
          if (_response.resCode === 0)
            setProjectItems(_response.response);
        });
      }
    });
  };

  const handlePublishStatus = (projectId, status) => {
    addOrEditProjectDetails({
      projectId: projectId,
      userId: token,
      language: "0",
      publishStatus: status
    }).then((property) => {
      if (property.resCode === 0) {
        toast.success(status === '0' ? t('TheProjectHasBeenUnpublished') : t('TheProjectPublished'));
        getProjects({
          userName: vendor,
          language: "0",
          userId: token
        }).then((_response) => {
          if (_response.resCode === 0)
            setProjectItems(_response.response);
        });
      }
    });
  };
  
  return currentPlan?.plan?.id && +currentPlan?.plan?.id === 9 ? (
    <>
      <SubHeader
        OKElement={
          <Fab
            size="large"
            color="primary"
            className="ok-fab"
            onClick={() => history('/admin/project')}
          >
            <Plus sx={{ fontSize: 30 }} />
            <span>{t('AddNewProject')}</span>
          </Fab>
        }
      />
    
      <div className="row m-0 pb-4">
        {loadingData ? <LoadingData /> :
          ProjectItems.length > 0 ?
            (<>
              <Grid container spacing={2}>
                {ProjectItems.map((item, i) => (
                  <Grid item xs={12} md={6} lg={4} xl={3} key={i} >
                    <ProjectCardForAdmin
                      item={item}
                      setOpenPopper={setOpenPopper}
                      setAnchorEl={setAnchorEl}
                      openPopper={openPopper}
                      anchorEl={anchorEl}
                      setSelectedProject={setSelectedProject}
                    />
                  </Grid>
                ))}
              </Grid>
              <ActionsPopper
                openPopper={openPopper}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setOpenPopper={setOpenPopper}
                items={
                  [
                    {
                      title: t('ViewDetails'),
                      action: () => history(`/admin/project-details/${selectedProject.id}`)
                    },
                    {
                      title: t('Edit'),
                      action: () => history(`/admin/edit-project/${selectedProject.id}`)
                    },
                    {
                      title: t('Delete'),
                      action: () => {
                        if (DEMO_VENDORS.includes(vendor)) {
                          setOpenDemoSnackBar(true);
                        } else {
                          openDeleteModal(t("ConfirmDeletion"), t("AreYouSureToDeleteThisProject"), () => handleDelete(selectedProject.id))
                        }
                      }
                    },
                    {
                      title: t("ShowInterests"),
                      action: () => setOpenInterestsModal({
                        visible: true,
                        data: selectedProject.interests,
                        projectName: selectedProject.name
                      })
                    },
                    {
                      title: selectedProject?.publishStatus === '1' ? t("Unpublish") : t("Publish"),
                      action: () => {
                        if (DEMO_VENDORS.includes(vendor)) {
                          setOpenDemoSnackBar(true);
                        } else {
                          if (selectedProject.publishStatus === '1')
                            openDeleteModal(t("ConfirmUnpublishing"), t("AreYouSureYouWantToUnpublishThisProject"), () => handlePublishStatus(selectedProject.id, '0'), t("Unpublish"))
                          else
                            openDeleteModal(t("ConfirmPublication"), t("AreYouSureToPublishThisProject"), () => handlePublishStatus(selectedProject.id, '1'), t("Publish"));
                        }
                      }
                    }
                  ]
                }
              />
            </>) : <NoData msg={t("ThereAreNoProjects")} />
        }

        <InterestsModal
          open={openInterestsModal.visible}
          data={openInterestsModal.data || []}
          onClose={() => setOpenInterestsModal({ visible: false, data: [], projectName: '' })}
          loadingData={false}
          projectName={openInterestsModal.projectName}
        />
        {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

      </div>
    </>
  ) : (
    <AccessDenied
      btnLink={'/admin/plans'}
      btnText={t("GoToSubscriptionPage")}
      desc={t("YouMustUpgradeYourCurrentPlanToAccessThisPage")}
    />
  );

}
