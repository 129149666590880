import { Box, Card, CardContent, CardHeader, Chip, ClickAwayListener, Dialog, DialogContent, DialogTitle, Divider, Fab, FormControl, FormControlLabel, Grow, IconButton, MenuItem, Pagination, Paper, Popper, Radio, RadioGroup, Select, Skeleton, Table, TableBody, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loadingdata from '../../../components/global-components/loading-data';
import NoData from '../../../components/global-components/no-data';
import SubHeader from "../../../components/global-components/sub-header";
import { useAuth } from "../../../helpers/context";
import {  useNavigate } from "react-router-dom";
import { getDemoSnakbar, openDeleteModal } from "../../../helpers";
import { Plus } from "../../../constants/icons";
import TuneIcon from '@mui/icons-material/Tune';
import { DEMO_VENDORS } from "../../../constants";
import { changeTaskStatus, deleteTask, getAgentsForCompany, getTasks, getUserProile } from "../../../api/userApi";
import { toast } from "react-toastify";
import TableCell from "@mui/material/TableCell";
import TableChartIcon from '@mui/icons-material/TableChart';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import LoadingButton from "../../../components/global-components/loading-btn";
import TasksBoard from "./components/tasks-board";
import TaskDetails from "./task-details";
import moment from "moment";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ActionsPopper from "../../../components/global-components/actions-popper";
import { useTranslation } from "react-i18next";
import AddCommentDialog from "./components/add-comment-dialog";

const pageSize = 10;

const TasksPage = () => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [openDemoSnackBar, setOpenDemoSnackBar] = React.useState(false);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const { token, vendor, AccountType, UserType, UserId } = useAuth();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [openPopper, setOpenPopper] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [agents, setAgents] = React.useState([]);
  const [userName, setUserName] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [tableView, setTableView] = useState(false);
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
  const [openChangeStatusDialog, setOpenChangeStatusDialog] = useState({
    open: false,
    id: '',
    status: -1
  });
  const history = useNavigate();
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState({
    status: 'all',
    added_by: "all",
    updatedAt: 'all',
    priority: 'all',
    assignedTo: "all"
  });
  const [task, setTask] = useState({});
  const [openSettingsPopper, setOpenSettingsPopper] = useState(false);
  const [anchorSettingsEl, setAnchorSettingsEl] = React.useState(null);
  const [openPopper2, setOpenPopper2] = useState(false);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const { t } = useTranslation();
  const [addCommentDialog, setAddCommentDialog] = useState({ visible: false, id: '' });

  const getData = async (withoutLoading = false) => {
    if (!withoutLoading) setLoadingData(true);
   
    getTasks({
      userId: token,
      page: page,
      language: '0',
      search: search,
      status: filters.status === 'all' ? undefined : filters.status,
      added_by: filters.added_by === "all" ? undefined : filters.added_by,
      updatedAt: filters.updatedAt === "all" ? '' : filters.updatedAt,
      priority: filters.priority === 'all' ? undefined : filters.priority,
      assignedTo: ""
    }).then((res) => {
      if (res.resCode === 0) {
        setData(res.response.array);
        setTotalCount(res.response.totalCount);
      } else {
        toast.error(res.resStr);
      }
    }).finally(() => setLoadingData(false));
  };

  useEffect(() => {
    if (UserType !== undefined)
      getData();
  },// eslint-disable-next-line
    [token, page, search, filters, UserType]);
  
  
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleDelete = (id) => {
    deleteTask({
      userId: token,
      language: "0",
      taskId: id
    }).then((res) => {
      if (res.resCode === 0) {
        toast.success('تمّ حذف المهمّة بنجاح');
        getData();
      } else {
        toast.error(res.resStr);
      }
    })
  }

  const hasFilter = () => {
    return filters.updatedAt !== 'all' || filters.added_by !== "all" || filters.status !== "all" || filters.priority !== "all";
  }
  
  useEffect(() => {
    if (token && AccountType === "2" && UserType !== "5") {
      getUserProile({ userId: token, language: "0" }).then(user => {
        if (user.resCode === 0) {
          setUserName(user.response.userProfile.name);
          setCompanyName(user.response.userProfile.companyName);
        }
      });
      getAgentsForCompany({ superUserId: token }).then((users) => {
        setAgents(users.response.userArray);
      });
    }
      
  },// eslint-disable-next-line
    [token, AccountType]);

  
  const renderFilterationBox = () => {
    return (
      <Box sx={{ backgroundColor: '#fff', width: '300px', direction: 'rtl', textAlign: 'right', display: 'flex', flexDirection: 'column', px: 2, pt: 2 }}>
        <div className="filters-box">
          <h6 className="mt-2">{t("Status")}</h6>
          <div className="col-12 mb-3 p-0">
            <FormControl className="custom-select">
              <Select
                value={filters.status}
                sx={{ width: "100%" }}
                inputProps={{
                  name: "updatedAt",
                  id: "updatedAt",
                }}
                onChange={(event) => {
                  setFilters({
                    ...filters,
                    status: event.target.value,
                  });
                }}
              >
                <MenuItem value='all'>{t("All")}</MenuItem>
                <MenuItem value='0'>{t("New")}</MenuItem>
                <MenuItem value='1'>{t("InProgress")}</MenuItem>
                <MenuItem value='2'>{t("Postponed")}</MenuItem>
                <MenuItem value='3'>{t("Done")}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Divider variant="inset" className="w-100" />
          <h6 className="mt-2">{t("Priority")}</h6>
          <div className="col-12 mb-3 p-0">
            <FormControl className="custom-select">
              <Select
                value={filters.priority}
                sx={{ width: "100%" }}
                inputProps={{
                  name: "priority",
                  id: "priority",
                }}
                onChange={(event) => {
                  setFilters({
                    ...filters,
                    priority: event.target.value,
                  });
                }}
              >
                <MenuItem value='all'>{t("All")}</MenuItem>
                <MenuItem value='0'>{t("Low")}</MenuItem>
                <MenuItem value='1'>{t("Medium")}</MenuItem>
                <MenuItem value='2'>{t("High")}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Divider variant="inset" className="w-100" />
          <h6 className="mt-2">{t("AssignedTo")}</h6>
          <div className="col-12 mb-3 p-0">
            <FormControl className="custom-select">
              <Select
                value={filters.assignedTo}
                sx={{ width: "100%" }}
                inputProps={{
                  name: "assignedTo",
                  id: "assignedTo",
                }}
                onChange={(event) => {
                  setFilters({
                    ...filters,
                    assignedTo: event.target.value,
                  });
                }}
              >
                <MenuItem value='all' key="all">{t("All")}</MenuItem>
                <MenuItem value={UserId} key="mother-account">{userName === companyName ? t("MainAccount") : userName}</MenuItem>
                {agents?.map(agent => <MenuItem value={agent.userId} key={agent.userId}>{agent.name}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
          {AccountType === '2' && agents?.length > 0 && UserType !== "5" && (
            <>
              <Divider variant="inset" className="w-100" />
              <h6 className="mt-2">{t("AddedBy")}</h6>
              <div className="col-12 mb-3 p-0">
                <FormControl className="custom-select">
                  <Select
                    value={filters.added_by}
                    sx={{ width: "100%" }}
                    inputProps={{
                      name: "agentId",
                      id: "agentId",
                    }}
                    onChange={(event) => {
                      setFilters({
                        ...filters,
                        added_by: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value='all' key="all">{t("All")}</MenuItem>
                    <MenuItem value={UserId} key="mother-account">{userName === companyName ? t("MainAccount"): userName}</MenuItem>
                    {agents?.map(agent => <MenuItem value={agent.userId} key={agent.userId}>{agent.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </div>
            </>
          )}
          <Divider variant="inset" className="w-100" />
          <h6 className="mt-2">{t("UpdatedAt")}</h6>
          <div className="col-12 mb-3 p-0">
            <FormControl className="custom-select">
              <Select
                value={filters.updatedAt}
                sx={{ width: "100%" }}
                inputProps={{
                  name: "updatedAt",
                  id: "updatedAt",
                }}
                onChange={(event) => {
                  setFilters({
                    ...filters,
                    updatedAt: event.target.value,
                  });
                }}
              >
                <MenuItem value='all'>{t("All")}</MenuItem>
                <MenuItem value='month'>{t("AMonthAgo")}</MenuItem>
                <MenuItem value='quarter'>{t("3MonthsAgo")}</MenuItem>
                <MenuItem value='year'>{t("AYearAgo")}</MenuItem>
              </Select>
            </FormControl>
          </div>
        
        </div>
        <div className="col-12 mb-3 p-0 mt-3 mx-0 row justify-content-center" style={{ gap: 15 }}>
         
          <button
            className={`btn action-btn primary-btn`}
            onClick={() => {
              setFilters({
                status: 'all',
                added_by: "all",
                updatedAt: 'all',
                priority: 'all',
                assignedTo: 'all'
              });
            }
            }
          >{t("Reset")}</button>
        </div>
      </Box>
    );
  }
  
  const resolveStatusName = (status) => {
    const badge = (statusName) => {
      return <Chip label={statusName} size="medium" style={{
        color: status === '0' ? 'gold' : status === '1' ? "blue" : status === '3' ? 'purple' : status === '2' ? 'black' : '#d50000',
        borderColor: status === '0' ? 'gold' : status === '1' ? "blue" : status === '3' ? 'purple' : status === '2' ? 'black' : '#d50000',
        background: '#fff',
        borderWidth: '1px',
        borderStyle: 'solid'
      }} />;
    };
    
    switch (status) {
      case '0': return badge(t("New"));
      case '1': return badge(t("InProgress"));
      case '3': return badge(t("Done"));
      case '2': return badge(t("Postponed"));
      default: return '';
    }
  }
  
  return (
    <>
      <SubHeader
        OKElement={
          <Fab
            size="large"
            color="primary"
            className="ok-fab"
            onClick={() => history('/admin/add-task')}
          >
            <Plus sx={{ fontSize: 30 }} />
            <span>مهمة جديد</span>
          </Fab>
        }
      />
       
      <div style={{ display: 'flex', flex: 1, width: '100%', flexDirection: 'column', textAlign: 'right', marginBottom: '50px' }}>
        <div
          style={{ direction: "rtl", width: "100%", display: "flex", gap: 15, marginBottom: 15, alignItems: 'center', flexWrap: 'wrap' }}>
          <TextField
            id='standard-search'
            style={{ width: "60%", background: '#fff' }}
            placeholder='ابحث بواسطة عنوان أو وصف المهمّة'
            type='search'
            size='small'
            value={search}
            InputLabelProps={{
              shrink: true,
            }}
            variant='outlined'
            onChange={(e) => {
              setSearch(e.currentTarget.value);
            }}
          />
             
          <button className={`btn ${hasFilter() ? 'primary-btn' : 'outlined-btn'} action-btn`} style={{ width: '20%', minWidth: 120 }} onClick={(e) => {
            setOpenPopper(!openPopper);
            setAnchorEl(anchorEl ? null : e.target);
          }}>
        {t("Filter")}    <TuneIcon />
          </button>
          <button className={`btn primary-btn action-btn`} style={{ width: '20%', minWidth: 160 }} onClick={() => setTableView(!tableView)}>
            {tableView ? (<> <ViewWeekIcon /><span> طريقة عرض البطاقات </span></>) : (<><TableChartIcon /><span> طريقة عرض الجدول</span></>)}
          </button>
          <Popper
            sx={{
              zIndex: 100,
              offset: '55px 0 0 5px',
            }}
            open={openPopper}
            role={undefined}
            transition
            anchorEl={anchorEl}
            disablePortal={false}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={(event) => {
                    if (event.target.nodeName === 'BODY' && event.type === 'click') {
                      return;
                    } else {
                      setAnchorEl(null); setOpenPopper(false);
                    }
                       
                  }}>
                    {renderFilterationBox()}
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        {tableView ? (
          <>
            <TableContainer className="table-container" component={Paper}>
              <Table sx={{ minWidth: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">العنوان</TableCell>
                    <TableCell align="center">{t("Description")}</TableCell>
                    <TableCell align="center">{t("Status")}</TableCell>
                    <TableCell align="center">{t("Deadline")}</TableCell>
                    <TableCell align="center">{t("AssignedTo")}</TableCell>
                    <TableCell align="center">{t("Actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingData ? <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  ><TableCell colSpan={6}><Loadingdata /></TableCell></TableRow> :
                    data?.length > 0 ? data.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell align="center">
                          <span style={{ color: 'var(--main-color-one)', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => {
                            setTask(row);
                            setOpenTaskDialog(true);
                          }}>
                            {row.summary}
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          {row?.description}
                        </TableCell>
                        <TableCell align="center">{resolveStatusName(row.status)}</TableCell>
                        <TableCell align="center">
                          {row.deadline ? moment(row.deadline).locale('en').format("YYYY-MM-DD") : t("Undefined")}
                        </TableCell>
                        <TableCell align="center">
                          {row.assignedTo === companyName ? t("MainAccount") : row.assignedTo}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={(e) => {
                              setOpenPopper2(!openPopper2);
                              setAnchorEl2(anchorEl2 ? null : e.target);
                              setTask(row);
                            }}
                            sx={{
                              background: '#fff',
                              zIndex: 99,
                              color: 'var(--main-color-one)',
                              boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                              '&:hover,&:focus': {
                                color: '#fff',
                                background: 'var(--main-color-one)'
                              }
                            }}>
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>

                      </TableRow>
                    )) :
                      <TableRow
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      ><TableCell colSpan={6}><NoData msg="لم يتم العثور على مهام" /></TableCell></TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <ActionsPopper
              openPopper={openPopper2}
              anchorEl={anchorEl2}
              setAnchorEl={setAnchorEl2}
              setOpenPopper={setOpenPopper2}
              items={
                [
                  {
                    title: t("ViewDetails"),
                    action: () => setOpenTaskDialog(true)
                  },
                  userName !== task.owner && UserType === "5" ? null : {
                    title: t("Edit"),
                    action: () => history(`/admin/edit-task/${task.id}`)
                  },
                  {
                    title: t("AddComment"),
                    action: () => setAddCommentDialog({ visible: true, id: task.id })
                  },
                  userName !== task.owner && UserType === "5" ? null : {
                    title: t("Delete"),
                    action: () => {
                      if (DEMO_VENDORS.includes(vendor)) {
                        setOpenDemoSnackBar(true);
                      } else {
                        openDeleteModal(t("ConfirmDeletion"), t("AreYouSureToDeleteThisTask"),
                          () => handleDelete(task.id));
                      }
                    }
                  },
                  userName !== task.owner && UserType === "5" ? null : {
                    title: t("ChangeStatus"),
                    action: () => {
                      if (DEMO_VENDORS.includes(vendor)) {
                        setOpenDemoSnackBar(true);
                      } else {
                        setOpenChangeStatusDialog({
                          open: true,
                          id: task.id,
                          status: +task.status
                        })
                      }
                    }
                  }
                ]
              }
            />
          </>
        ) : (
          <div className="deal-cards">
            {!loadingData ? (
              <TasksBoard
                data={data}
                task={task}
                companyName={companyName}
                openSettingsPopper={openSettingsPopper}
                setAnchorSettingsEl={setAnchorSettingsEl}
                anchorSettingsEl={anchorSettingsEl}
                userName={userName}
                handleDelete={handleDelete}
                onOK={() => getData(true)}
                setOpenDemoSnackBar={setOpenDemoSnackBar}
                UserType={UserType}
                setTask={setTask}
                setOpenTaskDialog={setOpenTaskDialog}
                setOpenChangeStatusDialog={setOpenChangeStatusDialog}
                setOpenSettingsPopper={setOpenSettingsPopper}
              />
            ) : <>
              <Card className="deal-card">
                <CardHeader
                  title={t("New")}
                  sx={{
                    paddingBottom: '10px',
                    borderBottom: `3px solid gold`,
                    '& .MuiTypography-root': {
                      fontWeight: 600,
                      fontSize: '15px'
                    }
                  }}
                />
                <CardContent>
                  <Skeleton width={'100%'} height={'100%'} />
                </CardContent>
              </Card>
              <Card className="deal-card">
                <CardHeader
                  title={t("InProgress")}
                  sx={{
                    paddingBottom: '10px',
                    borderBottom: `3px solid blue`,
                    '& .MuiTypography-root': {
                      fontWeight: 600,
                      fontSize: '15px'
                    }
                  }}
                />
                <CardContent>
                  <Skeleton width={'100%'} height={'100%'} />
                </CardContent>
              </Card>
             
              <Card className="deal-card">
                <CardHeader
                  title={t("Postponed")}
                  sx={{
                    paddingBottom: '10px',
                    borderBottom: `3px solid purple`,
                    '& .MuiTypography-root': {
                      fontWeight: 600,
                      fontSize: '15px'
                    }
                  }}
                />
                <CardContent>
                  <Skeleton width={'100%'} height={'100%'} />
                </CardContent>
              </Card>
              <Card className="deal-card">
                <CardHeader
                  title={t("Done")}
                  sx={{
                    paddingBottom: '10px',
                    borderBottom: `3px solid black`,
                    '& .MuiTypography-root': {
                      fontWeight: 600,
                      fontSize: '15px'
                    }
                  }}
                />
                <CardContent>
                  <Skeleton width={'100%'} height={'100%'} />
                </CardContent>
              </Card>
            </>}
          </div>
        )}
           
        {totalCount > pageSize && (
          <Pagination
            sx={{ '& .MuiPagination-ul': { flexDirection: 'row', marginTop: '15px', justifyContent: 'end' } }}
            count={parseInt(Math.ceil(totalCount / pageSize))}
            page={page}
            onChange={handlePageChange}
            color="primary" />
        )}
      </div>
       
      <AddCommentDialog
        onOK={() => getData(true)}
        dialog={addCommentDialog}
        setDialog={setAddCommentDialog}
      />
      

      <TaskDetails
        openTaskDialog={openTaskDialog}
        setOpenTaskDialog={setOpenTaskDialog}
        setTask={setTask}
        getData={() => getData(true)}
        companyName={companyName}
        task={task} />
      {DEMO_VENDORS.includes(vendor) && getDemoSnakbar(openDemoSnackBar, setOpenDemoSnackBar)}

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={openChangeStatusDialog.open}
        className="custom-dialog model"
        style={{ direction: "rtl", textAlignLast: "right" }}
        onClose={() => setOpenChangeStatusDialog({
          open: false,
          id: '',
          status: -1
        })}>
        <DialogTitle>
          <div className="dialog-head">
            <div>
              <h2>تغيير حالة المهمّة</h2>
              <p>قم باختيار الحالة الحاليّة للمهمة</p>
            </div>
            <div className="dialog-actions">
              <LoadingButton
                label={t("Save")}
                classes="px-3 primary-btn"
                loading={loadingChangeStatus}
                handleClick={() => {
                  setLoadingChangeStatus(true);
                  changeTaskStatus({
                    "userId": token,
                    "taskId": openChangeStatusDialog.id,
                    "status": openChangeStatusDialog.status
                  }).then(res => {
                    if (res.resCode === 0) {
                      toast.success('تم تغيير الحالة الحاليّة للمهمة بنجاح');
                      setOpenChangeStatusDialog({
                        open: false,
                        id: '',
                        status: -1
                      });
                      getData();
                    } else {
                      toast.error(res.resStr);
                    }
                  }).finally(() => setLoadingChangeStatus(false));
                }} />

              <button className="px-3 outlined-btn" onClick={() => setOpenChangeStatusDialog({
                open: false,
                id: '',
                status: -1
              })}>
                {t("Cancel")}
              </button>
            </div>
          </div>
          
        </DialogTitle>
        <DialogContent>
          <div className='mt-4 row justify-content-start'>
            <RadioGroup
              sx={{
                '& label': {
                  padding: '0 !important',
                  margin: '0 5px !important'
                },
                fontSize: '14px'
              }}
              value={openChangeStatusDialog.status}
              onChange={(e) => setOpenChangeStatusDialog({ ...openChangeStatusDialog, status: e.target.value, })}
              name="radio-buttons-group"
            >
              <FormControlLabel value={0} key={0} control={<Radio />} label={t("New")} />
              <FormControlLabel value={1} key={1} control={<Radio />} label={t("InProgress")} />
              <FormControlLabel value={2} key={2} control={<Radio />} label={t("Postponed")} />
              <FormControlLabel value={3} key={3} control={<Radio />} label={t("Done")} />
            </RadioGroup>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default TasksPage;
