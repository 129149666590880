import React from 'react'
import { Card, CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function DealCard({ companyName, setOpenDealDialog, setDeal, item, openPopper, setOpenPopper, anchorEl, setAnchorEl }) {
  const { t } = useTranslation();

  return (
    <div className="col-12 mb-3 component" >
      <Card className="deal-inner-card"
        onClick={(e) => {
          if (e.target.tagName !== 'A' && e.target.tagName !== 'svg' && e.target.tagName !== 'path') {
            setDeal(item);
            setOpenDealDialog(true);
          }
      }}>
        <CardHeader
          sx={{ pb: 0 }}
          action={
            <IconButton
              sx={{ padding: 0 }}
              onClick={(e) => {
                setOpenPopper(!openPopper);
                setAnchorEl(anchorEl ? null : e.target);
                setDeal(item);
              }}>
              <MoreVertIcon />
            </IconButton>
          }
          title={item.buyerObject?.name}
          subheader={item.property || item.sellerObject?.name}
          // onClick={(e) => {
          //   if (e?.target?.classList?.value?.indexOf('MuiCardHeader-title') > -1) {
          //     setDeal(item);
          //     setOpenDealDialog(true);
          //   }
          // }}
        />
          
        <CardContent>
          <Typography variant="body2" color="text.secondary" style={{ fontSize: '12px' }}>
            <b>قيمة الصفقة: </b> {Intl.NumberFormat("en").format(item.value.endsWith('.000') ? parseInt(item.value) : item.value) + ' ريال'}
          </Typography>
          {item.comments?.length > 0 && (
            <Typography variant="body2" color="text.secondary" style={{ fontSize: '12px' }}>
              <b>{item.comments?.[0]?.createdBy === companyName ? t("MainAccount") : item.comments?.[0]?.createdBy}:</b> {item.comments?.[0]?.content ? (
                item.comments?.[0]?.content?.length > 56 ? (item.comments?.[0]?.content?.substring(0, 56) + '...') : item.comments?.[0]?.content
              ) : 'لا يوجد'}
            </Typography>
          )}
          <Typography variant="body2" color="text.secondary" style={{ fontSize: '12px' }}>
            <b>تمّ تحديثها: </b> {moment(new Date(item.updatedAt)).add(3, 'hours').fromNow()}
          </Typography>
        </CardContent>
         
      </Card>
    </div>
  );
}