import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { MosquesBG } from "../../constants/backgrounds";
import { useTranslation } from "react-i18next";

export default function VerifyPopup({ trigger, setOpenVerifyModal, openVerifyModal, desc }) {
  const { t } = useTranslation();
  
  return (
    <>
      {trigger}
      <Dialog
        onClose={() => setOpenVerifyModal(false)}
        className="custom-dialog no-padding full-screen2"
        open={openVerifyModal}
      >
        <DialogContent sx={{ padding: 0, overflow: 'hidden' }}>
          <div className='modal2 '>
            <IconButton className='close' onClick={() => setOpenVerifyModal(false)}>
              <Close/>
            </IconButton>
            <div className='login-modal'>
              <div className='col-10 col-sm-9'>
                <h4>حسابك غير موثق</h4>
                <p>{desc}</p>
                <Link
                  to="/verify"
                  className="mt-4 btn primary-btn px-3 mx-auto w-50 justify-content-center"
                >
                 {t("AccountVerification")}
                </Link>
              </div>
            </div>
            <div className="col-12 bottom-image">
              <MosquesBG />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

