import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogContent, IconButton } from "@mui/material";
import React from "react";
import { MosquesBG } from "../../constants/backgrounds";
import { useNavigate } from "react-router";
import { useAuth } from "../../helpers/context";
import { useTranslation } from "react-i18next";

export default function SubscriptionPopup({ trigger, setOpenSubscriptionModal, openSubscriptionModal, desc }) {
  const history = useNavigate();
  const { UserType } = useAuth();
  const { t } = useTranslation();

  return (
    <>
      {trigger}
      <Dialog
        onClose={() => setOpenSubscriptionModal(false)}
        className="custom-dialog no-padding full-screen2"
        open={openSubscriptionModal}
      >
        <DialogContent sx={{ padding: 0, overflow: 'hidden' }}>
          <div className='modal2 '>
            <IconButton className='close' onClick={() => setOpenSubscriptionModal(false)}>
              <Close />
            </IconButton>
            <div className='login-modal'>
              <div className='col-12 col-sm-9 p-0'>
                <h4>
                  {t("UpgradeThePlan")}
                </h4>
                <p>{desc}</p>
                {UserType !== '5' && (<p>للإستمرار بعمليّة الإضافة, يجب عليك ترقية الخطّة الحاليّة</p>)}

                <Button
                  onClick={() => {
                    setOpenSubscriptionModal(false);
                    if (UserType !== '5')
                      history(window.location.href.includes('admin')?'/admin/plans':'/plans-and-prices');
                    else
                      history('/');
                  }}
                  className="mt-4 btn primary-btn px-3 mx-auto w-50 justify-content-center"
                >
                  {UserType !== '5' ? 'الترقية الآن' : t("GoToHomePage")}
                </Button>
              </div>
            </div>
            <div className="col-12 bottom-image">
              <MosquesBG />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

