import React, { useEffect, useState } from 'react'
import { Alert, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

export default function ReservedDomainInfo({ dialog, setDialog }) {
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState();

    const getDomainInfo = async () => {
        try {
            setLoadingData(true);
            let result = await fetch("https://www.whoisxmlapi.com/whoisserver/WhoisService", {
                method: 'POST',
                body: JSON.stringify({
                    domainName: dialog.domain,
                    apiKey: "at_IItwaIYw71iHsPC1ycCSlrzum7P6y",
                    outputFormat: 'JSON'
                })
            });
            const json = await result.json();
            setData(json?.WhoisRecord);
            console.log(json?.WhoisRecord);
        } finally {
            setLoadingData(false);
        }
       
    }
    useEffect(() => {
        if (dialog.visible) {
            getDomainInfo();
        }
    },// eslint-disable-next-line
        [dialog.visible]);

    return (
        <Dialog
            onClose={() => setDialog({ visible: false, domain: '' })}
            maxWidth={"sm"}
            className='custom-dialog'
            style={{ direction: "rtl", textAlignLast: "right", zIndex: 9999999 }}
            sx={{
                "& .MuiPaper-root": {
                    margin: 0,
                    width: "calc(100% - 20px)",
                    zIndex: 9999999,
                },
            }}
            open={dialog.visible}>
            <DialogTitle>
                <div className='dialog-head mb-4'>
                    <div>
                        <h2 className='mb-2'>معلومات النطاق</h2>
                    </div>
                    <div className='dialog-actions'>
                        <IconButton
                            aria-label='close'
                            onClick={() => setDialog({ visible: false, domain: '' })}
                            sx={{
                                color: (theme) => theme.palette.grey[500],
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                {loadingData ? (
                    <div className='w-100 d-flex justify-contnet-center'>
                        <CircularProgress size={35} style={{ padding: 0, margin: '20px auto', border: 0 }} sx={{ color: "var(--main-color-one)" }} />
                    </div>
                )
                    : (
                        <>
                            <Alert severity="info" dir="rtl" className="text-right"
                                style={{
                                    margin: '0 auto 20px',
                                }}
                                sx={{
                                    "& .MuiAlert-icon": {
                                        marginRight: 0,
                                        marginLeft: '5px'
                                    },
                                    '& .MuiAlert-message': {
                                        padding: '7px 0 2px'
                                    }
                                }}>
                            قم بتعديل معلومات النطاق الخاص بك, يمكنك الإستعانة بهذه <b>الخطوات</b> لإكمال العمليّة بنجاح</Alert>
                            <div className='px-2'>
                                <p><b>النطاق (Domain):</b> {data?.domainName}</p>
                                <p><b>مخدّم الاسماء (Name Servers):</b>
                                    <ul className='mt-2'>
                                        {data?.nameServers?.hostNames?.map(i => <li key={i}>{i}</li>)}
                                    </ul>
                                </p>
                                <p><b>اسم المسجّل (Registrar Name):</b> {data?.registrarName}</p>
                            </div>

                        </>
                    )}
             
            </DialogContent>
            <DialogActions>
                <button
                    className='primary-btn px-3'
                    onClick={() => setDialog({ visible: false, domain: '' })}>
                    حسناً
                </button>
            </DialogActions>
        </Dialog>
    );
}
