import React from 'react'
import CookieConsent from "react-cookie-consent";

export default function CookieConsentBar() {
    return (
        <CookieConsent
            buttonText="تأكيد"
            overlay={false}
            style={{ direction: 'rtl', textAlign: 'right' }}
            buttonClasses="primary-btn py-2 px-4"
            cookieName="AmmaknConsent"
        >
            <div className='cookie-wrapper'>
            أهلاً بك في أماكن, نحن نستخدم ملفات تعريف الارتباط من أجل تحسين تجربتك ضمن موقعنا
            &nbsp;<span style={{ fontSize: "10px" }}>(يرجى الضغط على زر تأكيد)</span>
            </div>
        </CookieConsent>
    );
}
