import React from 'react'
import { Card, CardContent, CardHeader, CardMedia, IconButton, Typography } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

export default function PostCardForAdmin({ item, setOpenPopper, setAnchorEl, openPopper, anchorEl, setSelectedPost }) {
    const history = useNavigate();
    
    return (
        <Card className='p-0 position-relative radius-16'>
            <CardHeader
                action={
                    <IconButton
                        onClick={(e) => {
                            setOpenPopper(!openPopper);
                            setAnchorEl(anchorEl ? null : e.target);
                            setSelectedPost(item);
                        }}
                        sx={{
                            background: '#fff',
                            color: 'var(--main-color-one)',
                            boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
                            '&:hover,&:focus': {
                                color: '#fff',
                                background: 'var(--main-color-one)'
                            }
                        }}>
                        <MoreVertIcon />
                    </IconButton>
                }
                sx={{ position: 'absolute', top: 0 }}
            />
            <CardMedia
                component="img"
                onClick={() => history(`/admin/news-details/${item.id}`)}
                style={{ cursor: 'pointer' }}
                height="194"
                image={item.image}
                alt={item.subject}
            />
            <CardContent
                onClick={() => history(`/admin/news-details/${item.id}`)}
                sx={{ minHeight: '155px', cursor: 'pointer', textAlign: 'right', borderTop: '1px solid #ddd' }}>
                <Typography variant="h6" color="text.primary" className='admin-card-title' style={{ fontSize: '18px' }}>
                    {item.subject}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    {moment(item.createdAt).format('DD-MMMM-YYYY')}
                </Typography>
                <Typography variant="body2" color="text.secondary" className='admin-card-desc mt-3'>
                    {item.shortDescription}
                </Typography>
            </CardContent>
        </Card>
    );
}
