import React, { useState, useEffect } from "react";
import BannerV2 from "../../../components/section-components/banner-v2";
import Bannerv3 from "../../../components/section-components/banner-v3";
import Dream from "../../../components/section-components/dream";
import { toast } from "react-toastify";
import { saveWLSectionItem, getSectionItems, saveWLService } from "../../../api/userApi";
import Process from "../../../components/section-components/process";
import { IconButton,Tooltip } from "@mui/material";
import YoutubeSection from "../../../components/section-components/youtube-section";
import WhyUsSection from "../../../components/section-components/why-us-section";
import FeaturedProjects from "../../../components/section-components/featured-projects";
import TeamSection from "../../../components/section-components/team-section";
import FeaturedPropertiesSection from "../../../components/section-components/featured-properties-section";
import Footer from "../../../components/global-components/footer-v2";
import SubHeader from "../../../components/global-components/sub-header";
import MenuSection from "./components/menu-section";
import { useAuth } from "../../../helpers/context";
import SortIcon from '@mui/icons-material/Sort';
import SortComponent from "./components/sort-component";
import TwitterSection from "../../../components/section-components/twitter-section";
import { DEMO_VENDORS, MODERN_TEMPLATES } from "../../../constants";
import LogoSection from "./components/logo-section";
import SectionWrapper from "./components/section-wrapper";


const SectionsPage = () => {
  const [SectionItems, setSectionItems] = useState([]);
  const [openSortModal1, setOpenSortModal1] = React.useState(false);
  const [openSortModal2, setOpenSortModal2] = React.useState(false);
  const [refresh, setRefresh] = useState();
  const { token, vendor, templateId, currentPlan } = useAuth();
  const [loadingButton, setLoadingButton] = useState({ key: '', value: false });
  const [sortData1, setSortData1] = useState([]);
  const [sortData2, setSortData2] = useState([]);
  const [sorted, setSorted] = useState(false);

  useEffect(() => {
    if (DEMO_VENDORS.includes(vendor)) {
      if (localStorage.getItem(`sections-${vendor}`)) {
        setSectionItems(JSON.parse(localStorage.getItem(`sections-${vendor}`)));
      } else {
        getSectionItems({
          userName: vendor,
          language: "0",
        }).then((_userInfo) => {
          if (_userInfo.resCode === 0) {
            localStorage.setItem(`sections-${vendor}`, JSON.stringify(_userInfo.response));
            setSectionItems(_userInfo.response);
          }
        });
      }
    } else {
      getSectionItems({
        userName: vendor,
        language: "0",
      }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          setSectionItems(_userInfo.response);
        }
      });
    }
  }, [vendor, refresh]);

  function sectionShowHide(key, isSecVisible) {

    if (DEMO_VENDORS.includes(vendor)) {
      const sections = localStorage.getItem(`sections-${vendor}`) ? JSON.parse(localStorage.getItem(`sections-${vendor}`)) : {};
      let temp = sections;
      sections?.map((section, index) => {
        if (section?.key === key) {
          temp[index].isVisible = isSecVisible === "0" ? "1" : "0";
        }
        return null;
      });
      localStorage.setItem(`sections-${vendor}`, JSON.stringify(temp));
      setSectionItems(temp);
      toast.success("تم التعديل بنجاح");

    } else {
      saveWLSectionItem({
        userName: vendor,
        language: "1",
        isVisible: isSecVisible === "0" ? "1" : "0",
        key: key,
      }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          toast.success("تم التعديل بنجاح");
          setRefresh(Math.random());
        } else {
          toast.error(_userInfo.resStr);
        }
      });
    }
  }

  function handleSave(props, key) {

    if (DEMO_VENDORS.includes(vendor)) {
      const sections = localStorage.getItem(`sections-${vendor}`) ? JSON.parse(localStorage.getItem(`sections-${vendor}`)) : {};
      let temp = sections;
      sections?.map((section, index) => {
        if (section?.key === key) {
          temp[index].details = props.data;
        }
        return null;
      });
      localStorage.setItem(`sections-${vendor}`, JSON.stringify(temp));
      setSectionItems(temp);
      toast.success("تم التعديل بنجاح");
      setRefresh(Math.random());

    } else {
      let ItemStr = "";
      Object.keys(props.data).forEach((element, index) => {
        ItemStr += element + "#$" + Object.values(props.data)[index] + "^";
      });
      if (ItemStr.length > 1) ItemStr = ItemStr.slice(0, -1);
      setLoadingButton({ key: key, value: true });
      saveWLSectionItem({
        userName: vendor,
        language: "1",
        details: ItemStr,
        key: key,
      }).then((_userInfo) => {
        if (_userInfo.resCode === 0) {
          toast.success("تم التعديل بنجاح");
          setRefresh(Math.random());
        } else {
          toast.error(_userInfo.resStr);
        }
        setLoadingButton({ key: key, value: false });
      }).catch(e => setLoadingButton({ key: key, value: false }));
    }
  }

  return (
    <>
      <SubHeader OKElement={<></>} />
      <LogoSection />
      <MenuSection />
      
      <div className='mt-4 row mx-0 justify-content-between align-items-center'>
        {SectionItems.map(
          (section, i) =>
          ({
            features: (
              <SectionWrapper key={`section-${i}`} section={section} sectionShowHide={sectionShowHide} description="يمكنك اختيار مجموعة من إعلاناتك العقاريّة لكي تظهر ضمن الصفحة الرئيسية">
                <FeaturedPropertiesSection
                  isEdit={true}
                  token={token}
                  data={section.details}
                  key={section.key}
                  loading={loadingButton.key === section.key ? loadingButton.value : false}
                  saveDetails={(e) => handleSave(e, section.key)}
                />
              </SectionWrapper>
            ),
            brokerSearch: (
              <SectionWrapper key={`section-${i}`} section={section} sectionShowHide={sectionShowHide}
                extraClasses="dark"
                description="اختر الصورة التي سوف تظهر في بداية الصفحة الرئيسية, بالإضافة للعنوان الرئيسي والثانوي">
                <Bannerv3
                  isEdit={true}
                  data={section.details}
                  key={section.key}
                  loading={loadingButton.key === section.key ? loadingButton.value : false}
                  saveDetails={(e) => handleSave(e, section.key)}
                />
              </SectionWrapper>
            ),
            // brokerAbout: (
            //   <div className='custom-card with-switcher' key={`brokerAbout-${i}`} style={inlineStyle}>
            //     <div className="card-header">
            //       <div>
            //         <h4>{section.nameAr}</h4>
            //         <h6>يمكنك تعديل الصورة, العنوان الرئيسي والثانوي لقسم معلومات عنّا ادناه</h6>
            //       </div>
            //       <div>
            //         <PaperShow
            //           isVisible={section.isVisible}
            //           onChange={(e) =>
            //             sectionShowHide(section.key, section.isVisible)
            //           }
            //         />
            //       </div>
            //     </div>
            //     <div className="card-body" style={{
            //       opacity: section.isVisible === "0" ? 0.5 : 1,
            //     }}>
            //       <Process
            //         isEdit={true}
            //         type="broker"
            //         data={section.details}
            //         key={section.key}
            //         loading={loadingButton.key === section.key ? loadingButton.value : false}
            //         saveDetails={(e) => handleSave(e, section.key)}
            //       />
            //     </div>
            //   </div>
            // ),
            brokerServices: (
              <SectionWrapper key={`section-${i}`} section={section} sectionShowHide={sectionShowHide}
                customHeader={<div className="header-with-icon">
                  <div>
                    <h4>{section.nameAr}</h4>
                    <h6>يمكنك إضافة وتعديل خدماتك ادناه</h6>
                  </div>
                  <div>
                    {sortData2 && sortData2.length > 1 && (
                      <Tooltip placement="top" title="ترتيب الخدمات">
                        <IconButton
                          edge='start'
                          color='inherit'
                          aria-label='open drawer'
                          className="mobile-version"
                          onClick={() => setOpenSortModal2(true)}
                          sx={{
                            color: '#fff',
                            background: 'var(--main-color-one)',
                            '&:hover': { color: 'var(--main-color-one)', background: '#efefef' },
                            marginLeft: "5px",
                            borderRadius: '100%'
                          }}>
                          <SortIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                  </div>
                  <SortComponent
                    title='ترتيب الخدمات'
                    description='قم بترتيب خدماتك عبر سحبها وإفلاتها بالموضع المناسب لرؤيتك'
                    onOK={async () => {
                      if (DEMO_VENDORS.includes(vendor)) {
                        localStorage.setItem(`services-${vendor}`, JSON.stringify(sortData2));
                        toast.success('تم ترتيب خدماتك بنجاح');
                        setSorted(!sorted);
                      } else {
                        saveWLService({ userId: token, language: "0", services: sortData2 }).then(() => {
                          toast.success('تم ترتيب خدماتك بنجاح');
                          setSorted(!sorted);
                        });
                      }
                    }}
                    openModal={openSortModal2}
                    data={sortData2}
                    setData={setSortData2}
                    setOpenModal={setOpenSortModal2}
                  />
                </div>}
              >
                <Dream
                  isEdit={true}
                  data={section.details}
                  sorted={sorted}
                  key={section.key}
                  saveDetails={(e) => handleSave(e, section.key)}
                  loading={loadingButton.key === section.key ? loadingButton.value : false}
                  sortData={sortData2}
                  setSortData={setSortData2}
                />
              </SectionWrapper>
            ),
            // brokerLocation: (
            //   <div className='custom-card with-switcher' key={`services-${i}`} style={inlineStyle}>
            //     <div className="card-header">
            //       <div>
            //         <h4>{section.nameAr}</h4>
            //         <h6>قم بتحديد موقعك الجغرافي بدقّة, كما يمكنك إضافة وصف مختصر يعبّر عنه</h6>
            //       </div>
            //       <div>
            //         <PaperShow
            //           isVisible={section.isVisible}
            //           onChange={(e) =>
            //             sectionShowHide(section.key, section.isVisible)
            //           }
            //         />
            //       </div>
            //     </div>
            //     <div className="card-body" style={{
            //       opacity: section.isVisible === "0" ? 0.5 : 1,
            //     }}>
            //       <BrokerLocation
            //         isEdit={true}
            //         data={section.details}
            //         sectionName={section.nameAr}
            //         key={section.key}
            //         loading={loadingButton.key === section.key ? loadingButton.value : false}
            //         saveDetails={(e) => handleSave(e, section.key)}
            //       />
            //     </div>
            //   </div>
            // ),
            // intro: (
            //   <div className='custom-card with-switcher' key={`intro-${i}`} style={inlineStyle}>
            //     <div className="card-header">
            //       <div>
            //         <h4>{section.nameAr}</h4>
            //         <h6>اختر الصورة التي سوف تظهر في بداية الصفحة الرئيسية, بالإضافة لكتابة العنوان الرئيسي والثانوي</h6>
            //       </div>
            //       <div>
            //         <PaperShow
            //           isVisible={section.isVisible}
            //           onChange={(e) =>
            //             sectionShowHide(section.key, section.isVisible)
            //           }
            //         />
            //       </div>
            //     </div>
            //     <div className="card-body" style={{
            //       opacity: section.isVisible === "0" ? 0.5 : 1,
            //     }}>
            //       <INTRO key={section.key} />

            //     </div>
            //   </div>
            // ),
            companySearch: (
              <SectionWrapper key={`section-${i}`} section={section} sectionShowHide={sectionShowHide} description="اختر الصورة التي سوف تظهر في بداية الصفحة الرئيسية, بالإضافة لكتابة العنوان الرئيسي والثانوي">
                <BannerV2
                  isEdit={true}
                  data={section.details}
                  key={section.key}
                  loading={loadingButton.key === section.key ? loadingButton.value : false}
                  saveDetails={(e) => handleSave(e, section.key)}
                />
              </SectionWrapper>
            ),
            about: (
              <SectionWrapper key={`section-${i}`} section={section} sectionShowHide={sectionShowHide}
                extraClasses={MODERN_TEMPLATES.includes(+templateId) ? 'col-lg-6' : ''}
                extraStyles={{ height: MODERN_TEMPLATES.includes(+templateId) ? 'fit-content' : 'auto' }}
                description="يمكنك تعديل العنوان الرئيسي والثانوي لقسم معلومات عنّا ادناه">
                <Process
                  isEdit={true}
                  data={section.details}
                  key={section.key}
                  loading={loadingButton.key === section.key ? loadingButton.value : false}
                  saveDetails={(e) => handleSave(e, section.key)}
                />
              </SectionWrapper>
            ),
            youtube: (
              <SectionWrapper key={`section-${i}`} section={section} sectionShowHide={sectionShowHide}
                extraClasses={`no-height ${MODERN_TEMPLATES.includes(+templateId) ? 'col-lg-5' : ''}`}
                description="قم بوضع رابط فيديو يوتيوب يعبّر عن نشاطك التجاري">
                <YoutubeSection
                  isEdit={true}
                  data={section.details}
                  key={section.key}
                  loading={loadingButton.key === section.key ? loadingButton.value : false}
                  saveDetails={(e) => handleSave(e, section.key)}
                />
              </SectionWrapper>
            ),
            whyUs: (
              <SectionWrapper key={`section-${i}`} section={section} sectionShowHide={sectionShowHide}
                description="قم بإدخال مزايا تميّزك عن الآخرين">
                <WhyUsSection
                  isEdit={true}
                  data={section.details}
                  key={section.key}
                  loading={loadingButton.key === section.key ? loadingButton.value : false}
                  saveDetails={(e) => handleSave(e, section.key)}
                />
              </SectionWrapper>
            ),
            services: (
              <SectionWrapper key={`section-${i}`} section={section} sectionShowHide={sectionShowHide}
                customHeader={<div className="header-with-icon">
                  <div>
                    <h4>{section.nameAr}</h4>
                    <h6>يمكنك إضافة وتعديل خدماتك ادناه</h6>
                  </div>
                  <div>
                    {sortData1 && sortData1.length > 1 && (
                      <Tooltip placement="top" title="ترتيب الخدمات">
                        <IconButton
                          edge='start'
                          color='inherit'
                          aria-label='open drawer'
                          className="mobile-version"
                          onClick={() => setOpenSortModal1(true)}
                          sx={{
                            color: '#fff',
                            background: 'var(--main-color-one)',
                            '&:hover': { color: 'var(--main-color-one)', background: '#efefef' },
                            marginLeft: "5px",
                            borderRadius: '100%'
                          }}>
                          <SortIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                  <SortComponent
                    title='ترتيب الخدمات'
                    description='قم بترتيب خدماتك عبر سحبها وإفلاتها بالموضع المناسب لرؤيتك'
                    onOK={async () => {
                      if (DEMO_VENDORS.includes(vendor)) {
                        localStorage.setItem(`services-${vendor}`, JSON.stringify(sortData1));
                        toast.success('تم ترتيب خدماتك بنجاح');
                        setSorted(!sorted);
                      } else {
                        saveWLService({ userId: token, language: "0", services: sortData1 }).then(() => {
                          toast.success('تم ترتيب خدماتك بنجاح');
                          setSorted(!sorted);
                        });
                      }
                    }}
                    openModal={openSortModal1}
                    data={sortData1}
                    setData={setSortData1}
                    setOpenModal={setOpenSortModal1}
                  />
                </div>}
              >
                <Dream
                  isEdit={true}
                  data={section.details}
                  key={section.key}
                  sorted={sorted}
                  loading={loadingButton.key === section.key ? loadingButton.value : false}
                  saveDetails={(e) => handleSave(e, section.key)}
                  sortData={sortData1}
                  setSortData={setSortData1}
                />
              </SectionWrapper>
            ),
            // callUs: (
            //   <div className='custom-card with-switcher no-padding' key={`callUs-${i}`} style={inlineStyle}>
            //     <div className="card-header">
            //       <div>
            //         <h4>{section.nameAr}</h4>
            //         <h6>يمكنك التحكم في إظهار أو إخفاء هذا القسم</h6>
            //       </div>
            //       <div>
            //         <PaperShow
            //           isVisible={section.isVisible}
            //           onChange={(e) =>
            //             sectionShowHide(section.key, section.isVisible)
            //           }
            //         />
            //       </div>
            //     </div>
            //     <div className="card-body" style={{
            //       opacity: section.isVisible === "0" ? 0.5 : 1,
            //     }}>
            //       <CallUSSection disabled />
            //     </div>
            //   </div>
            // ),
            featuredProjects: (
              <SectionWrapper key={`section-${i}`} section={section} sectionShowHide={sectionShowHide}
                description="يمكنك اختيار مجموعة من مشاريعك المنشورة لكي تظهر ضمن الصفحة الرئيسية">
                <FeaturedProjects
                  isEdit={true}
                  token={token}
                  data={section.details}
                  key={section.key}
                  loading={loadingButton.key === section.key ? loadingButton.value : false}
                  saveDetails={(e) => handleSave(e, section.key)}
                />
              </SectionWrapper>
            ),
            team: (
              <SectionWrapper key={`section-${i}`} section={section} sectionShowHide={sectionShowHide}
                description="يمكنك إدارة بيانات فريق العمل الخاص بك, من إضافة, حذف, وتعديل.">
                <TeamSection
                  isEdit={true}
                  token={token}
                  data={section.details}
                  key={section.key}
                  loading={loadingButton.key === section.key ? loadingButton.value : false}
                  saveDetails={(e) => handleSave(e, section.key)}
                />
              </SectionWrapper>
            ),
            twitter: [8, 9].includes(+currentPlan?.plan?.id || 0) && (
              <SectionWrapper key={`section-${i}`} section={{ ...section, nameAr: 'تغريدات منصّة X' }} sectionShowHide={sectionShowHide}
                extraClasses="no-height"
                description="يمكنك استعراض آخر التغريدات المنشورة على حسابك في منصّة X">
                <TwitterSection
                  isEdit={true}
                  data={section.details}
                  key={section.key}
                  loading={loadingButton.key === section.key ? loadingButton.value : false}
                  saveDetails={(e) => handleSave(e, section.key)}
                />
              </SectionWrapper>
            ),
          }[section.name])
        )}
       
        <div className='custom-card footer col-12 p-0'>
          <div className="card-header">
            <h4>تذييل</h4>
            <h6>يمكنك التحكم بلون الخلفية للتذييل وبالوصف الموجود ضمنه</h6>
          </div>
          <div className="card-body" dir="rtl">
            <Footer isEdit={true} token={token} />
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionsPage;
