import { Skeleton, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import { getFeaturedProperty, } from "../../api/propertyApi";
import PropertyBox from "./property-box";
import SwiperCore, { Autoplay, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAuth } from "../../helpers/context";

SwiperCore.use([Autoplay]);

function Featured(props) {
  const [FeaturedProperty, setFeaturedProperty] = useState([]);
  const { UserId } = useAuth();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    getFeaturedProperty(UserId).then((_FeaturedProperty) =>
      setFeaturedProperty(_FeaturedProperty.response.propertyArray.filter(item => item.status === "1"))
    )
      .catch(() => { })
      .finally(() => { setLoadingData(false) });
  }, [UserId]);

  return (
    <div className='featured-area'>
      <div className={`container-v2 ${FeaturedProperty?.length > 0 ? 'pd-top-60':''}`}>
        {((loadingData && FeaturedProperty?.length === 0) || FeaturedProperty?.length > 0) && (
          <div className='section-title'>
            <h2 className='title'>{props.data.title || "إعلانات عقاريّة مميزة"}</h2>
          </div>
        )}
        <>
          {!isLargeScreen ? (
            FeaturedProperty.length > 0 ? (
              <Swiper
                className="col-12 p-0"
                spaceBetween={32}
                slidesPerView={1}
                autoplay={{
                  delay: 2000,
                  pauseOnMouseEnter: true,
                  disableOnInteraction: false,
                }}
                modules={[Keyboard]}
                keyboard={{ enabled: true }}
                loop={true}
                speed={2500}
              >
                {FeaturedProperty.map((item, i) => (
                  <SwiperSlide key={i}>
                    <div
                      className='col-12 p-0'
                      style={{ cursor: "pointer" }}>
                      <PropertyBox propertey={item} key={i} />
                    </div>
                  </SwiperSlide>
                 
                ))}
                  
              </Swiper>
            ) : loadingData && <>
              <div className="col-12 p-0">
                <div
                  style={{ height: '355px', marginBottom: '25px' }}
                  className='col-12 p-0'
                >
                  <Skeleton
                    variant='rectangular'
                    height='100%'
                    className="single-feature"
                    width="100%" />
                </div>
              </div>
            </>
          ) :
            (
              FeaturedProperty.length > 0 ? (
                <div className='row justify-content-end'>
                  {FeaturedProperty.map((item, i) => (
                    <div
                      key={i}
                      data-aos='flip-left'
                      className='col-xl-3 col-lg-4 col-md-6 col-sm-6'
                      style={{ cursor: "pointer" }}>
                      <PropertyBox propertey={item} key={i} hideHeart />
                    </div>
                  ))}
                </div>
              ) : loadingData && <>
                <div className='row justify-content-end'>
                  {Array(8).fill(1).map((item, i) => (
                    <div
                      key={i}
                      data-aos='flip-left'
                      style={{ height: '355px', marginBottom: '25px' }}
                      className='col-xl-3 col-lg-4 col-md-6 col-sm-6'
                    >
                      <Skeleton
                        variant='rectangular'
                        height='100%'
                        className="single-feature"
                        width="100%" />
                    </div>
                  ))}
                </div>
              </>
            )}
        </>
      </div>
    </div>
  );
}

export default Featured;
