import { Link } from "react-router-dom";
import { useAuth } from "../../helpers/context";
import LoginPopup from "../user-components/login-popup";

const Banners = () => {
    const { token, setToken, setUserId } = useAuth();
    return (
        // <div className="row banner-wrapper2" dir="rtl">
        //     <div className="col-12 col-md-6 col-lg-4 mt-0 banner-3 pr-0" data-aos="slide-left" data-aos-duration="1500">
        //         <div className="add-ad-banner">
        //             <h3>سوّق لعقاراتك مع أماكن</h3>
        //             <p>لا تتردد في إضافة عقاراتك وتسويقها عبر أماكن, لإيصالها لأكبر عدد من العملاء</p>
        //             {token ? (
        //                 <Link to="/add-property" className="btn btn-yellow btn-rounded mx-0" style={{ width: 'fit-content' }} >أضف عقارك الآن</Link>
        //             ) : (
        //                 <LoginPopup
        //                     setToken={setToken}
        //                     setUserId={setUserId}
        //                     settext="أضف عقارك الآن"
        //                     btnTyp="2"
        //                     path="/add-property"
        //                 />
        //             )}
              
        //             <div className="card-bg">
        //                 <svg fill="#fff" version="1.1" viewBox="0 10 700 550" xmlns="http://www.w3.org/2000/svg">
        //                     <g>
        //                         <path d="m515.04 26.531c-18.293 0.011719-35.836 7.2852-48.77 20.219-12.938 12.934-20.215 30.473-20.234 48.766v11.234l-47.25-41.613c-13.477-11.805-30.785-18.312-48.703-18.312-17.914 0-35.223 6.5078-48.699 18.312l-222.52 195.49c-2.6133 2.3047-4.207 5.5508-4.4258 9.0312-0.22266 3.4766 0.94922 6.9023 3.2539 9.5156 4.8008 5.4492 13.105 5.9727 18.551 1.1758l19.672-17.309v209.46c0.015625 16.168 6.4492 31.672 17.883 43.105 11.434 11.434 26.934 17.863 43.102 17.883h173.09c7.25 0 13.125-5.8789 13.125-13.125 0-7.25-5.875-13.125-13.125-13.125h-173.13c-9.207-0.011719-18.039-3.6719-24.551-10.184-6.5117-6.5156-10.176-15.344-10.184-24.555v-232.54l176.48-155.17c8.6836-7.6094 19.836-11.801 31.379-11.801s22.695 4.1914 31.379 11.801l180.2 158.46 0.17578 0.14062 42 36.977c5.4453 4.7891 13.742 4.2578 18.531-1.1914 4.7891-5.4453 4.2578-13.742-1.1875-18.531l-37.102-32.621v-132.51c-0.023438-18.285-7.293-35.812-20.219-48.742-12.926-12.93-30.449-20.211-48.73-20.242zm42.734 178.39-85.488-75.25v-34.16c0.30469-15.066 8.5195-28.859 21.617-36.305 13.102-7.4453 29.152-7.4453 42.254 0 13.098 7.4453 21.312 21.238 21.617 36.305z" />
        //                         <path d="m286.65 223.42c0.007812 16.801 6.6836 32.91 18.566 44.789 11.883 11.875 27.996 18.547 44.797 18.543s32.91-6.6797 44.789-18.559c11.879-11.883 18.551-27.996 18.551-44.797-0.003906-16.801-6.6797-32.91-18.559-44.789-11.879-11.879-27.992-18.555-44.793-18.555-16.797 0.019531-32.902 6.7031-44.781 18.582-11.875 11.879-18.555 27.988-18.57 44.785zm100.45 0h0.003906c-0.007812 9.8398-3.918 19.273-10.879 26.227-6.957 6.9531-16.395 10.859-26.234 10.855-9.8398-0.003906-19.273-3.9141-26.227-10.871-6.957-6.957-10.863-16.395-10.863-26.234 0.003906-9.8359 3.9141-19.273 10.871-26.23 6.957-6.9531 16.391-10.863 26.23-10.863 9.8398 0.007812 19.273 3.9219 26.23 10.883s10.863 16.395 10.871 26.234z" />
        //                         <path d="m510.6 303.5c-30.5 0-59.746 12.113-81.312 33.68s-33.68 50.812-33.68 81.312c0 30.496 12.113 59.746 33.68 81.312 21.566 21.562 50.812 33.68 81.312 33.68 30.496 0 59.746-12.117 81.312-33.68 21.566-21.566 33.68-50.816 33.68-81.312-0.035156-30.488-12.164-59.715-33.723-81.273-21.555-21.555-50.781-33.684-81.27-33.719zm0 203.72v-0.003906c-23.539 0-46.109-9.3477-62.754-25.992-16.641-16.645-25.992-39.219-25.988-62.754 0-23.539 9.3516-46.109 25.996-62.75 16.648-16.645 39.219-25.992 62.758-25.988 23.535 0.003906 46.109 9.3555 62.75 26.004 16.641 16.645 25.984 39.219 25.98 62.758-0.027344 23.523-9.3867 46.078-26.023 62.711-16.637 16.637-39.191 25.988-62.719 26.012z" />
        //                         <path d="m553.44 405.37h-29.75v-29.75c0-7.25-5.875-13.125-13.125-13.125s-13.125 5.875-13.125 13.125v29.75h-29.75c-7.25 0-13.125 5.875-13.125 13.125 0 7.2461 5.875 13.125 13.125 13.125h29.75v29.75c0 7.2461 5.875 13.125 13.125 13.125s13.125-5.8789 13.125-13.125v-29.75h29.75c7.25 0 13.125-5.8789 13.125-13.125 0-7.25-5.875-13.125-13.125-13.125z" />
        //                     </g>
        //                 </svg>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="col-12 col-md-6 col-lg-4 mt-0 banner-3" data-aos="slide-up" data-aos-duration="1500">
        //         <div className="add-ad-banner">
        //             <h3>خريطة العقارات</h3>
        //             <p>اعثر على منزل أحلامك بواسطة خريطة تفاعلية تغطي جميع مناطق المملكة</p>
        //             <Link to="/search-map/3/all/24.716199523004914/46.671776478222675/createdAt/11/1" className="btn btn-yellow btn-rounded mx-0" style={{ width: 'fit-content' }} >اكتشف الآن</Link>
              
        //             <div className="card-bg">
        //                 <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="-5 0 125 100">
        //                     <g>
        //                         <g>
        //                             <polygon fill="#fff" points="65.897,72.788 31.961,72.769 12.681,93.458 88.387,93.458   " />
        //                             <path fill="#fff" d="M35.245,47.289c0,0,5.317,7.9,9.355,16.812l0.007-0.007c0,0,2.036,4.074,4.04,4.074c0,0,1.953,0.687,4.054-3.897    c0,0,0,0.002,0,0.003c4.181-8.976,9.757-16.971,9.757-16.971c0.766-1.013,4.951-8.882,4.951-8.882    c3.557-7.995,2.412-14.213,2.412-14.213C65.505,7.075,48.885,7.075,48.885,7.075v0.048C47.408,7.154,32.16,7.879,27.982,24.16    c0,0-1.169,6.213,2.35,14.224C30.332,38.383,34.487,46.27,35.245,47.289z M49.362,12.858c8.446,0,15.294,6.847,15.294,15.293    c0,8.445-6.848,15.293-15.294,15.293c-8.445,0-15.293-6.848-15.293-15.293C34.069,19.705,40.917,12.858,49.362,12.858z" />
        //                             <path fill="#fff" d="M39.938,36.068h6.706l0.032-0.353v-4.308c0,0,0-3.077,2.584-3.077c2.585,0,2.831,2.831,2.831,2.831v4.906h7.114V26.46    h2.329c0.473,0,0.524-0.234,0.114-0.516l-11.333-7.871c-0.409-0.285-1.074-0.28-1.476,0.014l-4.392,3.156v-3.189h-2.693v5.12    l-1.815,1.307l-2.018,1.45c-0.404,0.29-0.348,0.53,0.122,0.53h1.896V36.068z" />
        //                         </g>
        //                     </g>
        //                 </svg>
        //             </div>
        //         </div>
        //     </div>
        //     <div className="col-12 col-md-6 col-lg-4 mt-0 banner-3 pl-0" data-aos="slide-right" data-aos-duration="1500">
        //         <div className="add-ad-banner">
        //             <h3>مقارنة العقارات</h3>
        //             <p>لا تتردد في مقارنة أحدث العروض العقاريّة المنشورة على أماكن, والعثور على منزل أحلامك</p>
        //             {token ? (
        //                 <Link to="/comparison-list" className="btn btn-yellow btn-rounded mx-0" style={{ width: 'fit-content' }} >قارن الآن</Link>
        //             ) : (
        //                 <LoginPopup
        //                     setToken={setToken}
        //                     setUserId={setUserId}
        //                     settext="قارن الآن"
        //                     btnTyp="2"
        //                     path="/comparison-list"
        //                 />
        //             )}
              
        //             <div className="card-bg">
        //                 <svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" viewBox="50 0 460 430">
        //                     <path fill="#fff" d="M87.733,320.71h108.145c4.728,0,8.273-3.841,8.273-8.273v-70.619c0-2.364-0.886-4.432-2.364-5.91l-51.413-51.413v-36.935  h97.212v250.861H215.38c-4.728,0-8.273,3.841-8.273,8.273c0,4.432,3.841,8.273,8.273,8.273h80.961c4.728,0,8.273-3.841,8.273-8.273  c0-4.432-3.841-8.273-8.273-8.273h-32.207V147.559h97.212v78.893l-51.413,51.118c-1.477,1.477-2.364,3.841-2.364,5.91v70.619  c0,4.728,3.841,8.273,8.273,8.273h108.145c4.728,0,8.273-3.841,8.273-8.273V283.48c0-2.364-0.886-4.432-2.364-5.91l-51.709-51.413  v-78.893h16.842c4.728,0,8.273-3.841,8.273-8.273c0-4.432-3.841-8.273-8.273-8.273H264.134v-25.411c0-4.728-3.841-8.273-8.273-8.273  s-8.273,3.841-8.273,8.273v25.411H116.691c-4.728,0-8.273,3.841-8.273,8.273c0,4.432,3.841,8.273,8.273,8.273h16.842v36.935  L82.12,235.612c-1.477,1.477-2.364,3.841-2.364,5.91v70.619C79.46,316.869,83.006,320.71,87.733,320.71z M359.574,344.939v-15.365  c0-5.614,4.728-10.342,10.342-10.342s10.342,4.728,10.342,10.342v15.365H359.574z M415.419,345.826H397.1v-16.251  c0-15.069-12.115-27.184-27.184-27.184s-27.184,12.115-27.184,27.184v16.251h-18.32v-58.8l45.504-45.504l45.504,45.504  L415.419,345.826L415.419,345.826z M131.464,302.981v-15.365c0-5.614,4.728-10.342,10.342-10.342  c5.614,0,10.342,4.728,10.342,10.342v15.365H131.464z M96.302,245.067l45.504-45.504l45.504,45.504v58.8h-18.32v-16.251  c0-15.069-12.115-27.184-27.184-27.184s-27.184,12.115-27.184,27.184v16.251h-18.32L96.302,245.067L96.302,245.067z" />
        //                 </svg>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <div className="banner-wrapper banner-wrapper2" >
            <div className="add-ad-banner" dir="rtl">
                <div className="right-section">
                    <h3>هل لديك عقار للبيع او للايجار؟</h3>
                    <p>لا تتردد في تسويق إعلاناتك العقاريّة على أماكن, و إيصاله لأكبر عدد من العملاء</p>
                    {token ? (
                        <Link to="/add-property" className="btn btn-yellow btn-rounded" style={{ width: 'fit-content' }} >أضف إعلانك الآن</Link>
                    ) : (
                        <LoginPopup
                            setToken={setToken}
                            setUserId={setUserId}
                            settext="أضف إعلانك الآن"
                            btnTyp="2"
                            path="/add-property"
                        />
                    )}
          
                </div>
                <div className="left-section">
                    <svg width="100%" className="mt-lg-4" fill="#fff" height="200" version="1.1" viewBox="150 0 450 700" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <path d="m515.04 26.531c-18.293 0.011719-35.836 7.2852-48.77 20.219-12.938 12.934-20.215 30.473-20.234 48.766v11.234l-47.25-41.613c-13.477-11.805-30.785-18.312-48.703-18.312-17.914 0-35.223 6.5078-48.699 18.312l-222.52 195.49c-2.6133 2.3047-4.207 5.5508-4.4258 9.0312-0.22266 3.4766 0.94922 6.9023 3.2539 9.5156 4.8008 5.4492 13.105 5.9727 18.551 1.1758l19.672-17.309v209.46c0.015625 16.168 6.4492 31.672 17.883 43.105 11.434 11.434 26.934 17.863 43.102 17.883h173.09c7.25 0 13.125-5.8789 13.125-13.125 0-7.25-5.875-13.125-13.125-13.125h-173.13c-9.207-0.011719-18.039-3.6719-24.551-10.184-6.5117-6.5156-10.176-15.344-10.184-24.555v-232.54l176.48-155.17c8.6836-7.6094 19.836-11.801 31.379-11.801s22.695 4.1914 31.379 11.801l180.2 158.46 0.17578 0.14062 42 36.977c5.4453 4.7891 13.742 4.2578 18.531-1.1914 4.7891-5.4453 4.2578-13.742-1.1875-18.531l-37.102-32.621v-132.51c-0.023438-18.285-7.293-35.812-20.219-48.742-12.926-12.93-30.449-20.211-48.73-20.242zm42.734 178.39-85.488-75.25v-34.16c0.30469-15.066 8.5195-28.859 21.617-36.305 13.102-7.4453 29.152-7.4453 42.254 0 13.098 7.4453 21.312 21.238 21.617 36.305z" />
                            <path d="m286.65 223.42c0.007812 16.801 6.6836 32.91 18.566 44.789 11.883 11.875 27.996 18.547 44.797 18.543s32.91-6.6797 44.789-18.559c11.879-11.883 18.551-27.996 18.551-44.797-0.003906-16.801-6.6797-32.91-18.559-44.789-11.879-11.879-27.992-18.555-44.793-18.555-16.797 0.019531-32.902 6.7031-44.781 18.582-11.875 11.879-18.555 27.988-18.57 44.785zm100.45 0h0.003906c-0.007812 9.8398-3.918 19.273-10.879 26.227-6.957 6.9531-16.395 10.859-26.234 10.855-9.8398-0.003906-19.273-3.9141-26.227-10.871-6.957-6.957-10.863-16.395-10.863-26.234 0.003906-9.8359 3.9141-19.273 10.871-26.23 6.957-6.9531 16.391-10.863 26.23-10.863 9.8398 0.007812 19.273 3.9219 26.23 10.883s10.863 16.395 10.871 26.234z" />
                            <path d="m510.6 303.5c-30.5 0-59.746 12.113-81.312 33.68s-33.68 50.812-33.68 81.312c0 30.496 12.113 59.746 33.68 81.312 21.566 21.562 50.812 33.68 81.312 33.68 30.496 0 59.746-12.117 81.312-33.68 21.566-21.566 33.68-50.816 33.68-81.312-0.035156-30.488-12.164-59.715-33.723-81.273-21.555-21.555-50.781-33.684-81.27-33.719zm0 203.72v-0.003906c-23.539 0-46.109-9.3477-62.754-25.992-16.641-16.645-25.992-39.219-25.988-62.754 0-23.539 9.3516-46.109 25.996-62.75 16.648-16.645 39.219-25.992 62.758-25.988 23.535 0.003906 46.109 9.3555 62.75 26.004 16.641 16.645 25.984 39.219 25.98 62.758-0.027344 23.523-9.3867 46.078-26.023 62.711-16.637 16.637-39.191 25.988-62.719 26.012z" />
                            <path d="m553.44 405.37h-29.75v-29.75c0-7.25-5.875-13.125-13.125-13.125s-13.125 5.875-13.125 13.125v29.75h-29.75c-7.25 0-13.125 5.875-13.125 13.125 0 7.2461 5.875 13.125 13.125 13.125h29.75v29.75c0 7.2461 5.875 13.125 13.125 13.125s13.125-5.8789 13.125-13.125v-29.75h29.75c7.25 0 13.125-5.8789 13.125-13.125 0-7.25-5.875-13.125-13.125-13.125z" />
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    );
}

export default Banners;