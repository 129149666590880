import React, { useState } from "react";
import GoogleMapsContainer from "../../section-components/registration/GoogleMapsContainer";

export default function GoogleMapsContainerForAdmin(props) {
  // const [setZoomLevel] = useState(14);
  let publicUrl = process.env.REACT_APP_URL + "/";
  const [Location] = useState({
    id: null,
    userIdHashed: "",
    address: props.data?.address || "",
    latitude: props.data?.lat || "24.74772941029266",
    longitude: props.data?.long || "46.619290983645406",
    isLocationChanged: "0",
    language: "0",
    neighborhood: "",
    city:''
  });

  function handleChange(NewLocation) {
    props.setLocation({
      latitude: NewLocation.latitude,
      longitude: NewLocation.longitude,
      address: NewLocation.address,
      neighborhood: NewLocation.neighborhood,
      city:NewLocation.city
    });
  }

  // function handleZoomChange(NewZoom) {
  //   // setZoomLevel(NewZoom);
  // }

  function handleChangeAddress() {}

  return (
    <div style={{ overflow: "hidden", width: "100%" }}>
      <h4 style={{ textAlign: "right" }}>{props.title}</h4>
      <div
        className='row justify-content-md-center'
        style={{ height: "500px" }}>
        <div className='col-lg-12 col-sm-12'>
          <GoogleMapsContainer
            lat={Location.latitude}
            lng={Location.longitude}
            onChange={handleChange}
            onZoomChange={props.onZoomChange ? props.onZoomChange : () => {}}
            user={Location}
          />
          <img
            alt='pin'
            src={publicUrl + "assets/img/icons/BluePin.svg"}
            className='addPropPinAdmin'></img>
          <input
            id='address'
            name='address'
            className='form-control location-search'
            onChange={handleChangeAddress}
            value={Location.address}
          />
        </div>
      </div>
    </div>
  );
}
