import React from "react";
import MyFavorites from "./components/my-favorite";
import AppLayout from "../../../components/layouts/app-layout";
import { useAuth } from "../../../helpers/context";

const MyFavorite = () => {
  const { token } = useAuth();
  return (
    <AppLayout needAuth pageTitle="المفضلة">
      {token && (<MyFavorites userId={token} />)}
    </AppLayout>
  );
};

export default MyFavorite;
