/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  useState } from "react";
import { Link } from "react-router-dom";
import footerdata from "../../data/footerdata.json";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import { AppleStoreBtn, GooglePlayBtn, HuaweiAppGalleryBtn } from "../../constants/assets";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LazyLoadImage } from "react-lazy-load-image-component";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useAuth } from "../../helpers/context";
import { Twitter } from "../../constants/icons";

export default function Footer_v1(props) {
  
  const { vendor } = useAuth();

  let imgattr = "Footer logo";
  let PublicUrl = process.env.REACT_APP_URL + "/";

  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);

  const accordionStyles = {
    background: 'transparent',
    direction: 'rtl',
    textAlign: 'right',
    padding: 0
  };

  // useEffect(() => {
  //   if(vendor && vendor !== 'amakkn')
  //   getWLProfile({ userName: vendor, language: "0" }).then((_userInfo) => {
  //     if (_userInfo.resCode === 0)
  //       setuserInfo(_userInfo.response.wlUser);
  //   });
  // }, [vendor]);


  function handleGoogleClick() {
    const analytics = firebase.analytics();
    analytics.logEvent("play_store_website", { platform: "website" });
    window.open(
      "https://play.google.com/store/apps/details?id=com.amakknapp.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
      "_blank"
    );
  }

  function handleAppleClick() {
    const analytics = firebase.analytics();
    analytics.logEvent("app_store_website", { platform: "website" });
    window.open(
      "https://itunes.apple.com/us/app/amakkn/id1220303072",
      "_blank"
    );
  }

  function handleHuaweiClick() {
    window.open(
      "https://url.cloud.huawei.com/kcZJ8WHuHm",
      "_blank"
    );
  }


  const getCompanyLinks = () => {
    return (
      <ul>
        <li className='readeal-top' key='1'>
          <Link to="/about">
            {vendor === "amakkn" ? "حول أماكن" : "من نحن"}
          </Link>
        </li>
        <li className='readeal-top' key='2'>
          <Link to="/contact">تواصل معنا</Link>
        </li>
        <li className='readeal-top' key='3'>
          <Link to="/terms">شروط الاستخدام</Link>
        </li>
        <li className='readeal-top' key='4'>
          <Link to="/privacy-policy">سياسة الخصوصيّة</Link>
        </li>
        <li className='readeal-top' key='5'>
          <a
            aria-label="rega pdf"
            href='https://amakknbackendupload.s3.me-south-1.amazonaws.com/rega.pdf'>
            ضوابط الإعلانات العقاريّة
          </a>
        </li>
      </ul>
    );
  }

  const getPopularSeachLinks = () => {
    return (
      <ul>
        <li className='readeal-top' key='1'>
          <Link to={`/search-map/2/1/24.716199523004914/46.671776478222675/createdAt/12/1`}>أراضٍ للبيع في الرياض</Link>
        </li>
        <li className='readeal-top' key='2'>
          <Link to={`/search-map/2/3/24.716199523004914/46.671776478222675/createdAt/12/1`}>شقق للبيع في الرياض</Link>
        </li>
        <li className='readeal-top' key='3'>
          <Link to={`/search-map/1/3/21.4362767/39.7064624/createdAt/12/1`}>شقق للإيجار في مكّة</Link>
        </li>
        <li className='readeal-top' key='4'>
          <Link to={`/search-map/2/3/21.4505289/38.9309635/createdAt/12/1`}>شقق للبيع في جدّه</Link>
        </li>
        <li className='readeal-top' key='5'>
          <Link to={`/search-map/2/1/26.3628008/49.8524566/createdAt/12/1`}>أراضٍ للبيع في الدمام</Link>
        </li>
        <li className='readeal-top' key='6'>
          <Link to={`/search-map/2/4/24.4713203/39.4774735/createdAt/12/1`}>فلل للإيجار في المدينة</Link>
        </li>
        <li className='readeal-top' key='7'>
          <Link to={`/search-map/2/3/26.3628008/49.8524566/createdAt/12/1`}>شقق للبيع في الدمام</Link>
        </li>
        <li className='readeal-top' key='8'>
          <Link to={`/search-map/1/3/24.4713203/39.4774735/createdAt/12/1`}>شقق للإيجار في المدينة</Link>
        </li>
      </ul>
    );
  }

  const getDiscoverPropertiesLinks = () => {
    return (
      <ul>
        <li className='readeal-top' key='1'>
          <Link to={`/search-map/1/3/24.716199523004914/46.671776478222675/createdAt/12/1`}>شقق للإيجار في الرياض</Link>
        </li>
        <li className='readeal-top' key='2'>
          <Link to={`/search-map/2/1/21.4505289/38.9309635/createdAt/12/1`}>أراضٍ للبيع في جدّه</Link>
        </li>
        <li className='readeal-top' key='3'>
          <Link to={`/search-map/1/3/21.4362767/39.7064624/createdAt/12/1`}>شقق للإيجار في مكّة</Link>
        </li>
        <li className='readeal-top' key='4'>
          <Link to={`/search-map/2/3/24.716199523004914/46.671776478222675/createdAt/12/1`}>شقق للبيع في الرياض</Link>
        </li>
        <li className='readeal-top' key='5'>
          <Link to={`/search-map/1/4/26.3628008/49.8524566/createdAt/12/1`}>فلل للإيجار في الدمام</Link>
        </li>
        <li className='readeal-top' key='6'>
          <Link to={`/search-map/1/3/24.4713203/39.4774735/createdAt/12/1`}>شقق للإيجار المدينة</Link>
        </li>
        <li className='readeal-top' key='7'>
          <Link to={`/search-map/2/1/24.716199523004914/46.671776478222675/createdAt/12/1`}>أراضٍ للبيع في الرياض</Link>
        </li>
        <li className='readeal-top' key='8'>
          <Link to={`/search-map/2/4/24.716199523004914/46.671776478222675/createdAt/12/1`}>فلل للبيع في الرياض</Link>
        </li>
      </ul>
    );
  }

  return (
    <footer className='footer-area' style={props.style ? props.style : {}}>
      <div className='footer-wrapper'>
        <div className='footer-top'>
          <a className='footer-logo' href='/' aria-label="amakkn logo">
            <LazyLoadImage src={PublicUrl + footerdata.footerlogo} alt={imgattr} />
          </a>
          {/* {vendor === "amakkn" ? ( */}
            <p>
              منصة عقارية مرخصة من قبل الهيئة العامة للعقار
            </p>
          {/* ) : (
            <>
              <p>{userInfo.briefDescription}</p>
              <p>{userInfo.address}</p>
            </>
          )} */}
        </div>
        <div className='footer-center'>
          <div className='row m-0'>
            <div className='col-lg-4 col-md-6 col-12 mx-auto m-lg-0'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>
                  تابعنا على مواقع التواصل الإجتماعي
                </h4>
                <ul className='social-icon'>
                  <li>
                    <a href={"https://www.instagram.com/amakkn"} target='_blank' rel='noreferrer'
                      aria-label="social icon">
                      <InstagramIcon sx={{ fontSize: '34px', cursor: 'pointer' }} />
                    </a>
                  </li>
                  <li>
                    <a href={"https://twitter.com/amakkn"} target='_blank' rel='noreferrer'
                      aria-label="social icon">
                      <Twitter/>
                    </a>
                  </li>
                  <li>
                    <a href={"https://www.facebook.com/amakkn"} target='_blank' rel='noreferrer'
                      aria-label="social icon">
                      <FacebookIcon sx={{ fontSize: '34px', cursor: 'pointer' }} />
                    </a>
                  </li>
                </ul>
              </div>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title' style={{ marginBottom: '10px' }}>حمّل التطبيق</h4>
                <div className='btns-wrapper'>
                  <GooglePlayBtn onClick={handleGoogleClick} />
                  <AppleStoreBtn onClick={handleAppleClick} />
                  <HuaweiAppGalleryBtn onClick={handleHuaweiClick} />
                </div>
              </div>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title' style={{ marginBottom: '10px' }}>الترخيص</h4>
                <a
                  className='footer-a d-flex ic_rega mr-lg-0 mx-auto justify-content-center justify-content-lg-end position-relative'
                  aria-label="amakkn certificate image"
                  href='https://eservicesredp.rega.gov.sa/public/OfficesBroker/LicenseDetails/Bmlp/08db650b-a2ef-400c-8604-c3bbb4bbe0ba'>
                  {/* 210000357 */}
                  <LazyLoadImage
                    src={PublicUrl + "assets/img/rega.png"}
                    alt={imgattr}
                  />
                  <img src={PublicUrl + "assets/img/qr.png"} alt="license qr" style={{
                    position: 'absolute',
                    width: 50,
                    right: 129,
                    top: 20
                  }} />
                </a>
              </div>
            </div>
            <Accordion sx={accordionStyles} className='d-lg-none widget widget_nav_menu col-12' expanded={expanded1} onChange={() => { setExpanded1(!expanded1) }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}>
                <h4 className='widget-title m-0'>الشركة</h4>
              </AccordionSummary>
              <AccordionDetails>
                {getCompanyLinks()}
              </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles} className='d-lg-none widget widget_nav_menu col-12' expanded={expanded2} onChange={() => { setExpanded2(!expanded2) }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}>
                <h4 className='widget-title m-0'>عمليات البحث الشائعة</h4>
              </AccordionSummary>
              <AccordionDetails>
                {getPopularSeachLinks()}
              </AccordionDetails>
            </Accordion>
            <Accordion sx={accordionStyles} className='d-lg-none widget widget_nav_menu col-12' expanded={expanded3} onChange={() => { setExpanded3(!expanded3) }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#fff' }} />}
              >
                <h4 className='widget-title m-0'>استكشف الإعلانات العقاريّة</h4>
              </AccordionSummary>
              <AccordionDetails>
                {getDiscoverPropertiesLinks()}
              </AccordionDetails>
            </Accordion>
            <div className='col-lg-3 d-lg-block d-none'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>استكشف الإعلانات العقاريّة</h4>
                {getDiscoverPropertiesLinks()}
              </div>
            </div>
            <div className='col-lg-3 d-lg-block d-none'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>عمليات البحث الشائعة</h4>
                {getPopularSeachLinks()}
              </div>
            </div>
            <div className='col-lg-2 d-lg-block d-none m-0'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>الشركة</h4>
                {getCompanyLinks()}
              </div>
            </div>
            
          </div>
        </div>
        {/* <div
          className='footer-bottom copy-right text-center'
          dangerouslySetInnerHTML={{
            __html: footerdata.copyrighttext,
          }}></div> */}
        <div className='footer-bottom copy-right text-center'>
          © حقوق النشر محفوظة لشركة الأساليب المبتكرة للتسويق الإلكتروني {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  );
}
