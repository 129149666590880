import React from 'react';
import Errorpage from './components/error';
import AppLayout from '../../../components/layouts/app-layout';
import { useTranslation } from 'react-i18next';

const Error = () => {
    const { t } = useTranslation();

    return (
        <AppLayout pageTitle={t("PageNotFound")}>
            <Errorpage />
        </AppLayout>
    );
}
    
export default Error
