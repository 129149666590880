import { ClickAwayListener, Grow, List, ListItem, ListItemButton, ListItemText, Paper, Popper } from '@mui/material';
import React from 'react'

export default function ActionsPopper({ openPopper, anchorEl, setAnchorEl, setOpenPopper, items }) {
    return (
        <Popper
            sx={{
                zIndex: 99999999,
                offset: '55px 0 0 5px',
            }}
            open={openPopper}
            role={undefined}
            transition
            anchorEl={anchorEl}
            disablePortal={false}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={(event) => {
                            if (event.target.nodeName === 'BODY' && event.type === 'click') {
                                return;
                            } else {
                                setAnchorEl(null); setOpenPopper(false);
                            }

                        }}>
                            <List className='custom-list-item'>
                                {items.map((item, index) => item && (
                                    <ListItem disablePadding key={index}>
                                        <ListItemButton
                                            disabled={item.disabled || false}
                                            sx={{ padding: '2px 15px' }}
                                            onClick={() => {
                                                item.action();
                                                setAnchorEl(null);
                                                setOpenPopper(false);
                                            }}>
                                            <ListItemText primary={item?.title} sx={{ textAlign: 'right' }} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
             
                            </List>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
}
